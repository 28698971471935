import {
    APP_SESSION_KEY, LOGIN_AUTH_REQUEST, LOGIN_AUTH_SUCCESS, LOGIN_AUTH_FAILURE, LOGOUT_AUTH_REQUEST, USER_LOGIN_AUTH_REQUEST_INIT, USER_LOGIN_AUTH_REQUEST_DONE, USER_LOGIN_AUTH_REQUEST_FAILURE, USER_FORGET_PASSWORD_REQUEST_INIT, USER_FORGET_PASSWORD_REQUEST_DONE, USER_FORGET_PASSWORD_REQUEST_FAILURE, USER_TRIAL_ACC_REQUEST_DONE, USER_TRIAL_ACC_REQUEST_FAILURE, USER_TRIAL_ACC_REQUEST_INIT, IP_USER_SELF_REGISTER_REQUEST_INIT, IP_USER_SELF_REGISTER_REQUEST_DONE, IP_USER_SELF_REGISTER_REQUEST_FAILURE,
    GET_IP_ADMIN_DETAIL_REQUEST_INIT, GET_IP_ADMIN_DETAIL_REQUEST_DONE, GET_IP_ADMIN_DETAIL_REQUEST_FAILURE, USER_CHANGE_PWD_SAVE_REQUEST_INIT, USER_CHANGE_PWD_SAVE_REQUEST_DONE, USER_CHANGE_PWD_SAVE_REQUEST_FAILURE, USER_PROFILE_INIT_DATA_REQUEST_INIT, USER_PROFILE_INIT_DATA_REQUEST_DONE, USER_PROFILE_INIT_DATA_REQUEST_FAILURE, SAVE_USER_PROFILE_DATA_REQUEST_INIT, SAVE_USER_PROFILE_DATA_REQUEST_DONE, SAVE_USER_PROFILE_DATA_REQUEST_FAILURE} from "../constants";
import { Api, SessionData, toEncryption, Request, toDecryption } from "utils";
import _ from 'lodash';

const LoginReq = () => {
    return { type: LOGIN_AUTH_REQUEST };
};

const LoginReqSuccess = (res) => {
    return { type: LOGIN_AUTH_SUCCESS, payload: res.user_data };
};

const LoginReqFailure = (err) => { 
    return { type: LOGIN_AUTH_FAILURE, payload: err };
};

const LogoutReq = () => {
    return { type: LOGOUT_AUTH_REQUEST };
};

export const fnLoginAuthentication = ( data ) => { 
    return ( dispatch, getState ) => {
        Api.post('', data, { params: { 'for': 'loginReq' }})
            .then( ({data: res, status}) => {
                if (!res.error) { 
                    SessionData.set(APP_SESSION_KEY, toEncryption(res.user_data, true));
                    dispatch( LoginReqSuccess( res ) );                    
                } else {// Error Occured
                    throw new Error(res.msg);
                }
            })
            .catch(err => {
                dispatch( LoginReqFailure( err.toString() ) );
            });
    };

}

export const fnLoginAuthActions = {
    login(data) {
        return (dispatch) => {
            dispatch({type: USER_LOGIN_AUTH_REQUEST_INIT});
            Request.post('userLoginRequest', data).then(res => {
                
                dispatch({ type: USER_LOGIN_AUTH_REQUEST_DONE, payload: _.get(res, 'meta.data.user_data') });
                
                SessionData.set(APP_SESSION_KEY, toEncryption(_.get(res, 'meta.data.user_data'), true));
                localStorage.setItem("user_type_id" , res.meta.data.user_data.id_user_type)
                return Promise.resolve(_.get(res, 'meta.data'));
                
            }).catch(err => {
               
                dispatch({ type: USER_LOGIN_AUTH_REQUEST_FAILURE, payload: err.toString() });
                 return Promise.reject(err);
            });
        }
    },
    forgetPassword(data) { 
        return (dispatch) => {
            dispatch({type: USER_FORGET_PASSWORD_REQUEST_INIT});
            Request.post('userForgetPassword', data).then(res => {                
                dispatch({ type: USER_FORGET_PASSWORD_REQUEST_DONE, payload: _.get(res, 'meta.msg') });
                return Promise.resolve(_.get(res, 'meta.msg'));
            }).catch(err => {               
                dispatch({ type: USER_FORGET_PASSWORD_REQUEST_FAILURE, payload: err.toString() });
                 return Promise.reject(err);
            });
        }
    },
    requestTrialAccCreate(data) { 
        return (dispatch) => {
            dispatch({type: USER_TRIAL_ACC_REQUEST_INIT});
            Request.post('requestToCreateTrialAcc', data).then(res => {                
                dispatch({ type: USER_TRIAL_ACC_REQUEST_DONE, payload: _.get(res, 'meta.msg') });
                return Promise.resolve(_.get(res, 'meta.msg'));
            }).catch(err => {               
                dispatch({ type: USER_TRIAL_ACC_REQUEST_FAILURE, payload: err.toString() });
                 return Promise.reject(err);
            });
        }
    },
    getIpAdminDetailsForSelfReg() { 
        return (dispatch) => {
            dispatch({type: GET_IP_ADMIN_DETAIL_REQUEST_INIT});
            Request.post('getIpAdminDetailsForSelfReg', {}).then(res => {                
                dispatch({ type: GET_IP_ADMIN_DETAIL_REQUEST_DONE, payload: _.get(res, 'meta.data') });
                return Promise.resolve(_.get(res, 'meta.data'));
            }).catch(err => {               
                dispatch({ type: GET_IP_ADMIN_DETAIL_REQUEST_FAILURE, payload: err.toString() });
                 return Promise.reject(err);
            });
        }
    },
    selfRegisterToCreateAcc(data) { 
        return (dispatch) => {
            dispatch({type: IP_USER_SELF_REGISTER_REQUEST_INIT});
            return Request.post('selfRegisterToCreateAcc', data).then(res => {                
                dispatch({ type: IP_USER_SELF_REGISTER_REQUEST_DONE, payload: _.get(res, 'meta.msg') });
                return Promise.resolve(_.get(res, 'meta.msg'));
            }).catch(err => {               
                dispatch({ type: IP_USER_SELF_REGISTER_REQUEST_FAILURE, payload: err.toString() });
                 return Promise.reject(err);
            });
        }
    },
    saveChangePassword(data) { 
        return (dispatch) => {
            dispatch({type: USER_CHANGE_PWD_SAVE_REQUEST_INIT});
            return Request.post('saveChangePassword', data).then(res => {                
                dispatch({ type: USER_CHANGE_PWD_SAVE_REQUEST_DONE, payload: _.get(res, 'meta.msg') });
                return Promise.resolve(_.get(res, 'meta.msg'));
            }).catch(err => {               
                dispatch({ type: USER_CHANGE_PWD_SAVE_REQUEST_FAILURE, payload: err.toString() });
                 return Promise.reject(err);
            });
        }
    },
    saveUsersProfile(data) { 
        return (dispatch) => {
            dispatch({type: SAVE_USER_PROFILE_DATA_REQUEST_INIT});
            return Request.post('saveUsersProfile', data).then(res => { 
                
                //Update Username & also update session
                let session_data = toDecryption(SessionData.get(APP_SESSION_KEY), true);
                session_data.user_name = _.trim(data.user_name);
                SessionData.set(APP_SESSION_KEY, toEncryption(session_data, true));
                dispatch( LoginReqSuccess( { user_data: session_data } ) );

                dispatch({ type: SAVE_USER_PROFILE_DATA_REQUEST_DONE, payload: _.get(res, 'meta.msg') });
                return Promise.resolve(_.get(res, 'meta.msg'));
            }).catch(err => {               
                dispatch({ type: SAVE_USER_PROFILE_DATA_REQUEST_FAILURE, payload: err.toString() });
                 return Promise.reject(err);
            });
        }
    },
    getUserProfileInitData(data) { 
        return (dispatch) => {
            dispatch({type: USER_PROFILE_INIT_DATA_REQUEST_INIT});
            return Request.post('getUsersProfileInitData', data).then(res => {                
                dispatch({ type: USER_PROFILE_INIT_DATA_REQUEST_DONE, payload: _.get(res, 'meta.data') });
                return Promise.resolve(_.get(res, 'meta.data'));
            }).catch(err => {               
                dispatch({ type: USER_PROFILE_INIT_DATA_REQUEST_FAILURE, payload: err.toString() });
                 return Promise.reject(err);
            });
        }
    }
};

export const fnLogoutAuthentication = () => {
    return (dispatch, getState) => { 
        SessionData.clear(APP_SESSION_KEY);
        dispatch(LogoutReq());
        return Promise.resolve(true);
    }
}