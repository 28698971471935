export const GET_WS_LIST_DATA_REQUEST_INIT = 'GET_WS_LIST_DATA_REQUEST_INIT';
export const GET_WS_LIST_DATA_REQUEST_DONE = 'GET_WS_LIST_DATA_REQUEST_DONE';
export const GET_WS_LIST_DATA_REQUEST_FAILURE = 'GET_WS_LIST_DATA_REQUEST_DONE';

export const DOWNLOAD_WS_RESULTS_DATA_REQUEST_INIT = 'DOWNLOAD_WS_RESULTS_DATA_REQUEST_INIT';
export const DOWNLOAD_WS_RESULTS_DATA_REQUEST_DONE = 'DOWNLOAD_WS_RESULTS_DATA_REQUEST_DONE';
export const DOWNLOAD_WS_RESULTS_DATA_REQUEST_FAILURE = 'DOWNLOAD_WS_RESULTS_DATA_REQUEST_FAILURE';

export const GET_WS_LIST_INIT_DATA_REQUEST_INIT = 'GET_WS_LIST_INIT_DATA_REQUEST_INIT';
export const GET_WS_LIST_INIT_DATA_REQUEST_DONE = 'GET_WS_LIST_INIT_DATA_REQUEST_DONE';
export const GET_WS_LIST_INIT_DATA_REQUEST_FAILURE = 'GET_WS_LIST_INIT_DATA_REQUEST_FAILURE';

export const LOAD_WS_ADD_PROJECTS_INITIALIZE_DATA_REQUEST_INIT = 'LOAD_WS_ADD_PROJECTS_INITIALIZE_DATA_REQUEST_INIT';
export const LOAD_WS_ADD_PROJECTS_INITIALIZE_DATA_REQUEST_DONE = 'LOAD_WS_ADD_PROJECTS_INITIALIZE_DATA_REQUEST_DONE';
export const LOAD_WS_ADD_PROJECTS_INITIALIZE_DATA_REQUEST_FAILURE = 'LOAD_WS_ADD_PROJECTS_INITIALIZE_DATA_REQUEST_FAILURE';

export const SAVE_WS_PROJECTS_DATA_REQUEST_INIT = 'SAVE_WS_PROJECTS_DATA_REQUEST_INIT';
export const SAVE_WS_PROJECTS_DATA_REQUEST_DONE = 'SAVE_WS_PROJECTS_DATA_REQUEST_DONE';
export const SAVE_WS_PROJECTS_DATA_REQUEST_FAILURE = 'SAVE_WS_PROJECTS_DATA_REQUEST_FAILURE';

export const DELETE_WS_PROJECTS_DATA_REQUEST_INIT = 'DELETE_WS_PROJECTS_DATA_REQUEST_INIT';
export const DELETE_WS_PROJECTS_DATA_REQUEST_DONE = 'DELETE_WS_PROJECTS_DATA_REQUEST_DONE';
export const DELETE_WS_PROJECTS_DATA_REQUEST_FAILURE = 'DELETE_WS_PROJECTS_DATA_REQUEST_FAILURE';

export const GET_WS_PROJECTS_DETAILS_BASEDON_ID_REQUEST_INIT = 'GET_WS_PROJECTS_DETAILS_BASEDON_ID_REQUEST_INIT';
export const GET_WS_PROJECTS_DETAILS_BASEDON_ID_REQUEST_DONE = 'GET_WS_PROJECTS_DETAILS_BASEDON_ID_REQUEST_DONE';
export const GET_WS_PROJECTS_DETAILS_BASEDON_ID_REQUEST_FAILURE = 'GET_WS_PROJECTS_DETAILS_BASEDON_ID_REQUEST_FAILURE';

export const GET_WS_PROJECTS_LOG_DETAILS_BASEDON_ID_REQUEST_INIT = 'GET_WS_PROJECTS_LOG_DETAILS_BASEDON_ID_REQUEST_INIT';
export const GET_WS_PROJECTS_LOG_DETAILS_BASEDON_ID_REQUEST_DONE = 'GET_WS_PROJECTS_LOG_DETAILS_BASEDON_ID_REQUEST_DONE';
export const GET_WS_PROJECTS_LOG_DETAILS_BASEDON_ID_REQUEST_FAILURE = 'GET_WS_PROJECTS_LOG_DETAILS_BASEDON_ID_REQUEST_FAILURE';

export const GET_WS_QRY_PAGE_INITIALIZE_DATA_REQUEST_INIT = 'GET_WS_QRY_PAGE_INITIALIZE_DATA_REQUEST_INIT';
export const GET_WS_QRY_PAGE_INITIALIZE_DATA_REQUEST_DONE = 'GET_WS_QRY_PAGE_INITIALIZE_DATA_REQUEST_DONE';
export const GET_WS_QRY_PAGE_INITIALIZE_DATA_REQUEST_FAILURE = 'GET_WS_QRY_PAGE_INITIALIZE_DATA_REQUEST_FAILURE';

export const GET_QRY_SEARCH_RESULTS_DATA_REQUEST_INIT = 'GET_QRY_SEARCH_RESULTS_DATA_REQUEST_INIT';
export const GET_QRY_SEARCH_RESULTS_DATA_REQUEST_DONE = 'GET_QRY_SEARCH_RESULTS_DATA_REQUEST_DONE';
export const GET_QRY_SEARCH_RESULTS_DATA_REQUEST_FAILURE = 'GET_QRY_SEARCH_RESULTS_DATA_REQUEST_FAILURE';


export const TIME_WS_OVERRUN_PROJECTS_CALC_DATA_REQUEST_INIT = 'TIME_WS_OVERRUN_PROJECTS_CALC_DATA_REQUEST_INIT';
export const TIME_WS_OVERRUN_PROJECTS_CALC_DATA_REQUEST_DONE = 'TIME_WS_OVERRUN_PROJECTS_CALC_DATA_REQUEST_DONE';
export const TIME_WS_OVERRUN_PROJECTS_CALC_DATA_REQUEST_FAILURE = 'TIME_WS_OVERRUN_PROJECTS_CALC_DATA_REQUEST_FAILURE';

export const SET_WS_PROJECT_PAGE_LIST_PAGINATION_DATA_FOR_LSTORE = 'SET_WS_PROJECT_PAGE_LIST_PAGINATION_DATA_FOR_LSTORE';
export const SET_WS_PROJECT_DATA_FROM_PLIST_FOR_LSTORE = 'SET_WS_PROJECT_DATA_FROM_PLIST_FOR_LSTORE';

export const PROJECT_STATUS_UNDER_BIDDING = 4;
export const PROJECT_MODE = { PPP: 1, EPC: 2};
export const WS_QRY_PARAM_LABEL = {
    'ws_project_name': 'Project',
    'm_project_status_id': 'Status',
    'm_state_id': 'State',
    'm_district_id': 'District',
    'm_mode_id': 'Mode',
    'm_mode_ppp_id': 'Mode Of PPP',
    'm_project_funding_id': 'Founding Of Project',
    'm_ws_sector_id': 'Sector',
    'm_ws_sub_sector_id': 'Sub Sector',
    'm_ws_authority_id': 'Authority',
    'm_nodal_authority_id': 'Nodal Authority',
    'm_bid_parameter_id':'BID Parameter',
    'm_ws_plant_type_id' : 'Plant Type',
    'm_company_group_id': 'Group',
    'm_company_id_parent': 'Developer',
    'm_company_id': 'SPV Name',
    'act_const_comm_date': 'Actual Construction Start',
    'actual_completion_date': 'Actual Completion',
    'actual_commercial_operation_date': 'Actual Commercial Operation Start',
    'act_financial_closure': 'Actual Financial Closure',
    'appointed_date': 'Letter Of Award',
    'agreement_signing_date': 'Agreement Signed On',
    'date_rfp': 'RFP',
    'date_rfq': 'RFQ',
    'concession_period': 'Concession Period (Years)',
    'const_period': 'Construction Period (Months)',
    'm_bank_id': 'Bank',
    'scheduled_project_cost': 'Scheduled Project Cost (Rs.Cr)',
    'revised_project_cost': 'Revised Project Cost (Rs.Cr)',
    'actual_project_cost': 'Actual Project Cost (Rs.Cr)',
    'dpc': 'Developer Cost (Rs.Cr)',
    'debt': 'Debt (Rs.Cr)',
    'annuity_amt': 'Annuity (Rs.Cr)',
    'grant_amt': 'Grant (Rs.Cr)',
    'sec_loans': 'Secured Loans (Rs.Cr)',
    'unsec_loans': 'Unsecured Loans (Rs.Cr)',
    'equity': 'Equity',
    'updated_interval':'Updated Interval',
};