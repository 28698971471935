import {
    GET_PROJECTS_LIST_REQUEST_INIT, GET_PROJECTS_LIST_REQUEST_DONE, GET_PROJECTS_LIST_REQUEST_FAILURE,
    GET_MY_PROJECTS_LIST_REQUEST_INIT, GET_MY_PROJECTS_LIST_REQUEST_DONE, GET_MY_PROJECTS_LIST_REQUEST_FAILURE,
    GET_MY_PROJECTS_QUERY_REQUEST_INIT, GET_MY_PROJECTS_QUERY_REQUEST_DONE, GET_MY_PROJECTS_QUERY_REQUEST_FAILURE,
    LOAD_ADD_PROJECTS_INITIALIZE_DATA_REQUEST_INIT, LOAD_ADD_PROJECTS_INITIALIZE_DATA_REQUEST_DONE, LOAD_ADD_PROJECTS_INITIALIZE_DATA_REQUEST_FAILURE,
    SAVE_PROJECTS_DATA_REQUEST_INIT, SAVE_PROJECTS_DATA_REQUEST_DONE, SAVE_PROJECTS_DATA_REQUEST_FAILURE,
    DELETE_PROJECTS_DATA_REQUEST_INIT, DELETE_PROJECTS_DATA_REQUEST_DONE, DELETE_PROJECTS_DATA_REQUEST_FAILURE,
    TIME_OVERRUN_PROJECTS_CALC_DATA_REQUEST_INIT, TIME_OVERRUN_PROJECTS_CALC_DATA_REQUEST_DONE, TIME_OVERRUN_PROJECTS_CALC_DATA_REQUEST_FAILURE,
    GET_PROJECTS_DETAILS_BASEDON_ID_REQUEST_INIT, GET_PROJECTS_DETAILS_BASEDON_ID_REQUEST_DONE, GET_PROJECTS_DETAILS_BASEDON_ID_REQUEST_FAILURE,
    GET_PROJECTS_LOG_DETAILS_BASEDON_ID_REQUEST_INIT, GET_PROJECTS_LOG_DETAILS_BASEDON_ID_REQUEST_DONE, GET_PROJECTS_LOG_DETAILS_BASEDON_ID_REQUEST_FAILURE,
    GET_QRY_PAGE_INITIALIZE_DATA_REQUEST_INIT, GET_QRY_PAGE_INITIALIZE_DATA_REQUEST_DONE, GET_QRY_PAGE_INITIALIZE_DATA_REQUEST_FAILURE,
    GET_QRY_SEARCH_RESULTS_DATA_REQUEST_INIT, GET_QRY_SEARCH_RESULTS_DATA_REQUEST_DONE, GET_QRY_SEARCH_RESULTS_DATA_REQUEST_FAILURE,
    DOWNLOAD_QRY_SEARCH_RESULTS_DATA_REQUEST_INIT, DOWNLOAD_QRY_SEARCH_RESULTS_DATA_REQUEST_DONE, DOWNLOAD_QRY_SEARCH_RESULTS_DATA_REQUEST_FAILURE
} from "../constants";

const initState = {
    projects: {
        loading: false,
        error: false,
        msg: '',
        list: {}
    },
    projectslist: {
        loading: false,
        error: false,
        msg: '',
        list: {}
    },
    projectsQuery: {
        loading: false,
        error: false,
        msg: '',
        list: {}
    },
    addProjectInitData: {
        loading: false,
        error: false,
        msg: '',
        data: {}
    },
    saveProject: {
        loading: false,
        error: false,
        msg: ''
    },
    deleteProject: {
        loading: false,
        error: false,
        msg: ''
    },
    projectRowData: {
        loading: false,
        error: false,
        msg: '',
        data: {}
    },
    projectRowDatalog: {
        loading: false,
        error: false,
        msg: '',
        data: {}
    },
    QryPageInitialize: {
        loading: false,
        error: false,
        msg: '',
        data: {}
    },
    QrySearchResult: {
        loading: false,
        error: false,
        msg: '',
        data: {}
    },
    exportQrySearchResult: {
        loading: false,
        error: false,
        msg: '',
    }
};

export const Road = (state = initState, action) => {
    switch (action.type) {
        case GET_PROJECTS_LIST_REQUEST_INIT:
            return Object.assign({}, { ...state }, { projects: { ...state.projects, loading: true, error: false } });
        case GET_PROJECTS_LIST_REQUEST_DONE:
            return Object.assign({}, state, { projects: { ...state.projects, loading: false, error: false, list: action.payload } });
        case GET_PROJECTS_LIST_REQUEST_FAILURE:
            return Object.assign({}, state, { projects: { ...state.projects, loading: false, error: true, list: {}, msg: action.payload } });

        case GET_MY_PROJECTS_LIST_REQUEST_INIT:
            return Object.assign({}, { ...state }, { projectslist: { ...state.projectslist, loading: true, error: false } });
        case GET_MY_PROJECTS_LIST_REQUEST_DONE:
            return Object.assign({}, state, { projectslist: { ...state.projectslist, loading: false, error: false, list: action.payload } });
        case GET_MY_PROJECTS_LIST_REQUEST_FAILURE:
            return Object.assign({}, state, { projectslist: { ...state.projectslist, loading: false, error: true, list: {}, msg: action.payload } });

            case GET_MY_PROJECTS_QUERY_REQUEST_INIT:
                return Object.assign({}, { ...state }, { projectsQuery: { ...state.projectsQuery, loading: true, error: false } });
            case GET_MY_PROJECTS_QUERY_REQUEST_DONE:
                return Object.assign({}, state, { projectsQuery: { ...state.projectsQuery, loading: false, error: false, list: action.payload } });
            case GET_MY_PROJECTS_QUERY_REQUEST_FAILURE:
                return Object.assign({}, state, { projectsQuery: { ...state.projectsQuery, loading: false, error: true, list: {}, msg: action.payload } });

        case LOAD_ADD_PROJECTS_INITIALIZE_DATA_REQUEST_INIT:
            return Object.assign({}, state, { addProjectInitData: { ...state.addProjectInitData, loading: true, error: false } });
        case LOAD_ADD_PROJECTS_INITIALIZE_DATA_REQUEST_DONE:
            return Object.assign({}, state, { addProjectInitData: { ...state.addProjectInitData, loading: false, error: false, data: action.payload } });
        case LOAD_ADD_PROJECTS_INITIALIZE_DATA_REQUEST_FAILURE:
            return Object.assign({}, state, { addProjectInitData: { ...state.addProjectInitData, loading: false, error: true, msg: action.payload, data: {} } });


        case SAVE_PROJECTS_DATA_REQUEST_INIT:
            return Object.assign({}, state, { saveProject: { ...state.saveProject, loading: true, error: false } });
        case SAVE_PROJECTS_DATA_REQUEST_DONE:
            return Object.assign({}, state, { saveProject: { ...state.saveProject, loading: false, error: false } });
        case SAVE_PROJECTS_DATA_REQUEST_FAILURE:
            return Object.assign({}, state, { saveProject: { ...state.saveProject, loading: false, error: true, msg: action.payload } });

        case DELETE_PROJECTS_DATA_REQUEST_INIT:
            return Object.assign({}, state, { deleteProject: { ...state.deleteProject, loading: true, error: false } });
        case DELETE_PROJECTS_DATA_REQUEST_DONE:
            return Object.assign({}, state, { deleteProject: { ...state.deleteProject, loading: false, error: false } });
        case DELETE_PROJECTS_DATA_REQUEST_FAILURE:
            return Object.assign({}, state, { deleteProject: { ...state.deleteProject, loading: false, error: true, msg: action.payload } });

        case TIME_OVERRUN_PROJECTS_CALC_DATA_REQUEST_INIT: //timeOverRunCalc
            return Object.assign({}, state, { timeOverRunCalc: { ...state.timeOverRunCalc, loading: true, error: false } });
        case TIME_OVERRUN_PROJECTS_CALC_DATA_REQUEST_DONE:
            return Object.assign({}, state, { timeOverRunCalc: { ...state.timeOverRunCalc, loading: false, error: false, data: action.payload } });
        case TIME_OVERRUN_PROJECTS_CALC_DATA_REQUEST_FAILURE:
            return Object.assign({}, state, { timeOverRunCalc: { ...state.timeOverRunCalc, loading: false, error: true } });

        case GET_PROJECTS_DETAILS_BASEDON_ID_REQUEST_INIT:
            return Object.assign({}, state, { projectRowData: { ...state.projectRowData, loading: true, error: false } });
        case GET_PROJECTS_DETAILS_BASEDON_ID_REQUEST_DONE:
            return Object.assign({}, state, { projectRowData: { ...state.projectRowData, loading: false, error: false, data: action.payload } });
        case GET_PROJECTS_DETAILS_BASEDON_ID_REQUEST_FAILURE:
            return Object.assign({}, state, { projectRowData: { ...state.projectRowData, loading: false, error: true, msg: action.payload } });

        case GET_PROJECTS_LOG_DETAILS_BASEDON_ID_REQUEST_INIT:
            return Object.assign({}, state, { projectRowDatalog: { ...state.projectRowDatalog, loading: true, error: false } });
        case GET_PROJECTS_LOG_DETAILS_BASEDON_ID_REQUEST_DONE:
            return Object.assign({}, state, { projectRowDatalog: { ...state.projectRowDatalog, loading: false, error: false, data: action.payload?.filter((e) => e?.log_detail !== null) } });
        case GET_PROJECTS_LOG_DETAILS_BASEDON_ID_REQUEST_FAILURE:
            return Object.assign({}, state, { projectRowDatalog: { ...state.projectRowDatalog, loading: false, error: true, msg: action.payload } });

        case GET_QRY_PAGE_INITIALIZE_DATA_REQUEST_INIT:
            return Object.assign({}, state, { QryPageInitialize: { ...state.QryPageInitialize, loading: true, error: false, data: {}, msg: '' } });
        case GET_QRY_PAGE_INITIALIZE_DATA_REQUEST_DONE:
            return Object.assign({}, state, { QryPageInitialize: { ...state.QryPageInitialize, loading: false, error: false, data: action.payload, msg: '' } });
        case GET_QRY_PAGE_INITIALIZE_DATA_REQUEST_FAILURE:
            return Object.assign({}, state, { QryPageInitialize: { ...state.QryPageInitialize, loading: false, error: true, data: {}, msg: action.payload } });

        case GET_QRY_SEARCH_RESULTS_DATA_REQUEST_INIT:
            return Object.assign({}, state, { QrySearchResult: { ...state.QrySearchResult, loading: true, error: false, data: {}, msg: '' } });
        case GET_QRY_SEARCH_RESULTS_DATA_REQUEST_DONE:
            return Object.assign({}, state, { QrySearchResult: { ...state.QrySearchResult, loading: false, error: false, data: action.payload, msg: '' } });
        case GET_QRY_SEARCH_RESULTS_DATA_REQUEST_FAILURE:
            return Object.assign({}, state, { QrySearchResult: { ...state.QrySearchResult, loading: false, error: true, data: {}, msg: action.payload } });

        case DOWNLOAD_QRY_SEARCH_RESULTS_DATA_REQUEST_INIT:
            return Object.assign({}, state, { exportQrySearchResult: { loading: true, error: false, msg: '' } });
        case DOWNLOAD_QRY_SEARCH_RESULTS_DATA_REQUEST_DONE:
            return Object.assign({}, state, { exportQrySearchResult: { loading: false, error: false, msg: '' } });
        case DOWNLOAD_QRY_SEARCH_RESULTS_DATA_REQUEST_FAILURE:
            return Object.assign({}, state, { exportQrySearchResult: { loading: false, error: true, msg: action.payload } });
    }
    return state;
}