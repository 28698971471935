import { GET_WS_LIST_DATA_REQUEST_INIT, GET_WS_LIST_DATA_REQUEST_FAILURE, GET_WS_LIST_DATA_REQUEST_DONE,
    DOWNLOAD_WS_RESULTS_DATA_REQUEST_INIT, DOWNLOAD_WS_RESULTS_DATA_REQUEST_DONE, DOWNLOAD_WS_RESULTS_DATA_REQUEST_FAILURE,
    GET_WS_LIST_INIT_DATA_REQUEST_INIT, GET_WS_LIST_INIT_DATA_REQUEST_DONE, GET_WS_LIST_INIT_DATA_REQUEST_FAILURE,
    LOAD_WS_ADD_PROJECTS_INITIALIZE_DATA_REQUEST_INIT,LOAD_WS_ADD_PROJECTS_INITIALIZE_DATA_REQUEST_DONE,LOAD_WS_ADD_PROJECTS_INITIALIZE_DATA_REQUEST_FAILURE,
    SAVE_WS_PROJECTS_DATA_REQUEST_INIT,SAVE_WS_PROJECTS_DATA_REQUEST_DONE,SAVE_WS_PROJECTS_DATA_REQUEST_FAILURE,
    DELETE_WS_PROJECTS_DATA_REQUEST_INIT,DELETE_WS_PROJECTS_DATA_REQUEST_DONE,DELETE_WS_PROJECTS_DATA_REQUEST_FAILURE,
    GET_WS_PROJECTS_DETAILS_BASEDON_ID_REQUEST_INIT, GET_WS_PROJECTS_DETAILS_BASEDON_ID_REQUEST_DONE, GET_WS_PROJECTS_DETAILS_BASEDON_ID_REQUEST_FAILURE,
    GET_WS_QRY_PAGE_INITIALIZE_DATA_REQUEST_INIT, GET_WS_QRY_PAGE_INITIALIZE_DATA_REQUEST_DONE, GET_WS_QRY_PAGE_INITIALIZE_DATA_REQUEST_FAILURE,
    GET_WS_PROJECTS_LOG_DETAILS_BASEDON_ID_REQUEST_INIT,GET_WS_PROJECTS_LOG_DETAILS_BASEDON_ID_REQUEST_DONE,GET_WS_PROJECTS_LOG_DETAILS_BASEDON_ID_REQUEST_FAILURE,
    GET_QRY_SEARCH_RESULTS_DATA_REQUEST_INIT, GET_QRY_SEARCH_RESULTS_DATA_REQUEST_DONE, GET_QRY_SEARCH_RESULTS_DATA_REQUEST_FAILURE,
    TIME_WS_OVERRUN_PROJECTS_CALC_DATA_REQUEST_INIT,TIME_WS_OVERRUN_PROJECTS_CALC_DATA_REQUEST_DONE,TIME_WS_OVERRUN_PROJECTS_CALC_DATA_REQUEST_FAILURE} from "./../constants";

const initState = {
    loading: false,
    error: false,
    msg: '',
    data: {},
    exportExcel: {
        loading: false,
        error: false,
        msg: '',
    },
    listInitData: {
        loading: false,
        error: false,
        msg: '',
        data: {}
    },
    wsaddProjectInitData: {
        loading: false,
        error: false,
        msg: '',
        data: {}
    },
    wssaveProject: {
        loading: false,
        error: false,
        msg: ''
    },
    wsdeleteProject: {
        loading: false,
        error: false,
        msg: ''
    },
    wsprojectRowData: {
        loading: false,
        error: false,
        msg: '',
        data: {}
    },
    QryPageInitialize: {
        loading: false,
        error: false,
        msg: '',
        data: {}
    },
    wsprojectRowDatalog:{
        loading: false,
        error: false,
        msg: '',
        data: {}
    },
    QrySearchResult: {
        loading: false,
        error: false,
        msg: '',
        data: {}
    },
    exportQrySearchResult: {
        loading: false,
        error: false,
        msg: '',
    }
};

export const WaterSanitation = (state = initState, action) => {
    switch (action.type) {
        case GET_WS_LIST_DATA_REQUEST_INIT:
            return Object.assign({}, state, { loading: true, error: false, data: {} });
        case GET_WS_LIST_DATA_REQUEST_DONE:
            return Object.assign({}, state, { loading: false, error: false, data: action.payload });
        case GET_WS_LIST_DATA_REQUEST_FAILURE:
            return Object.assign({}, state, { loading: false, error: true, msg: action.payload });

        case DOWNLOAD_WS_RESULTS_DATA_REQUEST_INIT:
            return Object.assign({}, state, { exportExcel: { loading: true, error: false, msg: '' } });
        case DOWNLOAD_WS_RESULTS_DATA_REQUEST_DONE:
            return Object.assign({}, state, { exportExcel: { loading: false, error: false, msg: '' } });
        case DOWNLOAD_WS_RESULTS_DATA_REQUEST_FAILURE:
            return Object.assign({}, state, { exportExcel: { loading: false, error: true, msg: '' } });

        case GET_WS_LIST_INIT_DATA_REQUEST_INIT:
            return Object.assign({}, state, {listInitData: { loading: true, error: false, data: {} } });
        case GET_WS_LIST_INIT_DATA_REQUEST_DONE:
            return Object.assign({}, state, {listInitData: { loading: false, error: false, data: action.payload }});
        case GET_WS_LIST_INIT_DATA_REQUEST_FAILURE:
            return Object.assign({}, state, {listInitData: { loading: false, error: true, msg: action.payload }});

        case GET_WS_PROJECTS_DETAILS_BASEDON_ID_REQUEST_INIT:
            return Object.assign({}, state, { wsprojectRowData: { ...state.wsprojectRowData, loading: true, error: false } });
        case GET_WS_PROJECTS_DETAILS_BASEDON_ID_REQUEST_DONE:
            return Object.assign({}, state, { wsprojectRowData: { ...state.wsprojectRowData, loading: false, error: false, data: action.payload } });
        case GET_WS_PROJECTS_DETAILS_BASEDON_ID_REQUEST_FAILURE:
            return Object.assign({}, state, { wsprojectRowData: { ...state.wsprojectRowData, loading: false, error: true, msg: action.payload } });

        case GET_WS_PROJECTS_LOG_DETAILS_BASEDON_ID_REQUEST_INIT:
            return Object.assign({}, state, { wsprojectRowDatalog: { ...state.wsprojectRowDatalog, loading: true, error: false } });
        case GET_WS_PROJECTS_LOG_DETAILS_BASEDON_ID_REQUEST_DONE:
            return Object.assign({}, state, { wsprojectRowDatalog: { ...state.wsprojectRowDatalog, loading: false, error: false, data: action.payload?.filter((e) => e?.log_detail !== null) } });
        case GET_WS_PROJECTS_LOG_DETAILS_BASEDON_ID_REQUEST_FAILURE:
            return Object.assign({}, state, { wsprojectRowDatalog: { ...state.wsprojectRowDatalog, loading: false, error: true, msg: action.payload } });

        case LOAD_WS_ADD_PROJECTS_INITIALIZE_DATA_REQUEST_INIT:
            return Object.assign({}, state, { wsaddProjectInitData: { ...state.wsaddProjectInitData, loading: true, error: false } });
        case LOAD_WS_ADD_PROJECTS_INITIALIZE_DATA_REQUEST_DONE:
            return Object.assign({}, state, { wsaddProjectInitData: { ...state.wsaddProjectInitData, loading: false, error: false, data: action.payload } });
        case LOAD_WS_ADD_PROJECTS_INITIALIZE_DATA_REQUEST_FAILURE:
            return Object.assign({}, state, { wsaddProjectInitData: { ...state.wsaddProjectInitData, loading: false, error: true, msg: action.payload, data: {} } });


        case SAVE_WS_PROJECTS_DATA_REQUEST_INIT:
            return Object.assign({}, state, { wssaveProject: { ...state.wssaveProject, loading: true, error: false } });
        case SAVE_WS_PROJECTS_DATA_REQUEST_DONE:
            return Object.assign({}, state, { wssaveProject: { ...state.wssaveProject, loading: false, error: false } });
        case SAVE_WS_PROJECTS_DATA_REQUEST_FAILURE:
            return Object.assign({}, state, { wssaveProject: { ...state.wssaveProject, loading: false, error: true, msg: action.payload } });

        case DELETE_WS_PROJECTS_DATA_REQUEST_INIT:
            return Object.assign({}, state, { wsdeleteProject: { ...state.wsdeleteProject, loading: true, error: false } });
        case DELETE_WS_PROJECTS_DATA_REQUEST_DONE:
            return Object.assign({}, state, { wsdeleteProject: { ...state.wsdeleteProject, loading: false, error: false } });
        case DELETE_WS_PROJECTS_DATA_REQUEST_FAILURE:
            return Object.assign({}, state, { wsdeleteProject: { ...state.wsdeleteProject, loading: false, error: true, msg: action.payload } });

       case GET_WS_QRY_PAGE_INITIALIZE_DATA_REQUEST_INIT:
            return Object.assign({}, state, { QryPageInitialize: { ...state.QryPageInitialize, loading: true, error: false, data: {}, msg: '' } });
        case GET_WS_QRY_PAGE_INITIALIZE_DATA_REQUEST_DONE:
            return Object.assign({}, state, { QryPageInitialize: { ...state.QryPageInitialize, loading: false, error: false, data: action.payload, msg: '' } });
        case GET_WS_QRY_PAGE_INITIALIZE_DATA_REQUEST_FAILURE:
            return Object.assign({}, state, { QryPageInitialize: { ...state.QryPageInitialize, loading: false, error: true, data: {}, msg: action.payload } });

            case GET_QRY_SEARCH_RESULTS_DATA_REQUEST_INIT:
                return Object.assign({}, state, { QrySearchResult: { ...state.QrySearchResult, loading: true, error: false, data: {}, msg: '' } });
            case GET_QRY_SEARCH_RESULTS_DATA_REQUEST_DONE:
                return Object.assign({}, state, { QrySearchResult: { ...state.QrySearchResult, loading: false, error: false, data: action.payload, msg: '' } });
            case GET_QRY_SEARCH_RESULTS_DATA_REQUEST_FAILURE:
                return Object.assign({}, state, { QrySearchResult: { ...state.QrySearchResult, loading: false, error: true, data: {}, msg: action.payload } });


        case TIME_WS_OVERRUN_PROJECTS_CALC_DATA_REQUEST_INIT: //timeOverRunCalc
            return Object.assign({}, state, { wstimeOverRunCalc: { ...state.wstimeOverRunCalc, loading: true, error: false } });
        case TIME_WS_OVERRUN_PROJECTS_CALC_DATA_REQUEST_DONE:
            return Object.assign({}, state, { wstimeOverRunCalc: { ...state.wstimeOverRunCalc, loading: false, error: false, data: action.payload } });
        case TIME_WS_OVERRUN_PROJECTS_CALC_DATA_REQUEST_FAILURE:
            return Object.assign({}, state, { wstimeOverRunCalc: { ...state.wstimeOverRunCalc, loading: false, error: true } });

    }
    return state;
}