import React, { Component } from 'react';
//import ReactTable from 'react-table-6';
//import 'react-table-6/react-table.css';

const DataTable = ( props ) => {

    return null;

    /***return (
        <>
            <ReactTable {...props} manual />
        </>    
    );***/

}

export default DataTable;