import React, { Component, Fragment } from 'react';
import cx from 'classnames';
import { Nav, NavItem, NavLink, TabContent, TabPane, Card, Table, Row, Col, CustomInput, Button, Container, CardBody,  Form } from 'reactstrap';
import { connect } from 'react-redux';
import { fnGetFinanceDataBasedOnCompanyId, fnDownloadExcelFileData, fnActionCompany } from 'actions';
import _ from 'lodash';
import moment from 'moment';
import { NoDataFound, AjaxLoader } from 'components/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SERVER_URL } from '../../constants';
import { SweetAlert } from 'utils';
import $ from 'jquery';


class Finance extends Component {

    /***
     * Coming Props OPtions are
     * `isViewMode` - True | False
     * `projectData` - for Project Details Object
     * `companyId` - Company Id
     * ***/
    state = {
        activeTab: '3',
        ajaxLoader: false,
        annual_report_form_data: []
    };

    constructor(props) {
        super(props);
        this.isViewCompanyDetails = _.has(this.props, 'isViewCompanyDetails') ? this.props.isViewCompanyDetails : false;
    }

    componentDidMount() {
        this.props.getFinanceDataByCompany(this.props.companyId).then(res => {
            //For Company Finance Details Show Delete All Button
            if (!_.isNil(this.props.getFinaceDataStatus) && _.isFunction(this.props.getFinaceDataStatus)) {
                this.props.getFinaceDataStatus( _.size(res.period) > 0 ? true : false );
            }
        }).catch(err => {
            //Get Error
        });
    }

    toggleTab = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({activeTab: tab});
        }
    }

    deleteFinanceSummary = (obj) => {
        SweetAlert.confirm({
            title: `Delete ${obj.mon_yr} Company Details`,
            text: 'Are you sure want to delete?',
            icon: "warning",
            type: 'warning',
            buttons: ['Cancel', 'Yes, delete it'],
            dangerMode: true,
            closeOnClickOutside: false
        }, (isConfirm) => {
            if (isConfirm) {
                this.props.deleteFinanceSummary(obj).then((res) => {
                    SweetAlert._autoMsg('Success', 'data successfully deleted', 'success');
                    this.props.getFinanceDataByCompany(this.props.companyId);
                })
                .catch(err => {
                    SweetAlert._autoMsg('Error', err, 'error');
                });
            }
        });
    }

    uploadAnnualReportFile = (e) => {
        e.preventDefault();
        let formData = new FormData();
        formData.append('m_company_id', this.props.companyId);
        if (_.size(this.state.annual_report_form_data)) {
            _.each(this.state.annual_report_form_data, (obj, indx) => {
                formData.append(obj.name, obj.file);
                formData.append('id_fin_financial_summary['+obj.name+']', obj.id_fin_financial_summary);
            });
        } else {
            SweetAlert._autoMsg('Warning', 'Please choose any one file', 'warning'); return false;
        }

        this.props.uploadFinanceAnnualReportFile(formData).then(res => {
            SweetAlert._autoMsg('Success', 'file successfully uploaded', 'success');
            this.props.getFinanceDataByCompany(this.props.companyId); this.resetUploadAnnualReportForm(e);
        }).catch(err => {
            SweetAlert._autoMsg('Error', err, 'error');
        });
    }

    onChangeAnnualReportFileHandle = (e, id_fin_financial_summary) => {
        e.preventDefault();
        let file_obj = { file: e.target.files[0], name: e.target.name, id_fin_financial_summary: id_fin_financial_summary };
        this.setState((prevState, curState) => {
            let annual_report_form_data = prevState.annual_report_form_data;
            annual_report_form_data.push(file_obj);
            return {
                ...prevState,
                annual_report_form_data
            };
        });
    }

    resetUploadAnnualReportForm = (e) => {
        $("label.custom-file-label", "#annual_report_upload_form").html('Choose file');
        this.setState({ annual_report_form_data: [] });
    }

    deleteFinanceAnnualReportFile = (id_fin_annual_report, mon_yr) => { //deleteUploadAnnualReport
        SweetAlert.confirm({
            title: `Delete ${mon_yr} Annual report file`,
            text: 'Are you sure want to delete?',
            icon: "warning",
            type: 'warning',
            buttons: ['Cancel', 'Yes, delete it'],
            dangerMode: true,
            closeOnClickOutside: false
        }, (isConfirm) => {
            if (isConfirm) {
                this.props.deleteFinanceAnnualReportFile({ id_fin_annual_report }).then(res => {
                    SweetAlert._autoMsg('Success', 'file successfully removed', 'success');
                    this.props.getFinanceDataByCompany(this.props.companyId);
                }).catch(err => {
                    SweetAlert._autoMsg('Error', err, 'error');
                });
            }
        });


    }

    static SetBold = (data) => (<strong>{data}</strong>);

    static BoradOfDirectorsContent(props) {

        let exportParams = { 'for': 'export_board_director', 'm_company_id': props.companyId };
        let exportFileName = 'export_board_director_' + _.now() + '.xls'; //downloadExcelFilesData(exportParams, exportFileName);

        const { board_directors, downloadExcelFilesData, userRights: { VIEW_FINANCE } } = props;

        return (
            <Fragment>
                {_.size(board_directors) > 0 ? (
                    <Fragment>
                        <Table bordered  hover size={'md'} className="board-director-tbl table-custom">
                            <thead>
                                <tr>
                                    <th colSpan="2">Board Of Directors - { moment(_.get(board_directors, '0.financial_period'), 'YYYY-MM-DD').format('MMM-YYYY') }</th>
                                </tr>
                                <tr>
                                    <th className="list-heading" width="25%">Name</th>
                                    <th className="list-heading" width="25%">Designation</th>
                                </tr>
                            </thead>
                            <tbody>
                                {board_directors.map((obj, indx) => (
                                            <tr key={indx}>
                                                <td>{obj.director_name||''}</td>
                                                <td>{obj.director_designation||''}</td>
                                            </tr>
                                        )
                                    )
                                }
                            </tbody>
                        </Table>
                        {VIEW_FINANCE.ALLOW_EXCEL_EXPORT ? (
                            <div className="text-right"><a href="" className="btn btn-sm btn-outline-first btn-square" onClick={(e) => { e.preventDefault(); downloadExcelFilesData(exportParams, exportFileName);}} ><FontAwesomeIcon icon={['fa', 'file-excel']}/>&nbsp;Export</a></div>
                        ) : null}
                    </Fragment>
                ) : ( <Row> <Col> <NoDataFound /> </Col> </Row> ) }
            </Fragment>
        );
    }

    static ShareHoldersContent(props) {

        let exportParams = { 'for': 'export_key_share_holders', 'm_company_id': props.companyId };
        let exportFileName = 'export_key_share_holders_' + _.now() + '.xls'; //downloadExcelFilesData(exportParams, exportFileName);

        const { period, key_share_holder, downloadExcelFilesData, userRights: { VIEW_FINANCE } } = props;

        const shareHolderCont = _.map(key_share_holder.view_format, (obj, name) => {

            let _inTd = _.map(period, (mon_yr, indx) => {
                let rs_val = '-';
                if (_.has(obj, `${mon_yr}.share_percentage`) && !_.isEmpty(_.get(obj, `${mon_yr}.share_percentage`))) {
                    rs_val = _.get(obj, `${mon_yr}.share_percentage`);
                }
                return <td key={"_share_td"+indx} className="text-right">{rs_val}</td>;
            });

            return (
                <tr key={name}>
                    <td>{name}</td>
                    {_inTd}
                </tr>
            );
        });

        return (
            <Fragment>
                {(_.size(period) > 0 && _.size(key_share_holder) > 0 && _.has(key_share_holder, 'view_format')) ? (
                    <Fragment>
                        <Table bordered  hover size={'md'} className="table-custom">
                            <thead>
                                <tr>
                                    <th style={{ width: '30%' }}>&nbsp;</th>
                                    {_.map(period, (mon_yr, indx) => (<th className="text-right" key={indx} style={{ width: '10%' }}>{mon_yr}</th> ))}
                                </tr>
                                <tr>
                                    <th className="list-heading" style={{ width: '30%' }}>Key Share Holders</th>
                                    {_.map(period, (mon_yr, indx) => (<th className="text-right list-heading" key={indx}>%</th> ))}
                                </tr>
                            </thead>
                            <tbody>
                                {shareHolderCont}
                            </tbody>
                        </Table>
                        {VIEW_FINANCE.ALLOW_EXCEL_EXPORT ? (
                            <div className="text-right"><a href="" className="btn btn-sm btn-outline-first btn-square" onClick={(e) => { e.preventDefault(); downloadExcelFilesData(exportParams, exportFileName);}} ><FontAwesomeIcon icon={['fa', 'file-excel']} /> &nbsp;Export</a></div>
                        ): null}
                    </Fragment>
                ) : ( <Row> <Col> <NoDataFound /> </Col> </Row> ) }
            </Fragment>
        );
    }

    static ExecutiveSummaryContent(props) {

        let exportParams = { 'for': 'export_executive_summary', 'm_company_id': props.companyId };
        let exportFileName = 'export_executive_summary_' + _.now() + '.xls'; //downloadExcelFilesData(exportParams, exportFileName);

        const { financial_summary, period, source_funds, application_funds, cash_flow, key_ratio, downloadExcelFilesData, deleteFinanceSummary, isViewMode = false, userRights: { VIEW_FINANCE } } = props;

        if (_.size(period) === 0) {
            return (
                <Row> <Col> <NoDataFound /> </Col> </Row>
            );
        }

        const defaultObj = { data: {}, names: {} };

        const { data: financial_summary_data, names: financial_summary_names } = financial_summary || defaultObj;
        const { data: source_funds_data, names: source_funds_names } = source_funds || defaultObj;
        const { data: application_funds_data, names: application_funds_names } = application_funds || defaultObj;
        const { data: cash_flow_data, names: cash_flow_names } = cash_flow || defaultObj;
        const { data: key_ratio_data, names: key_ratio_names } = key_ratio || defaultObj;

        let $p_l_arr = ["net_sales", "sales_growth", "pbdita_exl_oi", "pbit", "interest", "pbt", "profit_after_tax"];
        //Balance Sheet
        let $bal_sheet_arr = ["shareholder_funds", "term_loan", "net_block"/** Table App funds **/, "current_investments", "inventories", "cash_bank", "sundry_debtors", "total_assets"];

        //Ratio
        let $ratio_arr = [
            /**** Table Financial Summary ***/
            "pbdita_per", "pbit_per", "interest_per", "pat_per",
            /**** Table key_ratio ***/
            "nwc_percentage", "roce", "ronw", "receivable_days", "payable_days"
        ];

        //For Sales Growth Calculation
        let sales_growth = [], sales_growth_per = {};
        _.each(period, (mon_yr) => {
            sales_growth.push( _.has(financial_summary_data, `${mon_yr}.net_sales`) ? _.get(financial_summary_data, `${mon_yr}.net_sales`) : "" );
        });
        _.each(sales_growth, (v, k) => {
            if (k === 0) {
                sales_growth_per[k] = "";
            } else {
                try {
                    let sales_grow_rs = (((+v - +sales_growth[k - 1]) / +sales_growth[k - 1]) * 100);
                    sales_growth_per[k] =  sales_grow_rs.toFixed(2) + "%";
                } catch (error) {
                    sales_growth_per[k] = "";
                }
            }
        });

        //P&L Summary Data
        const PLSummaryContent = $p_l_arr.map((key, indx) => {

            if (key === 'sales_growth') {
                let i = 0;
                let _inTd = [];

                _.each(period, (mon_yr, _indx) => {
                    let rs_val = '-';
                    if (_.has(sales_growth_per, `${i}`) && !_.isEmpty(_.get(sales_growth_per, `${i}`))) {
                        rs_val = _.get(sales_growth_per, `${i}`);
                    }
                    i++;
                    _inTd.push(<td className="text-right" key={'_pl_in' + _indx}>{rs_val}</td>);
                });

                return (
                    <tr key={'p_l_summary'+indx}>
                        <td style={{ textIndent: '30px' }}>Sales Growth</td>
                        {_inTd}
                    </tr>
                );

            } else {
                let _inTd = [];
                _.each(period, (mon_yr, _indx) => {
                    let rs_val = '-';
                    if (_.has(financial_summary_data, `${mon_yr}.${key}`) && !_.isEmpty(_.get(financial_summary_data, `${mon_yr}.${key}`))) {
                        rs_val = _.get(financial_summary_data, `${mon_yr}.${key}`);
                    }
                    _inTd.push(<td className="text-right" key={'_in'+_indx}>{rs_val}</td>);
                });

                return (
                    <tr key={'p_l_summary'+indx}>
                        <td>{_.get(financial_summary_names, `${key}.name`)}</td>
                        {_inTd}
                    </tr>
                );
            }
        });

        //Calculate Term Loan
        let term_loans = {};
        _.each(period, ($mon_yr) => {
            let $secured_loans = _.has(source_funds_data, `${$mon_yr}.secured_loans`) ? +_.get(source_funds_data, `${$mon_yr}.secured_loans`) : 0;
            let $unsecured_loans = _.has(source_funds_data, `${$mon_yr}.unsecured_loans`) ? +_.get(source_funds_data, `${$mon_yr}.unsecured_loans`) : 0;
            term_loans[$mon_yr] = ($secured_loans + $unsecured_loans);
        });

        const balSheetContent = $bal_sheet_arr.map((key, indx) => {
            if (key === 'term_loan') {

                let _inTd = [];
                _.each(period, (mon_yr, _indx) => {
                    let rs_val = '-';
                    if (_.has(term_loans, `${mon_yr}`) && !_.isEmpty(_.get(term_loans, `${mon_yr}`))) {
                        rs_val = _.get(term_loans, `${mon_yr}`);
                    }
                    _inTd.push(<td className="text-right" key={_indx}>{rs_val}</td>);
                });

                return (
                    <tr key={'bal_sheet_content'+indx}>
                        <td>Term Loan</td>
                        {_inTd}
                    </tr>
                );

            } else {
                let names = {}, data_rs = {};
                if (_.has(source_funds_names, key)) {
                    names = _.get(source_funds_names, key);
                    data_rs = source_funds_data;
                } else if ( _.has(application_funds_names, key) ) {
                    names = _.get(application_funds_names, key);
                    data_rs = application_funds_data;
                }

                let tdName = (<td>{names.name}</td>);
                if (_.has(names, 'bold') && _.get(names, 'bold') == true) {
                    tdName = (<td><strong>{names.name}</strong></td>);
                }

                let _inTd = [];
                _.each(period, (mon_yr, _indx) => {
                    let rs_val = '-';
                    if (_.has(data_rs, `${mon_yr}.${key}`) && !_.isEmpty(_.get(data_rs, `${mon_yr}.${key}`))) {
                        rs_val =_.get(data_rs, `${mon_yr}.${key}`);
                    }
                    let _tdName = (<td key={_indx}  className="text-right">{rs_val}</td>);
                    if (_.has(names, 'bold') && _.get(names, 'bold') == true) {
                        _tdName = (<td key={_indx} className="text-right"><strong>{rs_val}</strong></td>);
                    }
                    _inTd.push(_tdName);
                });

                return (
                    <tr key={'bal_sheet_content'+indx}>
                        {tdName}
                        {_inTd}
                    </tr>
                );
            }
        });

        //For Cash Flow
        let cashFlowContent = [];
        if (_.size(cash_flow_names)) {

            _.each(cash_flow_names, (names, key) => {

                let _inTd = [];
                _.each(period, (mon_yr, indx) => {
                    _inTd.push(<td className="text-right" key={indx}>{_.get(cash_flow_data, `${mon_yr}.${key}`)}</td>);
                });
                let _inTr = (
                    <tr key={'tr_cash_flow'+key}>
                        <td>{names.name}</td>
                        {_inTd}
                    </tr>
                );
                cashFlowContent.push(_inTr);
            })

        } else {
            cashFlowContent.push((<tr key='cash_flow_key'><td colSpan={ _.size(period) + 1 }>No Data Found</td></tr>) );
        }

        //For Key Ratio
        const ratioContent = $ratio_arr.map((key, indx) => {

            let names = {}, data_rs = {};

            if (_.has(financial_summary_names, key)) {

                names = _.get(financial_summary_names, `${key}`);
                data_rs = financial_summary_data;

            } else if (_.has(key_ratio_names, key)) {

                names = _.get(key_ratio_names, `${key}`);
                data_rs = key_ratio_data;
            }

            let _inTd = [];
            _.each(period, (mon_yr, _indx) => {
                _inTd.push(<td className="text-right" key={_indx}>{_.get(data_rs, `${mon_yr}.${key}`)}</td>);
            });

            return (
                <tr key={'ratio'+indx}>
                    <td>{names.name}</td>
                    {_inTd}
                </tr>
            );
        });

        return (
            <Fragment>
                <Table bordered  hover size={'md'} className="table-custom">
                    <thead style={{position:"sticky",top:-10, background:"#fff"}}>
                        {(isViewMode || !VIEW_FINANCE.ALLOW_DELETE_YR) ? null : (
                            <tr>
                                <th style={{ width: '30%' }}>&nbsp;</th>
                                {_.map(period, (mon_yr, indx) => (<th key={indx} style={{ width: '10%' }} className="text-center"><a href="" onClick={(e) => { e.preventDefault(); deleteFinanceSummary({mon_yr, id_fin_financial_summary: indx});}} className="text-danger" title={mon_yr}><FontAwesomeIcon icon={['fa', 'trash']} /></a></th>))}
                            </tr>
                        )}
                        <tr>
                            <th style={{ width: '30%' }}>&nbsp;</th>
                            {_.map(period, (mon_yr, indx) => (<th key={indx} className="text-right" style={{ width: '10%' }}>{mon_yr}</th> ))}
                        </tr>
                    </thead>
                    <tbody>
                        {/****** P & L Summary - Start *****/}
                        <tr>
                            <th className="list-heading">Executive Summary</th>
                            {_.map(period, (mon_yr, indx) => (<th key={indx} className="list-heading text-right">Rs. Crs</th> ))}
                        </tr>
                        {PLSummaryContent}
                        {/****** P & L Summary - End *****/}
                        {/****** Start Balance Summary Start *****/}
                        <tr>
                            <th className="list-heading">Balance Sheet Summary</th>
                            {_.map(period, (mon_yr, indx) => (<th  key={indx} className="list-heading text-right">Rs. Crs</th> ))}
                        </tr>
                        {balSheetContent}
                        {/****** Start Balance Summary End *****/}
                        {/****** Cash Flow - Start *****/}
                        <tr>
                            <th className="list-heading">Cash Flow</th>
                            {_.map(period, (mon_yr, indx) => (<th key={indx} className="list-heading text-right">Rs. Crs</th> ))}
                        </tr>
                        {cashFlowContent}
                        {/****** Cash Flow - End *****/}
                        {/****** Ratio - Start *****/}
                        <tr>
                            <th className="list-heading">Ratio</th>
                            {_.map(period, (mon_yr, indx) => (<th key={indx} className="list-heading text-right">&nbsp;</th> ))}
                        </tr>
                        {ratioContent}
                        {/****** Ratio - End *****/}
                    </tbody>
                </Table>
                {VIEW_FINANCE.ALLOW_EXCEL_EXPORT ? (
                    <div className="text-right"><a href="" className="btn btn-sm btn-outline-first btn-square" onClick={(e) => { e.preventDefault(); downloadExcelFilesData(exportParams, exportFileName); }} ><FontAwesomeIcon icon={['fa', 'file-excel']} /> &nbsp;Export</a></div>
                ) : null}
            </Fragment>
        );
    }

    static ProfitAndLossContent(props) {

        let exportParams = { 'for': 'export_profit_loss', 'm_company_id': props.companyId };
        let exportFileName = 'export_profit_loss_' + _.now() + '.xls'; //downloadExcelFilesData(exportParams, exportFileName);

        const { financial_summary, period, downloadExcelFilesData, userRights: { VIEW_FINANCE } } = props;

        if (_.size(period) === 0) {
            return (
                <Row> <Col> <NoDataFound /> </Col> </Row>
            );
        }

        const defaultObj = { data: {}, names: {} };

        const { data: financial_summary_data, names: financial_summary_names } = financial_summary || defaultObj;

        let opt_key = ['pbdita_inc_oi', 'pbdita_per', 'pbit_per', 'interest', 'interest_per', 'profit_after_tax', 'pat_per'];

        const profitLostContent = _.map(financial_summary_names, (names, key) => {
            let style = {}, content = '', bgsphighlight = '', isBoldText = false;
            if (_.has(names, 'text_indent') && !_.isEmpty(names.text_indent)) {
                style['textIndent'] = names.text_indent;
            }

            if (_.has(names, 'bold') && _.get(names, 'bold') == true) { isBoldText = true }

            if (_.indexOf(opt_key, key) > -1) {
                bgsphighlight = "list-sp-highlight";
            }

            let _inTd = _.map(period, (mon_yr, _indx) => {
                let rs_val = '-';
                if (_.has(financial_summary_data, `${mon_yr}.${key}`) && !_.isEmpty(_.get(financial_summary_data, `${mon_yr}.${key}`))) {
                    rs_val =_.get(financial_summary_data, `${mon_yr}.${key}`);
                }
                return (<td className={cx('text-right', bgsphighlight)} key={'_td_pl_cont'+_indx} style={style}>{isBoldText ? Finance.SetBold(rs_val) : rs_val}</td>);
            })

            return (
                <tr key={'tr_pl_'+key}>
                    <td className={bgsphighlight} style={style}> {isBoldText ? Finance.SetBold(names.name) : names.name}</td>
                    {_inTd}
                </tr>
            );
        });

        return (
            <Fragment>
                <Table bordered  hover size={'md'} className="table-custom">
                    <thead style={{position:"sticky",top:0, background:"#fff"}}>
                        <tr>
                            <th style={{ width: '30%' }}>&nbsp;</th>
                            {_.map(period, (mon_yr, indx) => (<th key={indx} className="text-right" style={{ width: '10%' }}>{mon_yr}</th> ))}
                        </tr>
                        <tr>
                            <th className="list-heading">P&L Summary</th>
                            {_.map(period, (mon_yr, indx) => (<th key={indx} className="text-right list-heading" style={{ width: '10%' }}>Rs. Crs</th> ))}
                        </tr>
                    </thead>
                    <tbody>
                        {profitLostContent}
                    </tbody>
                </Table>
                {VIEW_FINANCE.ALLOW_EXCEL_EXPORT ? (
                    <div className="text-right"><a href="" className="btn btn-sm btn-outline-first btn-square" onClick={(e) => { e.preventDefault(); downloadExcelFilesData(exportParams, exportFileName);}} ><FontAwesomeIcon icon={['fa', 'file-excel']} /> &nbsp;Export</a></div>
                ) : null}
            </Fragment>
        );
    }

    static BalanceSheetContent(props) {

        let exportParams = { 'for': 'export_balance_sheet', 'm_company_id': props.companyId };
        let exportFileName = 'export_balance_sheet_' + _.now() + '.xls'; //downloadExcelFilesData

        const { financial_summary, period, source_funds, application_funds, cash_flow, key_ratio, downloadExcelFilesData, userRights: { VIEW_FINANCE } } = props;

        if (_.size(period) === 0) {
            return (
                <Row> <Col> <NoDataFound /> </Col> </Row>
            );
        }

        const defaultObj = { data: {}, names: {} };

        const { data: financial_summary_data, names: financial_summary_names } = financial_summary || defaultObj;
        const { data: source_funds_data, names: source_funds_names } = source_funds || defaultObj;
        const { data: application_funds_data, names: application_funds_names } = application_funds || defaultObj;
        const { data: cash_flow_data, names: cash_flow_names } = cash_flow || defaultObj;
        const { data: key_ratio_data, names: key_ratio_names } = key_ratio || defaultObj;

        const balSheetContent = _.map(source_funds_names, (names, key) => {
            let style = {}, content = '', bgsphighlight = '', isBoldText = false;
            if (_.has(names, 'text_indent') && !_.isEmpty(names.text_indent)) {
                style['textIndent'] = names.text_indent;
            }

            if (_.has(names, 'bold') && _.get(names, 'bold') == true) { isBoldText = true }

            let _inTd = _.map(period, (mon_yr, _indx) => {
                let rs_val = '-';
                if (_.has(source_funds_data, `${mon_yr}.${key}`) && !_.isEmpty(_.get(source_funds_data, `${mon_yr}.${key}`))) {
                    rs_val =_.get(source_funds_data, `${mon_yr}.${key}`);
                }
                return (<td className="text-right" key={'_bal_td'+_indx} style={style}>{isBoldText ? Finance.SetBold(rs_val) : rs_val}</td>);
            })

            return (
                <tr key={'_bal_tr'+key}>
                    <td style={style}> {isBoldText ? Finance.SetBold(names.name) : names.name}</td>
                    {_inTd}
                </tr>
            );
        });

        const appFundContent = _.map(application_funds_names, (names, key) => {
            let style = {}, content = '', bgsphighlight = '', isBoldText = false;
            if (_.has(names, 'text_indent') && !_.isEmpty(names.text_indent)) {
                style['textIndent'] = names.text_indent;
            }
            if (_.has(names, 'bold') && _.get(names, 'bold') == true) { isBoldText = true }

            let _inTd = _.map(period, (mon_yr, _indx) => {
                let rs_val = '-';
                if (_.has(application_funds_data, `${mon_yr}.${key}`) && !_.isEmpty(_.get(application_funds_data, `${mon_yr}.${key}`))) {
                    rs_val =_.get(application_funds_data, `${mon_yr}.${key}`);
                }
                return (<td className="text-right" key={'_app_td'+_indx} style={style}>{isBoldText ? Finance.SetBold(rs_val) : rs_val}</td>);
            })

            return (
                <tr key={'_app_tr'+key}>
                    <td style={style}> {isBoldText ? Finance.SetBold(names.name) : names.name}</td>
                    {_inTd}
                </tr>
            );
        });

        return (
            <Fragment>
                <Table bordered  hover size={'md'} className="table-custom">
                    <thead style={{position:"sticky",top:0, background:"#fff"}}>
                        <tr>
                            <th style={{ width: '30%' }}>&nbsp;</th>
                            {_.map(period, (mon_yr, indx) => (<th key={indx} className="text-right" style={{ width: '10%' }}>{mon_yr}</th> ))}
                        </tr>
                        <tr>
                            <th className="list-heading">Source Of Funds</th>
                            {_.map(period, (mon_yr, indx) => (<th key={indx} className="text-right list-heading" style={{ width: '10%' }}>Rs. Crs</th> ))}
                        </tr>
                    </thead>
                    <tbody>
                        {balSheetContent}
                        <tr>
                            <th className="list-heading" colSpan={_.size(period) + 1}>Application Of Funds</th>
                        </tr>
                        {appFundContent}
                    </tbody>
                </Table>
                {VIEW_FINANCE.ALLOW_EXCEL_EXPORT ? (
                    <div className="text-right"><a href="" className="btn btn-sm btn-outline-first btn-square" onClick={(e) => { e.preventDefault(); downloadExcelFilesData(exportParams, exportFileName);}} ><FontAwesomeIcon icon={['fa', 'file-excel']} /> &nbsp;Export</a></div>
                ) : null}

            </Fragment>
        );
    }

    static KeyRatioTabContent(props) {

        let exportParams = { 'for': 'export_key_ratio', 'm_company_id': props.companyId };
        let exportFileName = 'export_key_ratio_' + _.now() + '.xls';

        const { financial_summary, period, source_funds, application_funds, cash_flow, key_ratio, downloadExcelFilesData, userRights: { VIEW_FINANCE } } = props;

        if (_.size(period) === 0) {
            return (
                <Row> <Col> <NoDataFound /> </Col> </Row>
            );
        }

        const defaultObj = { data: {}, names: {} };

        const { data: financial_summary_data, names: financial_summary_names } = financial_summary || defaultObj;
        const { data: source_funds_data, names: source_funds_names } = source_funds || defaultObj;
        const { data: application_funds_data, names: application_funds_names } = application_funds || defaultObj;
        const { data: cash_flow_data, names: cash_flow_names } = cash_flow || defaultObj;
        const { data: key_ratio_data, names: key_ratio_names } = key_ratio || defaultObj;

        if (_.size(period) === 0) return null;

        const keyRatioContent = _.map(key_ratio_names, (names, key) => {
            let style = {}, isBoldText = false;
            if (_.has(names, 'text_indent') && !_.isEmpty(names.text_indent)) {
                style['textIndent'] = names.text_indent;
            }
            if (_.has(names, 'bold') && _.get(names, 'bold') == true) { isBoldText = true }

            let _inTd = _.map(period, (mon_yr, _indx) => {
                let rs_val = '-';
                if (_.has(key_ratio_data, `${mon_yr}.${key}`) && !_.isEmpty(_.get(key_ratio_data, `${mon_yr}.${key}`))) {
                    if (_.indexOf(['d_e', 'current_ratio'], key) > -1) {
                        rs_val = (+_.get(key_ratio_data, `${mon_yr}.${key}`)).toFixed(2);
                    } else {
                        rs_val =_.get(key_ratio_data, `${mon_yr}.${key}`);
                    }
                }
                return (<td key={'k_ratio_td_'+_indx} className="text-right" style={style}>{isBoldText ? Finance.SetBold(rs_val) : rs_val}</td>);
            })

            return (
                <tr key={'k_ratio_tr_'+key} >
                    <td style={style}> {isBoldText ? Finance.SetBold(names.name) : names.name}</td>
                    {_inTd}
                </tr>
            );
        });

        return (
            <Fragment>
                <Table bordered  hover size={'md'} className="table-custom">
                    <thead style={{position:"sticky",top:0, background:"#fff"}}>
                        <tr>
                            <th style={{ width: '30%' }} className="list-heading">Key Ratio</th>
                            {_.map(period, (mon_yr, indx) => (<th key={indx} className="text-right list-heading" style={{ width: '10%' }}>{mon_yr}</th> ))}
                        </tr>
                    </thead>
                    <tbody>
                        {keyRatioContent}
                    </tbody>
                </Table>
                {VIEW_FINANCE.ALLOW_EXCEL_EXPORT ? (
                    <div className="text-right"><a href="" className="btn btn-sm btn-outline-first btn-square" onClick={(e) => { e.preventDefault(); downloadExcelFilesData(exportParams, exportFileName);}} ><FontAwesomeIcon icon={['fa', 'file-excel']} /> &nbsp;Export</a></div>
                ): null}
            </Fragment>
        );
    }

    static CashFlowTabContent(props) {

        let exportParams = { 'for': 'export_cash_flow', 'm_company_id': props.companyId };
        let exportFileName = 'export_cash_flow_'+_.now()+'.xls';

        const { financial_summary, period, source_funds, application_funds, cash_flow, key_ratio, downloadExcelFilesData, userRights: { VIEW_FINANCE } } = props;

        if (_.size(period) === 0) {
            return (
                <Row> <Col> <NoDataFound /> </Col> </Row>
            );
        }

        const defaultObj = { data: {}, names: {} };

        const { data: financial_summary_data, names: financial_summary_names } = financial_summary || defaultObj;
        const { data: source_funds_data, names: source_funds_names } = source_funds || defaultObj;
        const { data: application_funds_data, names: application_funds_names } = application_funds || defaultObj;
        const { data: cash_flow_data, names: cash_flow_names } = cash_flow || defaultObj;
        const { data: key_ratio_data, names: key_ratio_names } = key_ratio || defaultObj;

        if (_.size(period) === 0) return null;

        const cashFlowContent = _.map(cash_flow_names, (names, key) => {
            let style = {}, isBoldText = false;
            if (_.has(names, 'text_indent') && !_.isEmpty(names.text_indent)) {
                style['textIndent'] = names.text_indent;
            }
            if (_.has(names, 'bold') && _.get(names, 'bold') == true) { isBoldText = true }

            let _inTd = _.map(period, (mon_yr, _indx) => {
                let rs_val = '-';
                if (_.has(cash_flow_data, `${mon_yr}.${key}`) && !_.isEmpty(_.get(cash_flow_data, `${mon_yr}.${key}`))) {
                    rs_val =_.get(cash_flow_data, `${mon_yr}.${key}`);
                }
                return (<td key={'csh_flw'+_indx} className="text-right" style={style}>{isBoldText ? Finance.SetBold(rs_val) : rs_val}</td>);
            })

            return (
                <tr key={'csh_flw_tr'+key} >
                    <td style={style}> {isBoldText ? Finance.SetBold(names.name) : names.name}</td>
                    {_inTd}
                </tr>
            );
        });

        return (
            <Fragment>
                <Table bordered  hover size={'md'} className="table-custom">
                    <thead style={{position:"sticky",top:0, background:"#fff"}}>
                        <tr>
                            <th style={{ width: '30%' }}>&nbsp;</th>
                            {_.map(period, (mon_yr, indx) => (<th key={indx} className="text-right" style={{ width: '10%' }}>{mon_yr}</th> ))}
                        </tr>
                        <tr>
                            <th style={{ width: '30%' }} className="list-heading">Cash Flow</th>
                            {_.map(period, (mon_yr, indx) => (<th key={indx} className="text-right list-heading" style={{ width: '10%' }}>Rs.Crs</th> ))}
                        </tr>
                    </thead>
                    <tbody>
                        {cashFlowContent}
                    </tbody>
                </Table>
                {VIEW_FINANCE.ALLOW_EXCEL_EXPORT ? (
                    <div className="text-right">
                        <a href="" className="btn btn-sm btn-outline-first btn-square" onClick={(e) => { e.preventDefault(); downloadExcelFilesData(exportParams, exportFileName); }} >
                            <FontAwesomeIcon icon={['fa', 'file-excel']}/> &nbsp;Export
                        </a>
                    </div>
                 ) : null}

            </Fragment>
        );
    }

    static AnnualReportTabContent(props) {
        const { financial_summary, period, source_funds, application_funds, cash_flow, key_ratio, annual_report, isViewMode = false, uploadAnnualReportFile, resetUploadAnnualReportForm, onChangeAnnualReportFileHandle, deleteFinanceAnnualReportFile, userRights: { VIEW_FINANCE } } = props; //ALLOW_ADD_ANNUAL_REPORT

        if (_.size(period) === 0) {
            return (
                <Row> <Col> <NoDataFound /> </Col> </Row>
            );
        }

        const defaultObj = { data: {}, names: {} };

        const { data: financial_summary_data, names: financial_summary_names } = financial_summary || defaultObj;
        const { data: source_funds_data, names: source_funds_names } = source_funds || defaultObj;
        const { data: application_funds_data, names: application_funds_names } = application_funds || defaultObj;
        const { data: cash_flow_data, names: cash_flow_names } = cash_flow || defaultObj;
        const { data: key_ratio_data, names: key_ratio_names } = key_ratio || defaultObj;

        const annualReportContent = _.map(period, (mon_yr, id_fin_financial_summary) => {

            let _td = null;
            if (!_.isNil(annual_report) && !_.isEmpty(annual_report) && _.has(annual_report, id_fin_financial_summary)) {

                let id_fin_annual_report = _.get(annual_report, `${id_fin_financial_summary}.id_fin_annual_report`);

                _td = (<td key={"id_id"+id_fin_financial_summary}>
                    <a href={SERVER_URL+"annual_report/"+ _.get(annual_report, `${id_fin_financial_summary}.file_name`)} target="_blank" className="btn btn-sm btn-square btn-outline-primary"><FontAwesomeIcon icon={['fa', 'eye']}/> Annual Report</a>
                    {(!isViewMode || VIEW_FINANCE.ALLOW_DELETE_YR) ? (<a href="#" onClick={e => { e.preventDefault(); deleteFinanceAnnualReportFile( id_fin_annual_report, period[id_fin_financial_summary] ); }} className="text-danger" style={{ marginLeft: '15px' }}><FontAwesomeIcon icon={['fa', 'trash']} /></a>) : null}
                </td>)
            } else {

                _td = (<td key={"id_id" + id_fin_financial_summary} className="text-left" style={{ width: '75%' }}>
                        {(!isViewMode || VIEW_FINANCE.ALLOW_ADD_ANNUAL_REPORT) ? ( <CustomInput type="file" id={'select_pdf_file' + id_fin_financial_summary} name={'report_file_'+id_fin_financial_summary} accept="application/pdf" className="w-25" onChange={(e) => onChangeAnnualReportFileHandle(e, id_fin_financial_summary)} /> ): <>No data available</> }
                    </td>);
            }

            return (<tr key={'tr__'+id_fin_financial_summary}><th className="text-center" style={{ width: '25%' }}> {mon_yr} </th>{_td}</tr>);
        })

        return (
            <Fragment>
                <Form onSubmit={uploadAnnualReportFile} id="annual_report_upload_form" className="form-customise">
                    <Table bordered  hover size={'sm'} className="table-custom">
                        <tbody>
                            {annualReportContent}
                            {(!isViewMode || VIEW_FINANCE.ALLOW_ADD_ANNUAL_REPORT) ? (
                                <Fragment>
                                    <tr>
                                        <td colSpan="2">&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2" className="text-center">
                                            <Button outline color="warning" size="sm" className="mr-3 form-reset-cancel-btn" type="reset" onClick={resetUploadAnnualReportForm} >Cancel</Button>
                                            <Button outline color="primary" size="sm" className="form-submit-btn" type="submit">Upload</Button>
                                        </td>
                                    </tr>
                                </Fragment>
                            ) : null}

                        </tbody>
                    </Table>
                </Form>
            </Fragment>
        );
    }

    static ProjectDetailsData(props) {
        const { projectData } = props;
        return (<Row>
            <Col className="p-0">
                <Card className="card-list-box finance-card-container">
                    <CardBody>
                        <Row>
                            <Col className="border-right">
                                <Row>
                                    <Col md="12">
                                        <label>Company Name:</label> <b>{projectData.company_detail.company_name}</b></Col>
                                    <Col md="12">
                                        <label>CIN #:</label> <b>{projectData.company_detail.cin_no}</b>
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row>
                                    <Col md="12">
                                        <label>Project Name:</label> <b>{projectData.road_project_name}</b>
                                    </Col>
                                    <Col md="12">
                                        <label>TPC:</label> <b>{projectData.tpc} Crs</b>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Row>);
    }

    static CompanyDetailsData(props) {
        const { company_details } = props;
        return (<Row>
            <Col className="p-0">
                <Card className="card-list-box finance-card-container">
                    <CardBody>
                        <Row>
                            <Col className="border-right">
                                <Row>
                                    <Col md="12">
                                        <label>Company Name:</label> <b>{company_details.company_name}</b></Col>
                                    <Col md="12">
                                        <label>CIN #:</label> <b>{company_details.cin_no}</b>
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row>
                                    <Col md="12">
                                        <label>Parent Company:</label> <b>{company_details.parent_company_name.join(', ')}</b>
                                    </Col>
                                    <Col md="12">
                                        <label>Company Type:</label> <b>{company_details.company_type} Crs</b>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Row>);
    }

    static FinanceTabNavContent(props) {
        const { activeTab, toggleTab, propsData, deleteFinanceSummary } = props;
        return (
            <Row>
                <Col className="p-0 mt-1">
                    <Nav tabs className="finance-nav-tabs">
                    <NavItem>
                            <NavLink className={cx({ active: activeTab === '3' })} onClick={() => toggleTab('3')}>
                                Executive Summary
                            </NavLink>
                        </NavItem>

                        <NavItem>
                            <NavLink className={cx({ active: activeTab === '2' })} onClick={() => toggleTab('2')}>
                                Share Holders
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={cx({ active: activeTab === '1' })} onClick={() => toggleTab('1')}>
                                Board of Directors
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={cx({ active: activeTab === '4' })} onClick={() => toggleTab('4')}>
                                Profit & Loss
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={cx({ active: activeTab === '5' })} onClick={() => toggleTab('5')}>
                                Balance Sheet
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={cx({ active: activeTab === '6' })} onClick={() => toggleTab('6')}>
                                Key Ratio
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={cx({ active: activeTab === '7' })} onClick={() => toggleTab('7')}>
                                Cash Flow
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={cx({ active: activeTab === '8' })} onClick={() => toggleTab('8')}>
                                Annual Report
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent className="px-2 py-3 finance-data-tab-content" activeTab={activeTab} style={{maxHeight: 'calc( 100vh - 220px )', overflowY: 'auto', overflowX: 'hidden'}}>
                        <TabPane tabId="1">
                            <Finance.BoradOfDirectorsContent {...propsData}/>
                        </TabPane>
                        <TabPane tabId="2">
                            <Finance.ShareHoldersContent {...propsData} />
                        </TabPane>
                        <TabPane tabId="3">
                            <Finance.ExecutiveSummaryContent {...propsData} deleteFinanceSummary={deleteFinanceSummary} />
                        </TabPane>
                        <TabPane tabId="4">
                            <Finance.ProfitAndLossContent {...propsData} />
                        </TabPane>
                        <TabPane tabId="5">
                            <Finance.BalanceSheetContent {...propsData} />
                        </TabPane>
                        <TabPane tabId="6">
                            <Finance.KeyRatioTabContent {...propsData} />
                        </TabPane>
                        <TabPane tabId="7">
                            <Finance.CashFlowTabContent {...propsData} />
                        </TabPane>
                        <TabPane tabId="8">
                            <Finance.AnnualReportTabContent {...propsData} {...props} />
                        </TabPane>
                        <div><i style={{fontSize: '12px'}}>Standalone Figures</i></div>
                    </TabContent>
                </Col>
            </Row>
        );
    }

    render() {
        const { activeTab } = this.state;
        const { projectData, isViewMode, is_available_finance_data, showWithInCard, showInPopup } = this.props;

        return (
            <Fragment>
                <Container fluid>
                    {!_.isNil(projectData) && _.size(projectData) ? (<Finance.ProjectDetailsData projectData={projectData} />) : null}
                    {!_.isNil(this.isViewCompanyDetails) && this.isViewCompanyDetails && _.size(this.props.company_details) ? <Finance.CompanyDetailsData {...this.props} /> : null}

                    {(!_.isNil(showWithInCard) && _.isBoolean(showWithInCard) && showWithInCard) ? (
                        <Row>
                            <Col className="p-0">
                                <Card className={cx("card-box pb-3 rounded-0 border-0 finance-card-container", {"show-in-popup": !_.isNil(showInPopup) ? showInPopup === true : false})}>
                                    <CardBody>
                                        <Finance.FinanceTabNavContent
                                            toggleTab={this.toggleTab}
                                            activeTab={activeTab}
                                            propsData={this.props}
                                            deleteFinanceSummary={this.deleteFinanceSummary}
                                            uploadAnnualReportFile={this.uploadAnnualReportFile}
                                            resetUploadAnnualReportForm={this.resetUploadAnnualReportForm}
                                            onChangeAnnualReportFileHandle={this.onChangeAnnualReportFileHandle}
                                            deleteFinanceAnnualReportFile={this.deleteFinanceAnnualReportFile}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    ) :
                        <Finance.FinanceTabNavContent
                            toggleTab={this.toggleTab}
                            activeTab={activeTab}
                            propsData={this.props}
                            deleteFinanceSummary={this.deleteFinanceSummary}
                            uploadAnnualReportFile={this.uploadAnnualReportFile}
                            resetUploadAnnualReportForm={this.resetUploadAnnualReportForm}
                            onChangeAnnualReportFileHandle={this.onChangeAnnualReportFileHandle}
                            deleteFinanceAnnualReportFile={this.deleteFinanceAnnualReportFile}
                        />
                    }

                    <AjaxLoader isOpen={this.props.excel_is_downloading} imgSrc='/assets/images/xls-download-blue.gif' altText={'download excel gif image'} toggle={() => { }} />

                    <AjaxLoader isOpen={this.props.isDeleteFinanceMonYrLoading} altText={'download excel gif image'} toggle={() => { }} pageLoader={true} loaderText="Deleting..." />

                </Container>

                <AjaxLoader isOpen={this.props.isUploading} pageLoader={true} loaderText="Uploading..." altText={'upload excel gif image'} toggle={() => {}}/>

            </Fragment>
        );
    }
}
const mapStateToProps = (state, prevState) => {
    return {
        userRights: _.has(state.Authentication, 'user.action') ? state.Authentication.user.action : {},
        loading: state.Company.finance.loading || false,
        error: state.Company.finance.error || false,
        msg: state.Company.finance.msg || '',
        period: state.Company.finance.period || {},
        is_available_finance_data: state.Company.finance.is_available_finance_data || false,
        financial_summary: state.Company.finance.financial_summary || {},
        source_funds: state.Company.finance.source_funds || {},
        cash_flow: state.Company.finance.cash_flow || {},
        key_ratio: state.Company.finance.key_ratio || {},
        key_share_holder: state.Company.finance.key_share_holder || {},
        board_directors: state.Company.finance.board_directors || {},
        capex_details: state.Company.finance.capex_details || {},
        application_funds: state.Company.finance.application_funds || {},
        annual_report: state.Company.finance.annual_report || {},
        company_details:  _.has(state.Company.finance, 'company_details') ? state.Company.finance.company_details : {},
        excel_is_downloading: state.Company.finance.excel_is_downloading || false,
        isDeleteFinanceMonYrLoading: state.Company.finance.isDeleteFinanceMonYrLoading || false,

        isUploading: state.Company.annualReportUpload.loading || false,
        isAnnualReportDeleting: state.Company.annualReportUpload.is_deleting || false
    };
};
const mapDispatchToProps = (dispatch) => ({
    getFinanceDataByCompany: (id) => dispatch(fnGetFinanceDataBasedOnCompanyId(id)),
    downloadExcelFilesData: (params, filename) => dispatch(fnDownloadExcelFileData(params, filename)),
    deleteFinanceSummary: (data) => dispatch(fnActionCompany.deleteFinanceSummary(data)),
    uploadFinanceAnnualReportFile: (data) => dispatch(fnActionCompany.uploadFinanceAnnualReportFile(data)),
    deleteFinanceAnnualReportFile: (data) => dispatch(fnActionCompany.deleteFinanceAnnualReportFile(data))
});
export default connect(mapStateToProps, mapDispatchToProps)( Finance );