import React, {Fragment, useState, Component} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Row, Table, Col, CardBody, Card, CardTitle, CardHeader, Collapse, UncontrolledPopover, Container, InputGroup, InputGroupAddon, InputGroupText, FormText, Form, CustomInput, Label, FormGroup, Input, Badge, ListGroup, ListGroupItem, PopoverHeader, PopoverBody, UncontrolledTooltip, TabContent, TabPane, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledDropdown, Progress, DropdownToggle, DropdownMenu, Alert, DropdownItem } from 'reactstrap'

import {connect} from 'react-redux';

import {
    setHeaderSearchHover
} from '../../reducers/ThemeOptions';




class HeaderSearch extends Component {

    toggleHeaderSearchHover = () => {
        let {headerSearchHover, setHeaderSearchHover} = this.props;
        setHeaderSearchHover(!headerSearchHover);
    }

    render() {

        let {
            headerSearchHover
        } = this.props;

        return (
            <Fragment>
                <div className="header-search-wrapper">
                    <div className={cx("search-wrapper", {'is-active': headerSearchHover})}>
                        <label className="icon-wrapper text-black" htmlFor="header-search-input">
                            <FontAwesomeIcon icon={['fas', 'search']}/>
                        </label>
                        <input onFocus={this.toggleHeaderSearchHover} onBlur={this.toggleHeaderSearchHover} className="form-control" id="header-search-input" name="header-search-input" placeholder="Search terms..." type="search"/>
                    </div>
                </div>

            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    headerSearchHover: state.ThemeOptions.headerSearchHover
});

const mapDispatchToProps = dispatch => ({

    setHeaderSearchHover: enable => dispatch(setHeaderSearchHover(enable)),

});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderSearch);
