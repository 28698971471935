import React, { Component, Fragment, useState, useMemo, useEffect } from 'react';
import { connect } from "react-redux";
import { Button, UncontrolledTooltip, Row, Col, Card, CardBody, Label, InputGroup, Input, InputGroupAddon, Tooltip, Spinner } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Pagination } from 'components';
import { fnWaterSanitationActions } from "actions";
import { PROJECT_STATUS_UNDER_BIDDING, PROJECT_MODE, SET_WS_PROJECT_DATA_FROM_PLIST_FOR_LSTORE } from "../../constants";
import _, { slice } from 'lodash';
import moment from "moment";
import cx from 'classnames';
import { Finance, ProjectViewLoader, AjaxLoader } from "components";
import { useTable, usePagination, useGlobalFilter } from 'react-table';
import Select, { Option } from 'rc-select';
import AvailableProject from "../../components/Company/Projectform";
import { SessionData, toEncryption, Request, toDecryption, SweetAlert } from 'utils';
import MapView from './MapView';

class WsProjectsView extends Component {

    state = {
        isOpenFinance: false,
        m_company_id: 0,
        isSlider: false,
        available: [],
        company: {},
        getData:[],
        historyShow:false,
        loadButton:false,
        dataLoading:false,
    }

    constructor(props) {
        super(props);
        this.projectId = _.has(this.props, 'projectId') ? this.props.projectId : 0;
        this.history=React.createRef()
    }

    componentDidMount() {
        // this.props.getProjectDetails(this.props.projectId);
        this.props.loadAddProjectInitData()
        this.props.getProjectDataById(this.props.projectId).then((res) => {
                this.setState({dataLoading:true})
            SessionData.set(SET_WS_PROJECT_DATA_FROM_PLIST_FOR_LSTORE, toEncryption(res.meta.data, true));
        })

        // if (this.props.rightsID === 1) {

        //     this.props.getWsProjectDetailsLog(this.props.projectId);
        //     }

            Request.post("getWSDoc",{id_ws_project: this.projectId},3).then((res)=>{
                if(res.meta['data']){
                  this.setState({getData:res.meta.data})
                }else{
                  this.setState({getData:[]})
                }
                })


    }

    openHistory=()=>{
        this.setState({loadButton:true})
        if (this.projectId > 0) {
            this.props.getWsProjectDetailsLog(this.props.projectId).then((res) => {
                this.setState({historyShow:!this.state.historyShow,loadButton:false})
                setTimeout(()=>{
                    if(  this.history.current){
                        this.history.current.scrollIntoView({behavior:'smooth', block: "center",})
                    }
                },400)
            });
        }
    }

    viewFinanceData = (m_company_id) => {
        this.setState({
            isOpenFinance: true,
            m_company_id: m_company_id
        });
    }

    closeFinanceData = (e) => {
        e.preventDefault();
        this.setState({
            isOpenFinance: false,
            m_company_id: 0
        });
    }

    sliderOpenDev = (values) => {
        this.setState({ isSlider: true })
        let data = {}
        this.setState({ available: [] })
        if ('m_company_type_id' in values && +values.m_company_type_id === 2) {
            this.setState({ company: { company_name: values.parent_company_name } })
            data = {
                m_company_id: values.m_company_id
            }
        } else {
            this.setState({ company: { company_name: values.parent_company_name } })
            data = {
                m_company_id_parent: values.m_company_id
            }
        }
        Request.post('getQrySearchResults', data, 2).then((res) => {
            this.setState({ available: res.meta.data.rows ?? [] })
            if (this.state.available.length > 0) {
                this.setState({ isSlider: false, sliderShow: true })
            }
        })

    }

    sliderOpenSPV = (values) => {
        let data = {}
        this.setState({ available: [] })
        if ('m_company_type_id' in values && +values.m_company_type_id === 2) {
            this.setState({ company: { company_name: values.company_name } })
            data = {
                m_company_id: values.m_company_id
            }
        } else {
            this.setState({ company: { company_name: values.company_name } })
            data = {
                m_company_id_parent: values.m_company_id
            }
        }
        Request.post('getQrySearchResults', data, 2).then((res) => {
            this.setState({ available: res.meta.data.rows ?? [] })
            if (this.state.available.length > 0) {
                this.setState({ sliderShow: true })
            }
        })

    }

    groupSlider = (values) => {
        this.setState({ isSlider: true })
        values.company_name = values.company_group_name
        this.setState({ available: [], company: values })
        let data = {
            m_company_group_id: values.m_company_group_id
        }
        Request.post('getQrySearchResults', data, 2).then((res) => {
            this.setState({ available: res.meta.data.rows ?? [] })
            if (this.state.available.length > 0) {
                this.setState({ isSlider: false, sliderShow: true })
            }
        })
    }

    closeSlider = () => {
        this.setState({
            sliderShow: false
        });
    }

    static CardLabelContents(props,) {
        const { label, labelTitle, children, style, title, classname } = props;

        return (<><small className={classname} data-title={title} style={style} >{label}</small><div className="text-dark">{children}</div></>);
    }

    static ProjectInfoCard(props) {

        const { projectData, isDataLoading, highLightValues, heightData, initData } = props

        let authority_name = initData?.wsAuthority?.filter((e)=> e.value === Number(projectData?.m_ws_authority_id))
        projectData.authority_name = authority_name?.length > 0 ? authority_name[0]?.label : "-"

        let plant_type_name = initData?.plantType?.filter((e)=> e.m_ws_plant_type_id === Number(projectData?.m_ws_plant_type_id))
        projectData.plant_type = plant_type_name?.length > 0 ? plant_type_name[0]?.m_ws_plant_type_name : "-"

        return (
            <Card className="card-list-box project-view-details-card project-list-card">
                <CardBody>
                    <div className="text-left mb-2" style={{ backgroundColor: '#f7f7f7' }}>
                        <span data-title={highLightValues.includes("ws_project_name") ? heightData.filter((e) => e.id === "ws_project_name").map((e) => {
                            return `Previous Value : \n${e.old_value}`
                        }) : "Project Name"} className={`mb-0 text-black py-1 px-1 project-name ${highLightValues.includes("ws_project_name") && "data-title"}`} style={{ fontSize: '0.88rem'}}>{projectData.ws_project_name}</span>
                    </div>
                    <Row>
                        <Col md={3}>
                            <Row className="project-view-details-card">
                                <Col md={12}>
                                    <WsProjectsView.CardLabelContents classname={`font-weight-light ${highLightValues.includes("m_state_id") && "data-title"}`} label={'State'} title={highLightValues.includes("m_state_id") ? heightData.filter((e) => e.id === "m_state_id").map((e) => {
                                        return `Previous Value : \n${e.old_value}`
                                    }) : 'State'} >{_.size(projectData.state_name) ? _.join(projectData.state_name, ', ') : '-'}</WsProjectsView.CardLabelContents>
                                </Col>
                                <Col md={12}>
                                    <WsProjectsView.CardLabelContents classname={`font-weight-light ${highLightValues.includes("m_district_id") && "data-title"}`} label={'District'} title={highLightValues.includes("m_district_id") ? heightData.filter((e) => e.id === "m_district_id").map((e) => {
                                        return `Previous Value : \n${e.old_value}`
                                    }) : 'district'} >{_.has(projectData,'district_name') ? projectData.district_name?? ' -': '-'}</WsProjectsView.CardLabelContents>
                                </Col>
                                <Col md={12}>
                                    <WsProjectsView.CardLabelContents classname={`font-weight-light ${highLightValues.includes("ws_lat") && "data-title"}`} label={'Latitude'} title={highLightValues.includes("ws_lat") ? heightData.filter((e) => e.id === "ws_lat").map((e) => {
                                        return `Previous Value : \n${e.old_value}`
                                    }) : 'district'} >{_.size(projectData.ws_lat) ? projectData.ws_lat : '-'}</WsProjectsView.CardLabelContents>
                                </Col>
                                <Col md={12}>
                                    <WsProjectsView.CardLabelContents classname={`font-weight-light ${highLightValues.includes("ws_long") && "data-title"}`} label={'Longitude'} title={highLightValues.includes("ws_long") ? heightData.filter((e) => e.id === "ws_long").map((e) => {
                                        return `Previous Value : \n${e.old_value}`
                                    }) : 'district'} >{_.size(projectData.ws_long) ? projectData.ws_long : '-'}</WsProjectsView.CardLabelContents>
                                </Col>
                            </Row>
                            </Col>
                            <Col md={9}>
                            <Row className="project-view-details-card">

                                <Col md={3}>
                                    <small className={`font-weight-light ${highLightValues.includes("ws_sector_name") && "data-title"}`} data-title={highLightValues.includes("ws_sector_name") ? heightData.filter((e) => e.id === "ws_sector_name").map((e) => {
                                        return `Previous Value : \n${e.old_value}`
                                    }) : 'NH/SH'} >Sector</small><div className="text-dark">{projectData.ws_sector_name || '-'}</div>
                                </Col>
                                <Col md={3}>
                                    <small className={`font-weight-light ${highLightValues.includes("ws_sub_sector_name") && "data-title"}`} data-title={highLightValues.includes("ws_sub_sector_name") ? heightData.filter((e) => e.id === "ws_sub_sector_name").map((e) => {
                                        return `Previous Value : \n${e.old_value}`
                                    }) : 'NH/SH Number'} >Sub Sector</small><div className="text-dark">{projectData.ws_sub_sector_name || '-'}</div>
                                </Col>
                                <Col md={3}>
                                    <small className={`font-weight-light ${highLightValues.includes("bid_parameter_name") && "data-title"}`} data-title={highLightValues.includes("bid_parameter_name") ? heightData.filter((e) => e.id === "bid_parameter_name").map((e) => {
                                        return `Previous Value : \n${e.old_value}`
                                    }) : 'Stretch (Km)'} >BID Params</small><div className="text-dark">{projectData.bid_parameter_name || '-'}</div>
                                </Col>
                                <Col md={3}>
                                    <small className={`font-weight-light ${highLightValues.includes("m_ws_plant_type_id") && "data-title"}`} data-title={highLightValues.includes("m_ws_plant_type_id") ? heightData.filter((e) => e.id === "m_ws_plant_type_id").map((e) => {
                                        return `Previous Value : \n${e.old_value}`
                                    }) : 'Stretch (Km)'} >Plant Type</small><div className="text-dark">{projectData.plant_type || '-'}</div>
                                </Col>
                                <Col md={3}>
                                    <small className={`font-weight-light ${highLightValues.includes("authority") && "data-title"}`} data-title={highLightValues.includes("authority") ? heightData.filter((e) => e.id === "authority").map((e) => {
                                        return `Previous Value : \n${e.old_value}`
                                    }) : 'Authority'} >Authority</small><div className="text-dark">{projectData.authority_name || '-'}</div>
                                </Col>
                                <Col md={3}>
                                    <small className={`font-weight-light ${highLightValues.includes("scheme") && "data-title"}`} data-title={highLightValues.includes("scheme") ? heightData.filter((e) => e.id === "scheme").map((e) => {
                                        return `Previous Value : \n${e.old_value}`
                                    }) : 'Scheme'} >Nodal Authority</small><div className="text-dark">{projectData.nodal_authority_name || '-'}</div>
                                </Col>
                                <Col md={3}>
                                    <small className={`font-weight-light ${highLightValues.includes("number_lane") && "data-title"}`} data-title={highLightValues.includes("number_lane") ? heightData.filter((e) => e.id === "number_lane").map((e) => {
                                        return `Previous Value : \n${e.old_value}`
                                    }) : 'Number Of Lane'} >Capcity</small><div className="text-dark">{projectData.project_capacity || '-'}</div>
                                </Col>
                                <Col md={3}>
                                    <small className={`font-weight-light ${highLightValues.includes("nhdp_phase") && "data-title"}`} data-title={highLightValues.includes("nhdp_phase") ? heightData.filter((e) => e.id === "nhdp_phase").map((e) => {
                                        return `Previous Value : \n${e.old_value}`
                                    }) : 'Phase'} >Capacity Units</small><div className="text-dark">{projectData.ws_capacity_units_name || '-'}</div>
                                </Col>
                                <Col md={3}>
                                    <small className={`font-weight-light ${highLightValues.includes("m_mode_id") && "data-title"}`} data-title={highLightValues.includes("m_mode_id") ? heightData.filter((e) => e.id === "m_mode_id").map((e) => {
                                        return `Previous Value : \n${e.old_value}`
                                    }) : 'Mode'} >Mode</small><div className="text-dark">
                                        {projectData.mode_name}{(PROJECT_MODE.PPP === +projectData.m_mode_id && !_.isEmpty(projectData.mode_ppp_name)) ? " - " + projectData.mode_ppp_name : null}
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <small className={`font-weight-light ${highLightValues.includes("sanctioning_agency") && "data-title"}`} data-title={highLightValues.includes("sanctioning_agency") ? heightData.filter((e) => e.id === "sanctioning_agency").map((e) => {
                                        return `Previous Value : \n${e.old_value}`
                                    }) : 'Status'} >Sanctioning Agency</small><div className="text-dark">{projectData.sanctioning_agency || '-'}</div>
                                </Col>
                                <Col md={3}>
                                    <small className={`font-weight-light ${highLightValues.includes("scheme_name") && "data-title"}`} data-title={highLightValues.includes("scheme_name") ? heightData.filter((e) => e.id === "scheme_name").map((e) => {
                                        return `Previous Value : \n${e.old_value}`
                                    }) : 'Status'} >Scheme</small><div className="text-dark">{projectData.scheme_name || '-'}</div>
                                </Col>
                                <Col md={3}>
                                    <small className={`font-weight-light ${highLightValues.includes("source") && "data-title"}`} data-title={highLightValues.includes("source") ? heightData.filter((e) => e.id === "source").map((e) => {
                                        return `Previous Value : \n${e.old_value}`
                                    }) : 'Status'} >Source</small><div className="text-dark">{projectData.source || '-'}</div>
                                </Col>
                                <Col md={3}>
                                    <small className={`font-weight-light ${highLightValues.includes("location") && "data-title"}`} data-title={highLightValues.includes("location") ? heightData.filter((e) => e.id === "location").map((e) => {
                                        return `Previous Value : \n${e.old_value}`
                                    }) : 'Status'} >Location</small><div className="text-dark">{projectData.location || '-'}</div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        );
    }

    static CompanyInfoCard(props) {

        const { access, projectData, viewFinanceData, m_company_id, closeFinanceData, showInPopup, projectData: { company_detail = {} }, heightData, highLightValues, sliderOpenDev, sliderOpenSPV, groupSlider } = props;

        let showInpopup = false;
        if (!_.isNil(showInPopup) && showInPopup == true) {
            if (window.outerWidth >= 1200 && window.outerWidth <= 1440) {
                showInpopup = true;
            }
        }

        return (
            <Card className="card-box mb-3 rounded-0 border-0 card-list-box project-view-details-card">
                <CardBody>
                    <div className="text-left mb-2" style={{ backgroundColor: '#f7f7f7' }}>
                        <div className="mb-0 text-black py-1 px-1 project-name" style={{ fontSize: '0.83rem' }}>Company Info</div>
                    </div>
                    <Row>
                        <Col md={12}>
                            <small className={`font-weight-light ${highLightValues.includes("m_company_type_id") && "data-title"}`} data-title={highLightValues.includes("m_company_type_id") ? heightData.filter((e) => e.id === "m_company_type_id").map((e) => {
                                return `Previous Value : \n${e.old_value}`
                            }) : 'Type Of ompany'}  >Type Of Company</small>
                            <div className="text-dark" >{_.size(company_detail) && _.get(company_detail, 'm_company_type_id') > 0 ? (_.get(company_detail, 'm_company_type_id') == 1 ? 'Developer' : 'SPV') : '-'}</div>
                        </Col>
                    </Row>
                    {/***PROJECT_MODE.PPP === +projectData.m_mode_id ? (***/}
                    {_.size(company_detail) && _.get(company_detail, 'm_company_type_id') > 0 ? (
                        <>
                            {_.get(company_detail, 'm_company_type_id') == 2 ? (
                                <Row>
                                    <Col md={5}>
                                        <Row>
                                            <Col md={_.has(projectData, 'company_detail.is_finance_available') && +projectData.company_detail.is_finance_available ? 10 : 12}>
                                                <small className={`font-weight-light ${highLightValues.includes("") && "data-title"}`} title={'SPV Name'}>SPV Name</small>
                                                <div className="text-dark">
                                                    {_.has(projectData, 'company_detail.company_name') ? projectData.company_detail.company_name : '-'}
                                                </div>
                                            </Col>
                                            {
                                                _.has(projectData, 'company_detail.is_finance_available') && +projectData.company_detail.is_finance_available ?
                                                    (<Col md={3} className="pt-1">

                                                        {props.isOpenFinance ? (
                                                            <Fragment>
                                                                <Button onClick={e => { e.preventDefault(); closeFinanceData(e); }} type="button" size="sm" outline color="danger" id="view_finance" style={{ padding: '0.15rem 0.5rem' }}>  <FontAwesomeIcon icon={['fas', 'times']} /> </Button>
                                                                <UncontrolledTooltip popperClassName={'tooltip-danger'} placement="top" target="view_finance">
                                                                    Close Finance
                                                                </UncontrolledTooltip>
                                                            </Fragment>
                                                        ) : (
                                                            <Fragment>
                                                                <Button onClick={e => { e.preventDefault(); viewFinanceData(projectData.company_detail.m_company_id); }} type="button" size="sm" outline color="info" id="view_finance" style={{ padding: '0.15rem 0.5rem' }}>  <FontAwesomeIcon icon={['fas', 'rupee-sign']} /> </Button>
                                                                <UncontrolledTooltip popperClassName={'tooltip-info'} placement="top" target="view_finance">
                                                                    View Finance
                                                                </UncontrolledTooltip>
                                                            </Fragment>
                                                        )}
                                                    </Col>) : null
                                            }
                                        </Row>
                                    </Col>
                                    <Col md={3}>
                                        <small className="font-weight-light">CIN #</small><div className="text-dark">{_.has(projectData, 'company_detail.cin_no') ? projectData.company_detail.cin_no : '-'}</div>
                                    </Col>
                                    <Col md={3}>
                                        <small className={`font-weight-light ${highLightValues.includes("company_m_company_id") && "data-title"}`} data-title={highLightValues.includes("company_m_company_id") ? heightData.filter((e) => e.id === "company_m_company_id").map((e) => {
                                            return `Previous Value : \n${e.old_value}`
                                        }) : 'Type Of ompany'} >Developer Name</small>
                                        {/* {access.ALLOW_SHOW_OTHER_PROJECT ?
                                            <div style={{ cursor: 'pointer', color: "#3062d5", fontSize: "13px", fontWeight: 600 }} onClick={() => sliderOpenDev(projectData.company_detail)} >
                                                {_.has(projectData, 'company_detail.parent_company_name') ? projectData.company_detail.parent_company_name : '-'}</div> : */}
                                            <div className='text-dark'>
                                                {_.has(projectData, 'company_detail.parent_company_name') ? projectData.company_detail.parent_company_name : '-'}</div>
                                                {/* } */}
                                    </Col>
                                    <Col md={3}>
                                        <small className="font-weight-light" >Group</small>
                                        {/* {access.ALLOW_SHOW_OTHER_PROJECT ?
                                            <div style={{ cursor: 'pointer', color: "#3062d5", fontSize: "13px", fontWeight: 600 }} role='button' onClick={() => groupSlider(projectData.company_detail)}>
                                                {_.has(projectData, 'company_detail.company_group_name') ? projectData.company_detail.company_group_name : '-'}</div> : */}
                                            <div className='text-dark'>
                                                {_.has(projectData, 'company_detail.company_group_name') ? projectData.company_detail.company_group_name : '-'}</div>
                                                {/* } */}
                                    </Col>
                                </Row>
                            ) : (
                                <Row>
                                    <Col md={8}>
                                        <small className={`font-weight-light ${highLightValues.includes("company_m_company_id") && "data-title"}`} data-title={highLightValues.includes("company_m_company_id") ? heightData.filter((e) => e.id === "company_m_company_id").map((e) => {
                                            return `Previous Value : \n${e.old_value}`
                                        }) : 'Developer'} >Developer Name</small><div >

                                            <Row>
                                                {/* {access.ALLOW_SHOW_OTHER_PROJECT ?
                                                    <Col md={_.has(projectData, 'company_detail.is_finance_available') && +projectData.company_detail.is_finance_available ? 10 : 12} style={{ cursor: 'pointer', color: "#3062d5", fontSize: "13px", fontWeight: 600 }} role='button' onClick={() => sliderOpenDev(projectData.company_detail)}>
                                                        {_.has(projectData, 'company_detail.company_name') ?
                                                            projectData.company_detail.company_name :
                                                            '-'}
                                                    </Col> : */}
                                                    <Col md={_.has(projectData, 'company_detail.is_finance_available') && +projectData.company_detail.is_finance_available ? 10 : 12} className='text-dark' >
                                                        {_.has(projectData, 'company_detail.company_name') ?
                                                            projectData.company_detail.company_name :
                                                            '-'}
                                                    </Col>
                                                    {/* } */}
                                                {
                                                    _.has(projectData, 'company_detail.is_finance_available') && +projectData.company_detail.is_finance_available ?
                                                        (<Col md={3} className="pl-0">

                                                            {props.isOpenFinance ? (
                                                                <Fragment>
                                                                    <Button onClick={e => { e.preventDefault(); closeFinanceData(e); }} type="button" size="sm" outline color="danger" id="view_finance" style={{ padding: '0.15rem 0.5rem' }}>  <FontAwesomeIcon icon={['fas', 'times']} /> </Button>
                                                                    <UncontrolledTooltip popperClassName={'tooltip-danger'} placement="top" target="view_finance">
                                                                        Close Finance
                                                                    </UncontrolledTooltip>
                                                                </Fragment>
                                                            ) : (
                                                                <Fragment>
                                                                    <Button onClick={e => { e.preventDefault(); viewFinanceData(projectData.company_detail.m_company_id); }} type="button" size="sm" outline color="info" id="view_finance" style={{ padding: '0.15rem 0.5rem' }}>  <FontAwesomeIcon icon={['fas', 'rupee-sign']} /> </Button>
                                                                    <UncontrolledTooltip popperClassName={'tooltip-info'} placement="top" target="view_finance">
                                                                        View Finance
                                                                    </UncontrolledTooltip>
                                                                </Fragment>
                                                            )}
                                                        </Col>) : null
                                                }
                                            </Row>
                                        </div>
                                    </Col>
                                    <Col md={2}>
                                        <small className='font-weight-light'>CIN #</small><div className="text-dark">{_.has(projectData, 'company_detail.cin_no') ? projectData.company_detail.cin_no : '-'}</div>
                                    </Col>
                                    <Col md={2}>
                                        <small className='font-weight-light' >Group</small><div className="text-dark">{_.has(projectData, 'company_detail.company_group_name') ? projectData.company_detail.company_group_name : '-'}</div>
                                    </Col>
                                </Row>
                            )}
                        </>
                    ) : null}
                    {props.isOpenFinance ? (
                        <Fragment>
                            <div className="divider mt-2 mb-3"></div>
                            <div className={cx("finance-card-container", { "show-in-popup": showInpopup })}>
                                <Finance companyId={m_company_id} isViewCompanyDetails={false} isViewMode={true} />
                            </div>
                        </Fragment>
                    ) : null}

                </CardBody>
            </Card>
        );
                    }
    static BiddingInfoCard(props) {
        const { projectData, highLightValues, heightData } = props;
        return (
            <Card className="card-box card-list-box mb-3 rounded-0 border-0 project-view-details-card">
                <CardBody>
                    <div className="text-left mb-2" style={{ backgroundColor: '#f7f7f7' }}>
                        <div className="mb-0 text-black py-1 px-1 project-name" style={{ fontSize: '0.83rem' }}>Events/Bidding Info</div>
                    </div>
                    <Row>
                        <Col sm={3} md={3}>

                            <small className={`font-weight-light ${highLightValues.includes("date_rfp") && "data-title"}`} data-title={highLightValues.includes("date_rfp") ? heightData.filter((e) => e.id === "date_rfp").map((e) => {
                                return `Previous Value : \n${moment(Number(e.old_value)).format('MMM-YYYY') !== "Invalid date" ?
                                    moment(Number(e.old_value)).format('MMM-YYYY') : moment(e.old_value).format('MMM-YYYY')}`
                            }) : 'RFP'} >RFP</small><div className="text-dark">{projectData.date_rfp ? moment.unix(projectData.date_rfp).format('MMM-YYYY') : '-'}</div>

                        </Col>
                        <Col sm={3} md={3}>
                            <small className={`font-weight-light ${highLightValues.includes("date_rfq") && "data-title"}`} data-title={highLightValues.includes("date_rfq") ? heightData.filter((e) => e.id === "date_rfq").map((e) => {
                                return `Previous Value : \n${moment(Number(e.old_value)).format('MMM-YYYY') !== "Invalid date" ?
                                    moment(Number(e.old_value)).format('MMM-YYYY') : moment(e.old_value).format('MMM-YYYY')}`
                            }) : 'RFQ'} >RFQ</small><div className="text-dark">{projectData.date_rfq ? projectData.date_rfq : '-'}</div>
                        </Col>
                        <Col sm={3} md={3}>
                            <small className={`font-weight-light ${highLightValues.includes("appointed_date") && "data-title"}`} data-title={highLightValues.includes("appointed_date") ? heightData.filter((e) => e.id === "appointed_date").map((e) => {
                                return `Previous Value : \n${moment(Number(e.old_value)).format('MMM-YYYY') !== "Invalid date" ?
                                    moment(Number(e.old_value)).format('MMM-YYYY') : moment(e.old_value).format('MMM-YYYY')}`
                            }) : 'Letter Of Award'} >Letter Of Award</small><div className="text-dark">{projectData.appointed_date ? moment.unix(projectData.appointed_date).format('MMM-YYYY') : '-'}</div>
                        </Col>
                        <Col sm={3} md={3}>
                            <small className={`font-weight-light ${highLightValues.includes("agreement_signing_date") && "data-title"}`} data-title={highLightValues.includes("agreement_signing_date") ? heightData.filter((e) => e.id === "agreement_signing_date").map((e) => {
                                return `Previous Value : \n${moment(Number(e.old_value)).format('MMM-YYYY') !== "Invalid date" ?
                                    moment(Number(e.old_value)).format('MMM-YYYY') : moment(e.old_value).format('MMM-YYYY')}`
                            }) : 'Concession Agreement Signed'}>Agreement Signed</small><div className="text-dark">{projectData.agreement_signing_date ? moment.unix(projectData.agreement_signing_date).format('MMM-YYYY') : '-'}</div>
                        </Col>

                        <Col sm={3} md={3}>
                            <small className={`font-weight-light ${highLightValues.includes("sch_const_comm_date") && "data-title"}`} data-title={highLightValues.includes("sch_const_comm_date") ? heightData.filter((e) => e.id === "sch_const_comm_date").map((e) => {
                                return `Previous Value : \n${moment(Number(e.old_value)).format('MMM-YYYY') !== "Invalid date" ?
                                    moment(Number(e.old_value)).format('MMM-YYYY') : moment(e.old_value).format('MMM-YYYY')}`
                            }) : 'Construction Commencement'}>Scheduled Const. Commencement</small><div className="text-dark">{projectData.sch_const_comm_date ? moment.unix(projectData.sch_const_comm_date).format('MMM-YYYY') : '-'}</div>
                        </Col>

                        <Col sm={3} md={3}>
                            <small className={`font-weight-light ${highLightValues.includes("rev_const_comm_date") && "data-title"}`} data-title={highLightValues.includes("rev_const_comm_date") ? heightData.filter((e) => e.id === "rev_const_comm_date").map((e) => {
                                return `Previous Value : \n${moment(Number(e.old_value)).format('MMM-YYYY') !== "Invalid date" ?
                                    moment(Number(e.old_value)).format('MMM-YYYY') : moment(e.old_value).format('MMM-YYYY')}`
                            }) : 'Actual Completion'} >Revised Const. Commencement</small><div className="text-dark">{projectData.rev_const_comm_date ? moment.unix(projectData.rev_const_comm_date).format('MMM-YYYY') : '-'}</div>
                        </Col>

                        <Col sm={3} md={3}>
                            <small className={`font-weight-light ${highLightValues.includes("ant_const_comm_date") && "data-title"}`} data-title={highLightValues.includes("ant_const_comm_date") ? heightData.filter((e) => e.id === "ant_const_comm_date").map((e) => {
                                return `Previous Value : \n${moment(Number(e.old_value)).format('MMM-YYYY') !== "Invalid date" ?
                                    moment(Number(e.old_value)).format('MMM-YYYY') : moment(e.old_value).format('MMM-YYYY')}`
                            }) : 'Scheduled Project Completion'} >Anti Const. Commencement</small><div className="text-dark">{projectData.ant_const_comm_date ? moment.unix(projectData.ant_const_comm_date).format('MMM-YYYY') : '-'}</div>
                        </Col>
                        <Col sm={3} md={3}>
                            <small className={`font-weight-light ${highLightValues.includes("act_const_comm_date") && "data-title"}`} data-title={highLightValues.includes("act_const_comm_date") ? heightData.filter((e) => e.id === "act_const_comm_date").map((e) => {
                                return `Previous Value : \n${moment(Number(e.old_value)).format('MMM-YYYY') !== "Invalid date" ?
                                    moment(Number(e.old_value)).format('MMM-YYYY') : moment(e.old_value).format('MMM-YYYY')}`
                            }) : 'Anticipated Completion'} >Actual Const. Commencement</small><div className="text-dark">{projectData.act_const_comm_date ? moment.unix(projectData.act_const_comm_date).format('MMM-YYYY') : '-'}</div>
                        </Col>
                        <Col sm={3} md={3}>
                            <small className={`font-weight-light ${highLightValues.includes("scheduled_completion_date") && "data-title"}`} data-title={highLightValues.includes("scheduled_completion_date") ? heightData.filter((e) => e.id === "scheduled_completion_date").map((e) => {
                                return `Previous Value : \n${moment(Number(e.old_value)).format('MMM-YYYY') !== "Invalid date" ?
                                    moment(Number(e.old_value)).format('MMM-YYYY') : moment(e.old_value).format('MMM-YYYY')}`
                            }) : 'Scheduled Project Completion'} >Scheduled Const. Completion</small><div className="text-dark">{projectData.scheduled_completion_date ? moment.unix(projectData.scheduled_completion_date).format('MMM-YYYY') : '-'}</div>
                        </Col>
                        <Col sm={3} md={3}>
                            <small className={`font-weight-light ${highLightValues.includes("rev_comp_date") && "data-title"}`} data-title={highLightValues.includes("rev_comp_date") ? heightData.filter((e) => e.id === "rev_comp_date").map((e) => {
                                return `Previous Value : \n${moment(Number(e.old_value)).format('MMM-YYYY') !== "Invalid date" ?
                                    moment(Number(e.old_value)).format('MMM-YYYY') : moment(e.old_value).format('MMM-YYYY')}`
                            }) : 'Anticipated Completion'} >Revised Const. Completion</small><div className="text-dark">{projectData.rev_comp_date ? moment.unix(projectData.rev_comp_date).format('MMM-YYYY') : '-'}</div>
                        </Col>
                        <Col sm={3} md={3}>
                            <small className={`font-weight-light ${highLightValues.includes("ant_comp_date") && "data-title"}`} data-title={highLightValues.includes("ant_comp_date") ? heightData.filter((e) => e.id === "ant_comp_date").map((e) => {
                                return `Previous Value : \n${moment(Number(e.old_value)).format('MMM-YYYY') !== "Invalid date" ?
                                    moment(Number(e.old_value)).format('MMM-YYYY') : moment(e.old_value).format('MMM-YYYY')}`
                            }) : 'Scheduled Project Completion'} >Anticipated Const. Completion</small><div className="text-dark">{projectData.ant_comp_date ? moment.unix(projectData.ant_comp_date).format('MMM-YYYY') : '-'}</div>
                        </Col>
                        <Col sm={3} md={3}>
                            <small className={`font-weight-light ${highLightValues.includes("actual_completion_date") && "data-title"}`} data-title={highLightValues.includes("actual_completion_date") ? heightData.filter((e) => e.id === "actual_completion_date").map((e) => {
                                return `Previous Value : \n${moment(Number(e.old_value)).format('MMM-YYYY') !== "Invalid date" ?
                                    moment(Number(e.old_value)).format('MMM-YYYY') : moment(e.old_value).format('MMM-YYYY')}`
                            }) : 'Anticipated Completion'} >Actual Const. Completion</small><div className="text-dark">{projectData.actual_completion_date ? moment.unix(projectData.actual_completion_date).format('MMM-YYYY') : '-'}</div>
                        </Col>
                        <Col sm={3} md={3}>
                            <small className={`font-weight-light ${highLightValues.includes("scheduled_commercial_operation_date") && "data-title"}`} data-title={highLightValues.includes("scheduled_commercial_operation_date") ? heightData.filter((e) => e.id === "scheduled_commercial_operation_date").map((e) => {
                                return `Previous Value : \n${moment(Number(e.old_value)).format('MMM-YYYY') !== "Invalid date" ?
                                    moment(Number(e.old_value)).format('MMM-YYYY') : moment(e.old_value).format('MMM-YYYY')}`
                            }) : 'Scheduled Project Completion'} >Scheduled Comm. Operation</small><div className="text-dark">{projectData.scheduled_commercial_operation_date ? moment.unix(projectData.scheduled_commercial_operation_date).format('MMM-YYYY') : '-'}</div>
                        </Col>
                        <Col sm={3} md={3}>
                            <small className={`font-weight-light ${highLightValues.includes("revised_commercial_operation_date") && "data-title"}`} data-title={highLightValues.includes("revised_commercial_operation_date") ? heightData.filter((e) => e.id === "revised_commercial_operation_date").map((e) => {
                                return `Previous Value : \n${moment(Number(e.old_value)).format('MMM-YYYY') !== "Invalid date" ?
                                    moment(Number(e.old_value)).format('MMM-YYYY') : moment(e.old_value).format('MMM-YYYY')}`
                            }) : 'Anticipated Completion'} >Revised Comm. Operation</small><div className="text-dark">{projectData.revised_commercial_operation_date ? moment.unix(projectData.revised_commercial_operation_date).format('MMM-YYYY') : '-'}</div>
                        </Col>
                        <Col sm={3} md={3}>
                            <small className={`font-weight-light ${highLightValues.includes("anticipated_commercial_operation_date") && "data-title"}`} data-title={highLightValues.includes("anticipated_commercial_operation_date") ? heightData.filter((e) => e.id === "anticipated_commercial_operation_date").map((e) => {
                                return `Previous Value : \n${moment(Number(e.old_value)).format('MMM-YYYY') !== "Invalid date" ?
                                    moment(Number(e.old_value)).format('MMM-YYYY') : moment(e.old_value).format('MMM-YYYY')}`
                            }) : 'Scheduled Project Completion'} >Anticipated Comm. Operation</small><div className="text-dark">{projectData.anticipated_commercial_operation_date ? moment.unix(projectData.anticipated_commercial_operation_date).format('MMM-YYYY') : '-'}</div>
                        </Col>
                        <Col sm={3} md={3}>
                            <small className={`font-weight-light ${highLightValues.includes("actual_commercial_operation_date") && "data-title"}`} data-title={highLightValues.includes("actual_commercial_operation_date") ? heightData.filter((e) => e.id === "actual_commercial_operation_date").map((e) => {
                                return `Previous Value : \n${moment(Number(e.old_value)).format('MMM-YYYY') !== "Invalid date" ?
                                    moment(Number(e.old_value)).format('MMM-YYYY') : moment(e.old_value).format('MMM-YYYY')}`
                            }) : 'Anticipated Completion'} >Actual Comm. Operation</small><div className="text-dark">{projectData.actual_commercial_operation_date ? moment.unix(projectData.actual_commercial_operation_date).format('MMM-YYYY') : '-'}</div>
                        </Col>
                        <Col sm={3} md={3}>
                            <small className={`font-weight-light ${highLightValues.includes("sch_financial_closure") && "data-title"}`} data-title={highLightValues.includes("sch_financial_closure") ? heightData.filter((e) => e.id === "sch_financial_closure").map((e) => {
                                return `Previous Value : \n${moment(Number(e.old_value)).format('MMM-YYYY') !== "Invalid date" ?
                                    moment(Number(e.old_value)).format('MMM-YYYY') : moment(e.old_value).format('MMM-YYYY')}`
                            }) : 'Scheduled Project Completion'} >Scheduled Financial Closure</small><div className="text-dark">{projectData.sch_financial_closure ? moment.unix(projectData.sch_financial_closure).format('MMM-YYYY') : '-'}</div>
                        </Col>
                        <Col sm={3} md={3}>
                            <small className={`font-weight-light ${highLightValues.includes("rev_financial_closure") && "data-title"}`} data-title={highLightValues.includes("rev_financial_closure") ? heightData.filter((e) => e.id === "rev_financial_closure").map((e) => {
                                return `Previous Value : \n${moment(Number(e.old_value)).format('MMM-YYYY') !== "Invalid date" ?
                                    moment(Number(e.old_value)).format('MMM-YYYY') : moment(e.old_value).format('MMM-YYYY')}`
                            }) : 'Anticipated Completion'} >Revised Financial Closure</small><div className="text-dark">{projectData.rev_financial_closure ? moment.unix(projectData.rev_financial_closure).format('MMM-YYYY') : '-'}</div>
                        </Col>
                        <Col sm={3} md={3}>
                            <small className={`font-weight-light ${highLightValues.includes("ant_financial_closure") && "data-title"}`}
                                data-title={highLightValues.includes("ant_financial_closure") ? heightData.filter((e) => e.id === "ant_financial_closure").map((e) => {
                                    return `Previous Value : \n${moment(Number(e.old_value)).format('MMM-YYYY') !== "Invalid date" ?
                                        moment(Number(e.old_value)).format('MMM-YYYY') : moment(e.old_value).format('MMM-YYYY')}`
                                }) : 'Financial Closure'}
                              >Anticipated Financial Closure</small>
                            <div className="text-dark">
                                {projectData.ant_financial_closure ? moment.unix(projectData.ant_financial_closure).format('MMM-YYYY') : '-'}
                            </div>
                        </Col>
                        <Col sm={3} md={3}>
                            <small className={`font-weight-light ${highLightValues.includes("act_financial_closure") && "data-title"}`} data-title={highLightValues.includes("act_financial_closure") ? heightData.filter((e) => e.id === "actual_closure_date").map((e) => {
                                return `Previous Value : \n${moment(Number(e.old_value)).format('MMM-YYYY') !== "Invalid date" ?
                                    moment(Number(e.old_value)).format('MMM-YYYY') : moment(e.old_value).format('MMM-YYYY')}`
                            }) : 'Anticipated Completion'} >Actual Financial Closure</small><div className="text-dark">{projectData.act_financial_closure ? moment.unix(projectData.act_financial_closure).format('MMM-YYYY') : '-'}</div>
                        </Col>
                        <Col sm={3} md={3}>
                            <small className={`font-weight-light ${highLightValues.includes("concession_end_date") && "data-title"}`}
                                data-title={highLightValues.includes("financial_closure") ? heightData.filter((e) => e.id === "concession_end_date").map((e) => {
                                    return `Previous Value : \n${moment(Number(e.old_value)).format('MMM-YYYY') !== "Invalid date" ?
                                        moment(Number(e.old_value)).format('MMM-YYYY') : moment(e.old_value).format('MMM-YYYY')}`
                                }) : 'Financial Closure'}
                              >Concession End</small>
                            <div className="text-dark">
                                {projectData.concession_end_date ? moment.unix(projectData.concession_end_date).format('MMM-YYYY') : '-'}
                            </div>
                        </Col>
                        <Col sm={3} md={3}>
                            <small className={`font-weight-light ${highLightValues.includes("concession_period") && "data-title"}`} data-title={highLightValues.includes("concession_period") ? heightData.filter((e) => e.id === "concession_period").map((e) => {
                                return `Previous Value : \n${e.old_value}`
                            }) : 'Construction Period (Months)'} >Concession Period (years)</small><div className="text-dark">{!_.isEmpty(projectData.concession_period) ? projectData.concession_period : '-'}</div>
                        </Col>
                        <Col sm={3} md={3}>
                            <small className={`font-weight-light ${highLightValues.includes("concession_period") && "data-title"}`} data-title={highLightValues.includes("const_period") ? heightData.filter((e) => e.id === "const_period").map((e) => {
                                return `Previous Value : \n${e.old_value}`
                            }) : 'Basis Of Award'} >Construction Period (Months)</small><div className="text-dark">{!_.isEmpty(`${projectData.const_period}`) ? projectData.const_period : '-'}</div>
                        </Col>
                        <Col sm={4} md={4}>
                            <small className={`font-weight-light ${highLightValues.includes("time_overrun") && "data-title"}`} data-title={highLightValues.includes("time_overrun") ? heightData.filter((e) => e.id === "time_overrun").map((e) => {
                                return `Previous Value : \n${e.old_value}`
                            }) : 'Time Overrun (Months)'} >Time Overrun (Months)</small><div className="text-dark">{+projectData.time_overrun > 0 ? projectData.time_overrun : '-'}</div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        );
    }

    static FinancialInfoCard(props) {
        const { projectData, highLightValues, heightData } = props;

        return (
            <Card className="card-box card-list-box mb-3 rounded-0 border-0 project-view-details-card">
                <CardBody>
                    <div className="text-left mb-2" style={{ backgroundColor: '#f7f7f7' }}>
                        <div className="mb-0 text-black py-1 px-1 project-name" style={{ fontSize: '0.83rem' }}>Financial Info</div>
                    </div>
                    <Row>
                        {/***<Col sm={12} md={12}>
                            <small className={`font-weight-light ${highLightValues.includes("") && "data-title"}`} title={'Banks'}>Banks</small><div className="text-dark">{_.size(projectData.bank_name) ? _.join(projectData.bank_name, ', ') : '-'}</div>
                        </Col>***/}
                        <Col sm={4} md={6}>
                            <small className={`font-weight-light ${highLightValues.includes("scheduled_project_cost") && "data-title"}`} data-title={highLightValues.includes("scheduled_project_cost") ? heightData.filter((e) => e.id === "scheduled_project_cost").map((e) => {
                                return `Previous Value : \n${e.old_value}`
                            }) : 'Total Project Cost (Rs.Cr)'} >Scheduled Project Cost (Rs.Crs) </small><div className="text-dark">{projectData.scheduled_project_cost ? projectData.scheduled_project_cost : '-'}</div>
                        </Col>
                        <Col sm={4} md={6}>
                            <small className={`font-weight-light ${highLightValues.includes("revised_project_cost") && "data-title"}`} data-title={highLightValues.includes("revised_project_cost") ? heightData.filter((e) => e.id === "rrevised_project_costpc").map((e) => {
                                return `Previous Value : \n${e.old_value}`
                            }) : 'Revised Project Cost (Rs.Cr)'} >Revised Project Cost (Rs.Crs)</small><div className="text-dark">{projectData.revised_project_cost ? projectData.revised_project_cost : '-'}</div>
                        </Col>
                        <Col sm={4} md={6}>
                            <small className={`font-weight-light ${highLightValues.includes("actual_project_cost") && "data-title"}`} data-title={highLightValues.includes("actual_project_cost") ? heightData.filter((e) => e.id === "actual_project_cost").map((e) => {
                                return `Previous Value : \n${e.old_value}`
                            }) : 'Developer Cost (Rs.Cr)'} >Actual Project Cost (Rs.Crs)</small><div className="text-dark">{projectData.actual_project_cost ? projectData.actual_project_cost : '-'}</div>
                        </Col>
                        <Col sm={4} md={6}>
                            <small className={`font-weight-light ${highLightValues.includes("project_funding") && "data-title"}`} data-title={highLightValues.includes("project_funding") ? heightData.filter((e) => e.id === "project_funding").map((e) => {
                                return `Previous Value : \n${e.old_value}`
                            }) : 'Grant (%)'} >Funding Of Project</small><div className="text-dark">{projectData.project_funding ? projectData.project_funding : '-'}</div>
                        </Col>
                        <Col sm={4} md={6}>
                            <small className={`font-weight-light ${highLightValues.includes("equity") && "data-title"}`} data-title={highLightValues.includes("equity") ? heightData.filter((e) => e.id === "equity").map((e) => {
                                return `Previous Value : \n${e.old_value}`
                            }) : 'Equity (Rs.Cr)'} >Equity (Rs.Cr)</small><div className="text-dark">{projectData.equity ? projectData.equity : '-'}</div>
                        </Col>
                        <Col sm={4} md={6}>
                            <small className={`font-weight-light ${highLightValues.includes("debt") && "data-title"}`} data-title={highLightValues.includes("debt") ? heightData.filter((e) => e.id === "debt").map((e) => {
                                return `Previous Value : \n${e.old_value}`
                            }) : 'Debt (Rs.Cr)'} >Debt (Rs.Cr)</small><div className="text-dark">{projectData.debt ? projectData.debt : '-'}</div>
                        </Col>
                        <Col sm={4} md={6}>
                            <small className={`font-weight-light ${highLightValues.includes("annuity_amt") && "data-title"}`} data-title={highLightValues.includes("annuity_amt") ? heightData.filter((e) => e.id === "annuity_amt").map((e) => {
                                return `Previous Value : \n${e.old_value}`
                            }) : 'Annuity (Rs.Cr)'} >Annuity (Rs.Cr)</small><div className="text-dark">{projectData.annuity_amt ? projectData.annuity_amt : '-'}</div>
                        </Col>
                        <Col sm={4} md={6}>
                            <small className={`font-weight-light ${highLightValues.includes("annuity_percentage") && "data-title"}`} data-title={highLightValues.includes("annuity_percentage") ? heightData.filter((e) => e.id === "annuity_percentage").map((e) => {
                                return `Previous Value : \n${e.old_value}`
                            }) : 'Annuity (%)'} >Annuity (%)</small><div className="text-dark">{projectData.annuity_percentage ? projectData.annuity_percentage : '-'}</div>
                        </Col>
                        <Col sm={4} md={6}>
                            <small className={`font-weight-light ${highLightValues.includes("grant_amt") && "data-title"}`} data-title={highLightValues.includes("grant_amt") ? heightData.filter((e) => e.id === "grant_amt").map((e) => {
                                return `Previous Value : \n${e.old_value}`
                            }) : 'Grant (Rs.Cr)'} >Grant (Rs.Cr)</small><div className="text-dark">{projectData.grant_amt ? projectData.grant_amt : '-'}</div>
                        </Col>
                         <Col sm={4} md={4}>
                            <small className={`font-weight-light ${highLightValues.includes("grant_percentage") && "data-title"}`} data-title={highLightValues.includes("grant_percentage") ? heightData.filter((e) => e.id === "grant_percentage").map((e) => {
                                return `Previous Value : \n${e.old_value}`
                            }) : 'Premium (Rs.Cr)'}  >Grant (%)</small><div className="text-dark">{projectData.grant_percentage ? projectData.grant_percentage : '-'}</div>
                        </Col>
                        <Col sm={4} md={6}>
                            <small className={`font-weight-light ${highLightValues.includes("sec_loans") && "data-title"}`} data-title={highLightValues.includes("sec_loans") ? heightData.filter((e) => e.id === "sec_loans").map((e) => {
                                return `Previous Value : \n${e.old_value}`
                            }) : 'Total Project Cost/KM'}>Secured Loan</small><div className="text-dark">{projectData.sec_loans ? projectData.sec_loans : '-'}</div>
                        </Col>
                        <Col sm={4} md={6}>
                            <small className={`font-weight-light ${highLightValues.includes("unsec_loans") && "data-title"}`} data-title={highLightValues.includes("unsec_loans") ? heightData.filter((e) => e.id === "unsec_loans").map((e) => {
                                return `Previous Value : \n${e.old_value}`
                            }) : 'Long Term Loan (Rs.Cr)'} >Unsecured Loan (Rs.Cr)</small><div className="text-dark">{projectData.unsec_loans ? projectData.unsec_loans : '-'}</div>
                        </Col>
                        <Col sm={4} md={6}>
                            <small className={`font-weight-light ${highLightValues.includes("balance_sheet_last_filed_date") && "data-title"}`} data-title={highLightValues.includes("balance_sheet_last_filed_date") ? heightData.filter((e) => e.id === "balance_sheet_last_filed_date").map((e) => {
                                return `Previous Value : \n${moment(Number(e.old_value)).format('MMM-YYYY') !== "Invalid date" ?
                                    moment(Number(e.old_value)).format('MMM-YYYY') : moment(e.old_value).format('MMM-YYYY')}`
                            }) : 'RFP'} >Balance Sheet Last Filled</small><div className="text-dark">{projectData.balance_sheet_last_filed_date ? moment.unix(projectData.balance_sheet_last_filed_date).format('MMM-YYYY')  : '-'}</div>

                        </Col>
                    </Row>
                </CardBody>
            </Card>
        );
    }

    static BankInfoCard(props) {
        const { projectData, highLightValues, heightData } = props;
        return (
            <Card className="card-box card-list-box mb-3 rounded-0 border-0 project-view-details-card">
                <CardBody>
                    <div className="text-left mb-2" style={{ backgroundColor: '#f7f7f7' }}>
                        <span data-title={highLightValues.includes("m_bank_id") ? heightData.filter((e) => e.id === "m_bank_id").map((e) => {
                            return `Previous Value : \n${e.old_value}`
                        }) : "Bank"} className={`mb-0 text-black py-1 px-1 project-name ${highLightValues.includes("m_bank_id") && "data-title"}`} style={{ fontSize: '0.83rem' }} >Banks Info</span>
                    </div>
                    <Row>
                        <Col sm={12} md={12}>
                            <div className="text-dark">{_.size(projectData.bank_name) ? _.join(projectData.bank_name, ', ') : '-'}</div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        );
    }

    static MemberInfoCard(props) {
        const { projectData, highLightValues, heightData } = props;


        return (
            <Row>
                <Col md={6} className="pr-2">
                    <Card className="card-box card-list-box mb-3 rounded-0 border-0 project-view-details-card">
                        <CardBody>
                            <div className="text-left mb-2" style={{ backgroundColor: '#f7f7f7' }}>
                                <span data-title={highLightValues.includes("ind_engineer") ? heightData.filter((e) => e.id === "ind_engineer").map((e) => {
                                    return `Previous Value : \n${e.old_value}`
                                }) : 'Independent Engineer'} className={`mb-0 text-black py-1 px-1 project-name ${highLightValues.includes("ind_engineer") && "data-title"}`} style={{ fontSize: '0.83rem'}}>Independent Engineer</span>
                            </div>
                            <Row className="mx-0">
                                {
                                    _.size(projectData.ind_engineer) ? _.map(_.values(projectData.ind_engineer), (names, indx) => (

                                        <Col md={12} key={names + '_~' + indx} className={_.size(projectData.ind_engineer) > 1 && indx !== (_.size(projectData.ind_engineer) - 1) ? "border-bottom pb-2" : ""}>
                                            {
                                                names !== "" ? <div className="text-dark">{indx + 1}. {names}</div> : '-'
                                            }
                                        </Col>
                                    )) : (<Col md={12}>
                                        <div className="text-dark text-capitalize">No Data available</div>
                                    </Col>)
                                }
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                <Col md={6} className="pl-2">
                    <Card className="card-box card-list-box mb-3 rounded-0 border-0 project-view-details-card">
                        <CardBody>
                            <div className="text-left mb-2" style={{ backgroundColor: '#f7f7f7' }}>
                                <span data-title={highLightValues.includes("other_members") ? heightData.filter((e) => e.id === "other_members").map((e) => {
                                    return `Previous Value : \n${e.old_value}`
                                }) : "Other Members"} className={`mb-0 text-black py-1 px-1 project-name ${highLightValues.includes("other_members") && "data-title"}`} style={{ fontSize: '0.83rem' }}>Other Members</span>
                            </div>
                            <Row className="mx-0">
                                {
                                    _.size(projectData.other_members) ? _.map(_.values(projectData.other_members), (names, indx) => (
                                        <Col md={12} key={names + '_~' + indx} className={_.size(projectData.other_members) > 1 && indx !== (_.size(projectData.other_members) - 1) ? "border-bottom pb-2" : ""}>
                                            {
                                                names !== "" ? <div className="text-dark">{indx + 1}. {names}</div> : '-'
                                            }
                                        </Col>
                                    )) : (<Col md={12}>
                                        <div className="text-dark text-capitalize">No data available</div>
                                    </Col>)
                                }
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        );
    }
    static EditInfoCard(props) {
        const { projectDatalog } = props;

        const dateFormate = ["date_rfp",
            "date_rfq",
            "award_date",
            "agreement_signed_on",
            "cons_start_date",
            "actual_comp_date",
            "cons_completion",
            "ant_comp_date",
            "financial_closure"]

        const columnObj = [
            { Header: '#', accessor: 'rec_count', width: "3%", className: 'text-center align-top', Cell: ({ row }) => <div> {row.index + 1}</div> },
            { Header: 'UPDATED BY', accessor: 'user_name', width: '0%', className: 'text-left d-none' },

            { Header: 'UPDATED ON', accessor: 'formatted_date', width: '0%', className: 'text-left- d-none' },
            // {
            {
                Header: 'DETAILS', accessor: 'log_detail', width: '97%', className: 'text-center', Cell: ({ row }) => {

                    var details = JSON.parse(row.values.log_detail)


                    return (
                        <Card>
                            <CardBody>
                                <Row className="justify-content-left" >
                                    <Col md={6} className="mb-0 py-1 px-1 project-name" style={{ fontSize: '0.83rem', fontWeight: "600", color: "#3b3e66", textAlign: "left", marginLeft: "16px" }}>
                                        {details[0]?.field_label === 'New Value' ? 'Added BY' : 'Updated By'}: <span style={{ fontWeight: "500", color: "#000" }}>{row.original.user_name}</span> </Col>
                                    <Col md={6} className="mb-0  py-1 px-1 project-name" style={{ fontSize: '0.83rem', fontWeight: "600", color: "#3b3e66", textAlign: "left", marginLeft: "-16px" }}>
                                        {details[0]?.field_label === 'New Value' ? 'Added On' : 'Updated On'} : <span style={{ fontWeight: "500", color: "#000" }}>{row.original.formatted_date}</span></Col>

                                </Row>
                                <table className='w-100' >
                                    <thead>
                                        <tr>
                                            <th style={{ width: '200px', maxWidth: "200px", color: "#3b3e66", textAlign: "left" }} >Field Name</th>
                                            <th style={{ width: '200px', maxWidth: "200px", color: "#3b3e66", textAlign: "left" }} >Old Value</th>
                                            <th style={{ width: '200px', maxWidth: "200px", color: "#3b3e66", textAlign: "left" }} >Updated Value</th>
                                            <th style={{ width: '300px', maxWidth: "300px", color: "#3b3e66", textAlign: "left" }} >Source</th>
                                            <th style={{ width: '300px', maxWidth: "300px", color: "#3b3e66", textAlign: "left" }} >Remarks</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {details?.map((item) => {

                                            return (
                                                <tr>
                                                    <td style={{ width: '200px', maxWidth: "200px", textAlign: "left" }} className='text_left  '>{item?.field_label}</td>
                                                    <td style={{ width: '200px', maxWidth: "200px", textAlign: "left" }} className='text-left '>
                                                        {typeof (item?.old_value) !== "string" ? <>
                                                            {item?.old_value?.length > 0 ? <> {item?.old_value?.map(val => {
                                                                return (

                                                                    <p class="rounded-2  p-1 mb-0" style={{ wordBreak: "break-all" }}>{val}</p>

                                                                )
                                                            })} </> : "-"} </>
                                                            : <>
                                                                <p className='mb-0' style={{ wordBreak: "break-all" }}>
                                                                    {dateFormate.includes(item.field_name) && item?.old_value !== "-" && item.old_value !== "" ?
                                                                        moment(Number(item?.old_value)).format("MMM-YYYY") === 'Invalid date' ?
                                                                            moment(item?.old_value).format("MMM-YYYY")
                                                                            : moment(Number(item?.old_value)).format("MMM-YYYY")
                                                                        : item?.old_value !== "" ? item?.old_value : '-'}</p>
                                                            </>} </td>

                                                    <td style={{ width: '200px', maxWidth: "200px", textAlign: "left", wordBreak: "break-all" }} className='text-left' >
                                                        {typeof (item?.new_value) !== "string" ? <>
                                                            {item?.new_value?.length > 0 ? <> {item?.new_value?.map(val => {
                                                                return (
                                                                    <p class="rounded-2  p-1 mb-0" style={{ wordBreak: "break-all" }}>{val}</p>
                                                                )
                                                            })} </> : "-"} </>
                                                            : <>
                                                                <p className=' mb-0' style={{ wordBreak: "break-all" }} >{dateFormate.includes(item.field_name) && item?.new_value !== "-" && item.new_value !== "" ? moment(Number(item?.new_value)).format("MMM-YYYY") === 'Invalid date' ?
                                                                    moment(item?.new_value).format("MMM-YYYY") : moment(Number(item?.new_value)).format("MMM-YYYY") : item?.new_value !== "" ? item?.new_value : '-'}</p>
                                                            </>} </td>
                                                    <td style={{ width: '300px', maxWidth: "300px", textAlign: "left" }} className='text_left '><div className='d-flex align-items-center' style={{ wordBreak: "break-all" }}> {item?.source_name}</div></td>
                                                    <td style={{ width: '300px', maxWidth: "300px", textAlign: "left" }} className='text_left '><div className='d-flex align-items-center' style={{ wordBreak: "break-all" }}> {item?.edit_remark}</div></td>

                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </CardBody>
                        </Card>
                    )

                }
            },
        ];

        const columns = useMemo(() => columnObj, []);
        let res_data = projectDatalog ? projectDatalog : [];

        let data = React.useMemo(() => res_data, [res_data]);


        const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow, nexPage, previousPage, gotoPage, setPageSize, state, setGlobalFilter } = useTable({ columns, data }, useGlobalFilter, usePagination,);


        const { pageIndex, pageSize, globalFilter } = state

        const onPageChange = (current, pageSize) => {
            gotoPage(current - 1)

        }

        const changePage = (PageChange, size) => {
            setPageSize(size)
        }
        useEffect(() => {
            changePage(1, 10)
        }, [])


        return (
            <Card className="card-box card-list-box mb-3 rounded-0 border-0 project-view-details-card">
                <CardBody >
                    <div className="text-left mb-2" style={{ backgroundColor: '#f7f7f7' }}>
                        <div className="mb-0 text-black py-1 px-1 project-name" style={{ fontSize: '0.83rem' }}>History</div>
                    </div>
                    <div className={page.length > 10 ? 'table-responsive' : ""}>
                        {
                            data?.length > 10 &&

                            <Row className='justify-content-end '>
                                <InputGroup style={{ maxWidth: '250px', marginRight: '20px', marginBottom: '5px' }} className="filter-input-group">
                                    <Input placeholder="Search" className="form-control form-control-sm rounded-0" value={globalFilter || ""} onChange={(e) => setGlobalFilter(e.target.value)} style={{ width: '275px' }} />
                                    <InputGroupAddon addonType="append"><Button size="sm" style={{ backgroundColor: '#dadada' }} className="rounded-0 border-0 transform-none box-shadow-none"><FontAwesomeIcon icon={['fas', 'search']} size="sm" /></Button></InputGroupAddon>
                                </InputGroup>
                            </Row>
                        }
                        <table {...getTableProps({ className: "table table-bordered table-hover table-custom" })}>
                            <thead>
                                {headerGroups.map(headerGroup => (
                                    <tr {...headerGroup.getHeaderGroupProps(
                                    )}>
                                        {headerGroup.headers.map(column => (
                                            <th {...column.getHeaderProps(column.id === 'user_name' || column.id === 'formatted_date' ? { style: { display: 'none' } } : { style: { zIndex: 10, top: -1, textAlign: "center" } })}>{column.render('Header')}</th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {_.size(page) === 0 ? (
                                    <tr>
                                        <td colSpan={_.size(columns)} className="text-center">No Data Found</td>
                                    </tr>
                                ) : page.map((row, i) => {
                                    prepareRow(row)
                                    return (
                                        <>
                                            <tr {...row.getRowProps()}>
                                                {row.cells.map(cell => {
                                                    return <td {...cell.getCellProps({ className: cell.column.className ? cell.column.className : '' })}>{cell.render('Cell')}</td>
                                                })}
                                            </tr>
                                        </>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    {data.length > 10 &&
                        <Pagination
                            selectComponentClass={Select}
                            pageSizeOptions={['10', '25', '50', '75', '100']}
                            showTotal={(total, [from, to]) => { let _from = +from < 0 ? 1 : from; let _to = to > -1 ? to : total; return (<>{_from} - {_to} Of {total}</>) }}
                            defaultPageSize={10}
                            pageSize={pageSize}
                            defaultCurrent={1}
                            // current={page1.current}
                            total={data.length}
                            onChange={onPageChange}
                            onShowSizeChange={changePage}
                            style={{ borderRadius: '4px', fontSize: '12px' }}
                            wrapperClassName={cx('p-3 my-2 pagination-container')}
                        />}
                </CardBody>
            </Card>
        )
    }
    static Upload({projectId,state,WS_PROJECT_LIST}){
        const {getData}=state
       const [postPerPage,setPostPerPage]=useState(5)
       const[currentPage,setCurrentPage]=useState(1)



         const changeOn = (current, pageSize) => {
           // gotoPage(current - 1)
         setCurrentPage(current)
         }

         const changePage = (PageChange, size) => {
           setPostPerPage(size)
         }
         useEffect(() => {
           changePage(1, 5)
         }, [])
       const indexOfLastPost = currentPage * postPerPage;
     const indexOfFirstPost = indexOfLastPost - postPerPage;
     const currentPosts = getData.slice(indexOfFirstPost, indexOfLastPost);
     return(
       <>
        {
           getData.length>0&&

           <div className='mb-2' >
             <div style={{overflowY:'auto'}}>
             <table className="table table-bordered table-hover table-custom" >
               <thead>
                 <tr>
                   <th style={{ width: "3%" }} className='text-center'>S.No</th>
                   <th style={{ width: "45%" }}>Document Name</th>
                   <th style={{ width: "10%" }}>Document Type</th>
                   {
                    WS_PROJECT_LIST.ALLOW_EDIT&&<>
                     <th style={{ width: "10%" }}>uploaded By</th>
                   <th style={{ width: "15%" }}>uploaded On</th>
                    </>
                   }

                   <th style={{ width: "7%" }} className='text-center'>Action</th>
                 </tr>
               </thead>
               <tbody>
                 {currentPosts.map((e,i) =>
                   <tr>
                     <td className='text-center'>{((currentPage-1)*postPerPage)+i+1}</td>
                     <td>{e.file_name}</td>
                     <td>{e.doc_name}</td>
                     {
                        WS_PROJECT_LIST.ALLOW_EDIT&&<>
                         <td>{e.created_by}</td>
                         <td>{e.created_on}</td>
                        </>
                     }

                     <td><div className='d-flex justify-content-center'>
                       <Button outline size="sm"  href={/^(http|https):\/\/[^ "]+$/.test(e.file_path)?e.file_path:atob(e.file_path)}  target='_blank'className="btn-sm-icon btn-outline-slack border-1 mr-2" > <i class="fa-solid fa-eye"></i></Button>

                     </div></td>
                   </tr>
                 )}
               </tbody>
             </table>
             </div>
             {
               getData.length>5&&<Pagination
               selectComponentClass={Select}
                pageSizeOptions={['5', '10', '30', '50']}
                showTotal={(total, [from, to]) => { let _from = +from < 0 ? 1 : from; let _to = to > -1 ? to : total; return (<>{_from} - {_to} Of {total}</>) }}
                pageSize={postPerPage}
                current={currentPage}
                total={getData.length}
                 onChange={changeOn}
                 onShowSizeChange={changePage}
                style={{ borderRadius: '4px', fontSize: '12px' }}
                wrapperClassName="my-2 pagination-container"
            />
             }

           </div>
             }
       </>
     )

   }

    render() {

        const { userRights: { WS_PROJECT_LIST, ROAD_QUERY }, noDataAvailable } = this.props;
        const { projectData, isDataLoading, projectDatalog, editCard } = this.props;

        const highLight = WS_PROJECT_LIST.ALLOW_SHOW_LAST_UPDATED ? JSON.parse(projectDatalog[0]?.log_detail !== undefined ? projectDatalog[0]?.log_detail : '[]') : []
        projectData.company_m_company_id = highLight.filter((e) => e.id === "company_m_company_id")
        var highLightValues = []

        const heightData = highLight.filter((e) => Object.keys(projectData).includes(e.id))
        heightData.map((e) => {
            highLightValues.push(...Object.keys(projectData).filter(a => a === e.id))
        })
        let rname = "road"
        const window_url = window.location.href;


        console.log(this.props)

        return (
            <Fragment>

                {isDataLoading ? (<ProjectViewLoader />) :

                    (<>
                        <WsProjectsView.ProjectInfoCard {...this.props} highLightValues={highLightValues} heightData={heightData} />

                        {
                           (
                                <Fragment>
                                    {

                                    }
                                    <WsProjectsView.CompanyInfoCard access={WS_PROJECT_LIST} highLightValues={highLightValues}  heightData={heightData} {...this.props} viewFinanceData={this.viewFinanceData} sliderOpenDev={this.sliderOpenDev} sliderOpenSPV={this.sliderOpenSPV} groupSlider={this.groupSlider} {...this.state} closeFinanceData={this.closeFinanceData} />
                                    <Row>
                                        <Col md={8} className="pr-2">
                                            <WsProjectsView.BiddingInfoCard highLightValues={highLightValues}  heightData={heightData} {...this.props} />
                                        </Col>
                                        <Col md={4} className="pl-2">
                                            <WsProjectsView.FinancialInfoCard highLightValues={highLightValues}  heightData={heightData} {...this.props} />
                                        </Col>
                                    </Row>
                                    <WsProjectsView.BankInfoCard {...this.props} highLightValues={highLightValues}  heightData={heightData} />
                                    <WsProjectsView.Upload projectId={this.projectId} state={this.state} WS_PROJECT_LIST={WS_PROJECT_LIST}/>
                                        {
                                            this.state.dataLoading&& this.props.projectData.ws_long && this.props.projectData.ws_long&& !window.location.pathname.includes('/wsqry') &&<div ref={this.props.refMap}><MapView {...this.props}/></div>
                                        }

                                    {
                                        WS_PROJECT_LIST.ALLOW_EDIT &&this.props?.projectData.log_count>0&& <div className='text-end'>
                                        {
                                            !this.state.loadButton&& <Button size="sm" className='mt-3' loading={this.state.loadButton} color="primary" id="AddEntryTooltip20"  onClick={()=>this.openHistory()}>{!this.state.historyShow?"Show History":"Hide History"}</Button>
                                        }

                                        {
                                            this.state.loadButton && <Spinner></Spinner>
                                        }
                                      </div>
                                      }

                                    {/* <WsProjectsView.MemberInfoCard {...this.props} highLightValues={highLightValues}  heightData={heightData} /> */}
                                    {WS_PROJECT_LIST.ALLOW_EDIT && projectDatalog.length > 0 && editCard === true && WS_PROJECT_LIST.ALLOW_UPDATE_LOG_VIEW ?
                                       <>
                                       {
                                       this.state.historyShow&& <div ref={this.history}><WsProjectsView.EditInfoCard {...this.props}  {...this.state} /></div>
                                       }
                                       </> : ""
                                    }
                                    {window_url.includes("rdprojectsview") &&
                                        <div className={this.state.sliderShow === true ? 'slider-bg' : 'slider-bg-close'}>
                                            <div className={this.state.sliderShow === true ? 'company-slide-drawer' : 'company-slide-drawer-close'}>

                                                <Button onClick={(e) => { e.preventDefault(); this.closeSlider(); }} className="close-page-drawer-btn btn btn-sm close-btn-slider" id="ClosePageDrawerTooltip">
                                                    <div className={cx("navbar-toggler hamburger hamburger--elastic", { 'is-active': true })}>
                                                        <span className="hamburger-box">
                                                            <span className="hamburger-inner" />
                                                        </span>
                                                    </div>
                                                </Button>
                                                <UncontrolledTooltip target="ClosePageDrawerTooltip" placement="left">
                                                    Close drawer
                                                </UncontrolledTooltip>
                                                <div >
                                                    <AvailableProject projectData={this.state.available} close={this.closeSlider} company={this.state.company} rname={rname} />
                                                </div>
                                            </div>
                                        </div>}
                                </Fragment>
                            )
                        }
                    </>
                    )
                }
                <AjaxLoader isOpen={this.state.isSlider} pageLoader={true} loaderText={'Fetching Details...'} altText={'Loadin'} toggle={() => { }} />

            </Fragment>
        );
    }

}
const mapStateToProps = (state, prevState) => {
    return {
        isDataLoading: state.WaterSanitation.wsprojectRowData.loading || false,
        projectData: state.WaterSanitation.wsprojectRowData.data || {},
        projectDatalog: state.WaterSanitation.wsprojectRowDatalog.data || {},
        isDataLoadingErr: state.WaterSanitation.wsprojectRowData.error || false,
        isDataLoadingErrMsg: state.WaterSanitation.wsprojectRowData.msg || '',
        userRights: _.has(state.Authentication, 'user.action') ? state.Authentication.user.action : {},
        initData: state.WaterSanitation.wsaddProjectInitData.data || {},

    };
};
const mapDispatchToProps = (dispatch) => ({
    getProjectDataById: (projectid) => dispatch(fnWaterSanitationActions.getProjectDataById(projectid)),
    getWsProjectDetailsLog: (projectid) => dispatch(fnWaterSanitationActions.getWsProjectDetailsLog(projectid)),
    loadAddProjectInitData: (data) => dispatch(fnWaterSanitationActions.loadAddProjectInitData(data)),

});
export default connect(mapStateToProps, mapDispatchToProps)(WsProjectsView);