export const GET_DBOARD_DATA_REQUEST_INIT = 'GET_DBOARD_DATA_REQUEST_INIT';
export const GET_DBOARD_DATA_REQUEST_DONE = 'GET_DBOARD_DATA_REQUEST_DONE';
export const GET_DBOARD_DATA_REQUEST_FAILURE = 'GET_DBOARD_DATA_REQUEST_FAILURE';

export const GET_DBOARD_ROAD_DATA_REQUEST_INIT = 'GET_DBOARD_ROAD_DATA_REQUEST_INIT';
export const GET_DBOARD_ROAD_DATA_REQUEST_DONE = 'GET_DBOARD_ROAD_DATA_REQUEST_DONE';
export const GET_DBOARD_ROAD_DATA_REQUEST_FAILURE = 'GET_DBOARD_ROAD_DATA_REQUEST_FAILURE';

export const GET_DBOARD_METRO_DATA_REQUEST_INIT = 'GET_DBOARD_METRO_DATA_REQUEST_INIT';
export const GET_DBOARD_METRO_DATA_REQUEST_DONE = 'GET_DBOARD_METRO_DATA_REQUEST_DONE';
export const GET_DBOARD_METRO_DATA_REQUEST_FAILURE = 'GET_DBOARD_METRO_DATA_REQUEST_FAILURE';

export const GET_DBOARD_WS_DATA_REQUEST_INIT = 'GET_DBOARD_WS_DATA_REQUEST_INIT';
export const GET_DBOARD_WS_DATA_REQUEST_DONE = 'GET_DBOARD_WS_DATA_REQUEST_DONE';
export const GET_DBOARD_WS_DATA_REQUEST_FAILURE = 'GET_DBOARD_WS_DATA_REQUEST_FAILURE';