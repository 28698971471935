import React, {Fragment, useState, Component} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Row, Table, Col, CardBody, Card, CardTitle, CardHeader, Collapse, UncontrolledPopover, Container, InputGroup, InputGroupAddon, InputGroupText, FormText, Form, CustomInput, Label, FormGroup, Input, Badge, ListGroup, ListGroupItem, PopoverHeader, PopoverBody, UncontrolledTooltip, TabContent, TabPane, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledDropdown, Progress, DropdownToggle, DropdownMenu, Alert, DropdownItem } from 'reactstrap'

import {connect, useSelector, shallowEqual} from 'react-redux';
import RouterLink from './react-metismenu-router-link';
import {getLoginUserTypeId} from '../../utils/functions.utils'

import MetisMenu from 'react-metismenu';
import _ from 'lodash';
import { SidebarUserbox } from '../../layout-components';


const sidebarMenuContentBk=[
  {
    "label": "Dashboards",
    //"icon": "pe-7s-safe",
    "icon": "fa fa-road",
    "to": "/dashboard",
    /***"content": [
      {
        "label": "Default",
        "to": "/DashboardDefault"
      },
      {
        "label": "Analytics",
        "to": "/DashboardAnalytics"
      },
      {
        "label": "Sales Management",
        "to": "/DashboardSales"
      },
      {
        "label": "Reports",
        "to": "/DashboardReports"
      },
      {
        "label": "Real Estate",
        "to": "/DashboardRealEstate"
      },
      {
        "label": "Server Status",
        "to": "/DashboardServerStatus"
      },
      {
        "label": "Projects",
        "to": "/DashboardProjects"
      },
      {
        "label": "Helpdesk",
        "to": "/DashboardHelpdesk"
      },
      {
        "label": "Customers",
        "to": "/DashboardCustomers"
      },
      {
        "label": "Cryptocurrency",
        "to": "/DashboardCryptocurrency"
      },
      {
        "label": "CRM Manager",
        "to": "/DashboardCrmManager"
      },
      {
        "label": "Content Statistics",
        "to": "/DashboardContent"
      }
    ]***/
  },
  {
    "label": "Manage Users",
    "icon": "pe-7s-users",
    "to": "/users"
  },
  {
    "label": "Company",
    "icon": "pe-7s-network",
    "content":[
        {
          "label": "Company",
          "to": "/clist"
        },
        {
          "label": "Update Log",
          "to": "/cupdatelog"
        }
    ]
  },
  {
    "label": "Road",
    "icon": "pe-7s-way",
    "content": [
      {
        "label": "Projects",
        "to": "/rdprojects"
      },
      {
        "label": "Query",
        "to": "/rdqry"
      },
      {
        "label": "Update Log",
        "to": "/rdupdatelog"
      }
    ]
  },{
    "label": "Metro",
    "icon": "pe-7s-way",
    "content": [
      {
        "label": "Projects",
        "to": "/metroprojects"
      }
    ]
  },
  {
    "label": "Water & Sanitation",
    "icon": "pe-7s-way",
    "content": [
      {
        "label": "Projects",
        "to": "/wsprojects"
      }
    ]
  },
  {
    "label": "My Project List",
    "icon": "pe-7s-way",
    "content": [
      {
        "label": "Projects",
        "to": "/myprojectlist"
      }
    ]
  },

  /***{
    "label": "Applications",
    "icon": "pe-7s-network",
    "content": [
      {
        "label": "Calendar",
        "to": "/ApplicationsCalendar"
      },
      {
        "label": "Chat",
        "to": "/ApplicationsChat"
      },
      {
        "label": "Contacts",
        "to": "/ApplicationsContacts"
      },
      {
        "label": "File Manager",
        "to": "/ApplicationsFileManager"
      },
      {
        "label": "Mail",
        "to": "/ApplicationsMail"
      },
      {
        "label": "Projects",
        "to": "/ApplicationsProjects"
      },
      {
        "label": "App Widgets",
        "to": "/ApplicationsWidgets"
      }
    ]
  },
  {
    "label": "Pages",
    "icon": "pe-7s-portfolio",
    "content": [
      {
        "label": "Login",
        "to": "/PagesLogin"
      },
      {
        "label": "Register",
        "to": "/PagesRegister"
      },
      {
        "label": "Recover Password",
        "to": "/PagesRecoverPassword"
      },
      {
        "label": "Profile",
        "to": "/PagesProfile"
      },
      {
        "label": "Invoice",
        "to": "/PagesInvoice"
      },
      {
        "label": "Error 404",
        "to": "/PagesError404"
      },
      {
        "label": "Error 500",
        "to": "/PagesError500"
      },
      {
        "label": "Error 505",
        "to": "/PagesError505"
      }
    ]
  },
  {
    "label": "Elements",
    "icon": "pe-7s-keypad",
    "content": [
      {
        "label": "Buttons",
        "to": "/Buttons"
      },
      {
        "label": "Dropdowns",
        "to": "/Dropdowns"
      },
      {
        "label": "Navigation menus",
        "to": "/NavigationMenus"
      },
      {
        "label": "Avatars",
        "icon": "icon",
        "to": "/Avatars"
      },
      {
        "label": "Progress Bars",
        "to": "/ProgressBars"
      },
      {
        "label": "Ratings",
        "to": "/Ratings"
      },
      {
        "label": "Pagination",
        "to": "/Pagination"
      },
      {
        "label": "Ribbons",
        "to": "/Ribbons"
      },
      {
        "label": "Scrollable",
        "to": "/Scrollable"
      },
      {
        "label": "Search Bars",
        "to": "/SearchBars"
      },
      {
        "label": "Badges",
        "to": "/Badges"
      },
      {
        "label": "Icons",
        "to": "/Icons"
      },
      {
        "label": "Timelines",
        "to": "/Timelines"
      },
      {
        "label": "Utilities & Helpers",
        "to": "/UtilitiesHelpers"
      }
    ]
  },
  {
    "label": "Cards",
    "icon": "pe-7s-box2",
    "content": [
      {
        "label": "Cards examples 1",
        "to": "/Cards1"
      },
      {
        "label": "Cards examples 2",
        "to": "/Cards2"
      },
      {
        "label": "Cards examples 3",
        "to": "/Cards3"
      },
      {
        "label": "Cards examples 4",
        "to": "/Cards4"
      },
      {
        "label": "Cards examples 5",
        "to": "/Cards5"
      },
      {
        "label": "Cards examples 6",
        "to": "/Cards6"
      },
      {
        "label": "Cards examples 7",
        "to": "/Cards7"
      },
      {
        "label": "Cards examples 8",
        "to": "/Cards8"
      },
      {
        "label": "Cards examples 9",
        "to": "/Cards9"
      },
      {
        "label": "Cards examples 10",
        "to": "/Cards10"
      },
      {
        "label": "Cards examples 11",
        "to": "/Cards11"
      },
      {
        "label": "Cards examples 12",
        "to": "/Cards12"
      }
    ]
  },
  {
    "label": "List Groups",
    "icon": "pe-7s-id",
    "to": "/ListGroups"
  },
  {
    "label": "Presentation Blocks",
    "icon": "pe-7s-box2",
    "content": [
      {
        "label": "Landing page",
        "to": "/LandingPage"
      },
      {
        "label": "Feature sections",
        "to": "/FeatureSections"
      },
      {
        "label": "Icon Boxes",
        "to": "/IconBoxes"
      },
      {
        "label": "Pricing Tables",
        "to": "/PricingTables"
      },
      {
        "label": "Header sections",
        "to": "/HeaderSections"
      },
      {
        "label": "Testimonials sections",
        "to": "/TestimonialsSections"
      },
      {
        "label": "Blog sections",
        "to": "/BlogSections"
      },
      {
        "label": "Partners sections",
        "to": "/PartnersSections"
      },
      {
        "label": "Call to action boxes",
        "to": "/CtaBoxes"
      },
      {
        "label": "Footer sections",
        "to": "/FooterSections"
      },
      {
        "label": "Hero sections",
        "to": "/HeroSections"
      }
    ]
  },
  {
    "label": "Widgets",
    "icon": "pe-7s-display2",
    "content": [
      {
        "label": "Accordions",
        "to": "/Accordions"
      },
      {
        "label": "Modal dialogs",
        "to": "/Modals"
      },
      {
        "label": "Notifications",
        "to": "/Notifications"
      },
      {
        "label": "Drag & Drop",
        "to": "/DragDrop"
      },
      {
        "label": "Calendars",
        "to": "/Calendars"
      },
      {
        "label": "Carousels",
        "to": "/Carousels"
      },
      {
        "label": "Popovers",
        "to": "/Popovers"
      },
      {
        "label": "Tooltips",
        "to": "/Tooltips"
      },
      {
        "label": "Tree View",
        "to": "/TreeView"
      },
      {
        "label": "Tabs",
        "to": "/Tabs"
      },
      {
        "label": "Guided Tours",
        "to": "/GuidedTours"
      },
      {
        "label": "Image Crop",
        "to": "/ImageCrop"
      },
      {
        "label": "Loading Indicators",
        "to": "/LoadingIndicators"
      },
      {
        "label": "Count Up",
        "to": "/CountUp"
      },
      {
        "label": "Context Menus",
        "to": "/ContextMenus"
      }
    ]
  },
  {
    "label": "Regular Tables",
    "icon": "pe-7s-albums",
    "content": [
      {
        "label": "Tables examples 1",
        "to": "/RegularTables1"
      },
      {
        "label": "Tables examples 2",
        "to": "/RegularTables2"
      },
      {
        "label": "Tables examples 3",
        "to": "/RegularTables3"
      },
      {
        "label": "Tables examples 4",
        "to": "/RegularTables4"
      },
      {
        "label": "Tables examples 5",
        "to": "/RegularTables5"
      }
    ]
  },
  {
    "label": "Dynamic Tables",
    "icon": "pe-7s-edit",
    "content": [
      {
        "label": "React Table",
        "to": "/DynamicTables1"
      },
      {
        "label": "React Next Table",
        "to": "/DynamicTables2"
      }
    ]
  },
  {
    "label": "Forms Elements",
    "icon": "pe-7s-menu",
    "content": [
      {
        "label": "Layout",
        "to": "/FormsLayout"
      },
      {
        "label": "Controls",
        "to": "/FormsControls"
      },
      {
        "label": "Wizard",
        "to": "/FormsWizard"
      }
    ]
  },
  {
    "label": "Forms Widgets",
    "icon": "pe-7s-tools",
    "content": [
      {
        "label": "Clipboard",
        "to": "/FormsClipboard"
      },
      {
        "label": "Colorpicker",
        "to": "/FormsColorpicker"
      },
      {
        "label": "Datepicker",
        "to": "/FormsDatepicker"
      },
      {
        "label": "Dual Listbox",
        "to": "/FormsDualListbox"
      },
      {
        "label": "Input Mask",
        "to": "/FormsInputMask"
      },
      {
        "label": "Input Select",
        "to": "/FormsInputSelect"
      },
      {
        "label": "Slider",
        "to": "/FormsSlider"
      },
      {
        "label": "Textarea Autosize",
        "to": "/FormsTextareaAutosize"
      },
      {
        "label": "Timepicker",
        "to": "/FormsTimepicker"
      },
      {
        "label": "Toggle Switch",
        "to": "/FormsToggleSwitch"
      },
      {
        "label": "Typeahead",
        "to": "/FormsTypeahead"
      },
      {
        "label": "Upload",
        "to": "/FormsUpload"
      },
      {
        "label": "Validation",
        "to": "/FormsValidation"
      },
      {
        "label": "WYSIWYG Editors",
        "to": "/FormsWysiwygEditor"
      }
    ]
  },
  {
    "label": "Charts",
    "icon": "pe-7s-graph1",
    "content": [
      {
        "label": "Apex Charts",
        "to": "/ApexCharts"
      },
      {
        "label": "Gauges Charts",
        "to": "/GaugesCharts"
      },
      {
        "label": "ChartJS",
        "icon": "icon",
        "to": "/ChartjsCharts"
      },
      {
        "label": "Sparklines Charts",
        "to": "/SparklinesCharts"
      }
    ]
  },
  {
    "label": "Maps",
    "icon": "pe-7s-map-2",
    "to": "/Maps"
  }
  ***/
];

class SidebarMenu extends Component {

  constructor(props) {
    super(props);
    this.state = {
      count_project: this.props.count
    }
    this.sidebarMenuContent = this.setMenuContent(this.props.actions,this.props.road_project_count,  this.props.my_ws_count,this.props.road_qry_count,this.props.ws_project_count);

  }


  // data_count = (document.getElementsByClassName("count-my-project").value = this.props.count)
  componentDidMount() {
    console.log(this.props.actions)
    if(this.props.actions.MENU.ROAD_MY_PROJECT === true) {
      document.getElementById('count-road-project').innerHTML = this.props.road_project_count ? this.props.road_project_count : 0  ;
    }
    if(this.props.actions.MENU.WS_MY_PROJECT === true) {
       document.getElementById('count-ws-project').innerHTML = this.props.ws_project_count ? this.props.ws_project_count : 0  ;
    }


    if(this.props.actions.MENU.ROAD_MY_QUERY === true){
      document.getElementById('count-road-query').innerHTML = this.props.road_qry_count ? this.props.road_qry_count : 0;
    }
    if(this.props.actions.MENU.WS_MY_QUERY === true){
      document.getElementById('count-ws-query').innerHTML =  this.props.my_ws_count ? this.props.my_ws_count : 0;
    }

  }
  componentDidUpdate() {
    if(this.props.actions.MENU.ROAD_MY_PROJECT === true) {
      document.getElementById('count-road-project').innerHTML = this.props.road_project_count ? this.props.road_project_count : 0  ;
    }
    if(this.props.actions.MENU.WS_MY_PROJECT === true) {
        document.getElementById('count-ws-project').innerHTML = this.props.ws_project_count ? this.props.ws_project_count : 0  ;
    }

    if(this.props.actions.MENU.ROAD_MY_QUERY === true){
      document.getElementById('count-road-query').innerHTML = localStorage.getItem("my_query_count") ? localStorage.getItem("my_query_count") : 0;
    }
    if(this.props.actions.MENU.WS_MY_QUERY === true){
      document.getElementById('count-ws-query').innerHTML = localStorage.getItem("my_ws_count") ? localStorage.getItem("my_ws_count") : 0;
    }

  }

  setMenuContent = (Action,project_count) => {
    const menuContent = [];
    if (Action.MENU.DASHBOARD) {
      menuContent.push({ "label": "Dashboards", "icon": "fa-light fa-chart-tree-map", "to": "/dashboard"});
    }
    if (Action.MENU.MANAGE_USERS) {
      menuContent.push({ "label": "Manage Users", "icon": "fa-light fa-users", "to": "/manageusers" });
    }
    if (Action.MENU.MANAGE_LOCAL_ADMIN_USERS) {
      menuContent.push({ "label": "Manage Users", "icon": "fa-light fa-users", "to": "/lausers" });//For Group Manage Users
    }
    if (Action.MENU.COMPANY||Action.MENU.COMPANY_UPDATE_LOG || Action.MENU.COMPANY_GROUP) {

      let obj = {
        "label": "Company",
        "icon": "fa-sharp fa-light fa-buildings",
        "content": []
      };
      if (Action.MENU.COMPANY_GROUP) {
        obj.content.push({ "label": "Company Group", "to": "/cgroup" });
      }
      if (Action.MENU.COMPANY) {
        obj.content.push({ "label": "Company List", "to": "/clist" });
      }
      if (Action.MENU.COMPANY_UPDATE_LOG) {
        obj.content.push({ "label": "Update Log", "to": "/cupdatelog" });
      }
      menuContent.push(obj);
    }
    if (Action.MENU.ROAD_PROJECT_LIST || Action.MENU.ROAD_QUERY || Action.MENU.ROAD_UPDATE_LOG || Action.MENU.ROAD_MY_PROJECT || Action.MENU.ROAD_MY_QUERY || Action.MENU.ROAD_PROJECT_UNPUBLISH) {
      let obj = {
        "label": "Road",
        "icon": "fa-light fa-road",
        "content": []

      };
      if (Action.MENU.ROAD_PROJECT_LIST) {
        obj.content.push({ "label": "Projects", "icon":"fa-light fa-diagram-project", "to": "/rdprojects" });
      }
      if (Action.MENU.ROAD_PROJECT_UNPUBLISH) {
        obj.content.push({ "label":"Projects - Unpublished", "to": "/unpublishproject"});
      }
      if (Action.MENU.ROAD_QUERY) {
        obj.content.push({ "label": "Query", "to": "/rdqry" });
      }
      if (Action.MENU.ROAD_UPDATE_LOG) {
        obj.content.push({ "label": "Update Log", "to": "/rdupdatelog" });
      }
      if (Action.MENU.ROAD_MY_PROJECT) {
        obj.content.push({ "label":<>My Projects <span className='count-box' id='count-road-project'></span></>, "to": "/myprojectlist"});
      }
      if (Action.MENU.ROAD_MY_QUERY) {
        obj.content.push({ "label": <>My Queries <span className='count-box' id='count-road-query' ></span></>, "to": "/myqrylist"});
      }
      menuContent.push(obj);
    }


    if (Action.MENU.WS_PROJECT_LIST) {
      let obj = {
        "label": "Water & Sanitation",
        "icon": "fa-light fa-hand-holding-droplet",
        "content": []

      };
      if (Action.MENU.WS_PROJECT_LIST) {
        obj.content.push({ "label": "Projects", "to": "/wsprojects" });
      }
      if (Action.MENU.WS_PROJECT_UNPUBLISH) {
        obj.content.push({ "label":"Projects - Unpublished", "to": "/wsunpublishproject"});
      }
      if (Action.MENU.WS_QUERY) {
        obj.content.push({ "label":"Query" , "to": "/wsqry"});
      }
      if (Action.MENU.WS_UPDATE_LOG) {
        obj.content.push({ "label": "Update Log", "to": "/wsupdatelog" });
      }
      if (Action.MENU.WS_MY_PROJECT) {
        obj.content.push({ "label":<>My Projects <span className='count-box' id='count-ws-project'></span></> , "to": "/myprojectlistwater"});
      }
      if (Action.MENU.WS_MY_QUERY) {
        obj.content.push({ "label": <>My Queries <span className='count-box' id='count-ws-query' ></span></>, "to": "/myqrylistws"});
      }
      menuContent.push(obj);

    }

    if (Action.MENU.METRO_PROJECT_LIST) {
      menuContent.push({ "label": "Metro", "icon": "fa-light fa-train-subway-tunnel", "content": [{ "label": "Projects", "to": "/metroprojects" }] });
    }
    // if (Action.MENU.WS_PROJECT_LIST) {
    //   menuContent.push({ "label": "My Project List", "icon": "fa-regular fa-rectangle-history", "to": "/myprojectlist" });

    // }
    return menuContent;
  }


  activeLinkMenu = () => {

  }

  render() {

    let {
      sidebarUserbox
    } = this.props;


    return (
        <Fragment>
          <PerfectScrollbar>
            {sidebarUserbox && <SidebarUserbox /> }
            <div className="sidebar-navigation">
                <div className="sidebar-header">
                  { /** <span>Navigation menu</span> **/ }
                </div>

                <MetisMenu ref="menu" activeLinkTo={this.props.activeLinkToMenu}  content={this.sidebarMenuContent} LinkComponent={RouterLink} activeLinkFromLocation iconNamePrefix="" noBuiltInClassNames={true}
                classNameItemActive="active" classNameIcon="sidebar-icon" iconNameStateVisible="sidebar-icon-indicator" iconNameStateHidden="sidebar-icon-indicator"
                classNameItemHasVisibleChild="submenu-open" classNameItemHasActiveChild="active" classNameStateIcon="pe-7s-angle-down"   />
            </div>
          </PerfectScrollbar>
      </Fragment>
      );
    }
}


const mapStateToProps = state => ({
  actions: _.has(state.Authentication, 'user.action') ? state.Authentication.user.action : {},
  sidebarUserbox: state.ThemeOptions.sidebarUserbox,
  activeLinkToMenu: state.ThemeOptions.activeLinkTo || '',

});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarMenu);
