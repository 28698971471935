export const LOGIN_AUTH_REQUEST = 'LOGIN_AUTH_REQUEST';
export const LOGIN_AUTH_SUCCESS = 'LOGIN_AUTH_SUCCESS';
export const LOGIN_AUTH_FAILURE = 'LOGIN_AUTH_FAILURE';
export const LOGOUT_AUTH_REQUEST = 'LOGOUT_AUTH_REQUEST';

export const USER_LOGIN_AUTH_REQUEST_INIT = 'USER_LOGIN_AUTH_REQUEST_INIT';
export const USER_LOGIN_AUTH_REQUEST_DONE = 'USER_LOGIN_AUTH_REQUEST_DONE';
export const USER_LOGIN_AUTH_REQUEST_FAILURE = 'USER_LOGIN_AUTH_REQUEST_FAILURE';

export const USER_FORGET_PASSWORD_REQUEST_INIT = 'USER_FORGET_PASSWORD_REQUEST_INIT';
export const USER_FORGET_PASSWORD_REQUEST_DONE = 'USER_FORGET_PASSWORD_REQUEST_DONE';
export const USER_FORGET_PASSWORD_REQUEST_FAILURE = 'USER_FORGET_PASSWORD_REQUEST_FAILURE';

export const USER_TRIAL_ACC_REQUEST_INIT = 'USER_TRIAL_ACC_REQUEST_INIT';
export const USER_TRIAL_ACC_REQUEST_DONE = 'USER_TRIAL_ACC_REQUEST_DONE';
export const USER_TRIAL_ACC_REQUEST_FAILURE = 'USER_TRIAL_ACC_REQUEST_FAILURE';

export const IP_USER_SELF_REGISTER_REQUEST_INIT = 'IP_USER_SELF_REGISTER_REQUEST_INIT';
export const IP_USER_SELF_REGISTER_REQUEST_DONE = 'IP_USER_SELF_REGISTER_REQUEST_DONE';
export const IP_USER_SELF_REGISTER_REQUEST_FAILURE = 'IP_USER_SELF_REGISTER_REQUEST_FAILURE';

export const GET_IP_ADMIN_DETAIL_REQUEST_INIT = 'GET_IP_ADMIN_DETAIL_REQUEST_INIT';
export const GET_IP_ADMIN_DETAIL_REQUEST_DONE = 'GET_IP_ADMIN_DETAIL_REQUEST_DONE';
export const GET_IP_ADMIN_DETAIL_REQUEST_FAILURE = 'GET_IP_ADMIN_DETAIL_REQUEST_FAILURE';

export const USER_CHANGE_PWD_SAVE_REQUEST_CLEAR_MSG = 'USER_CHANGE_PWD_SAVE_REQUEST_CLEAR_MSG';
export const USER_CHANGE_PWD_SAVE_REQUEST_INIT = 'USER_CHANGE_PWD_SAVE_REQUEST_INIT';
export const USER_CHANGE_PWD_SAVE_REQUEST_DONE = 'USER_CHANGE_PWD_SAVE_REQUEST_DONE';
export const USER_CHANGE_PWD_SAVE_REQUEST_FAILURE = 'USER_CHANGE_PWD_SAVE_REQUEST_FAILURE';

export const USER_PROFILE_INIT_DATA_REQUEST_INIT = 'USER_PROFILE_INIT_DATA_REQUEST_INIT';
export const USER_PROFILE_INIT_DATA_REQUEST_DONE = 'USER_PROFILE_INIT_DATA_REQUEST_DONE';
export const USER_PROFILE_INIT_DATA_REQUEST_FAILURE = 'USER_PROFILE_INIT_DATA_REQUEST_FAILURE';

export const SAVE_USER_PROFILE_DATA_REQUEST_RESET = 'SAVE_USER_PROFILE_DATA_REQUEST_RESET';
export const SAVE_USER_PROFILE_DATA_REQUEST_INIT = 'SAVE_USER_PROFILE_DATA_REQUEST_INIT';
export const SAVE_USER_PROFILE_DATA_REQUEST_DONE = 'SAVE_USER_PROFILE_DATA_REQUEST_DONE';
export const SAVE_USER_PROFILE_DATA_REQUEST_FAILURE = 'SAVE_USER_PROFILE_DATA_REQUEST_FAILURE';