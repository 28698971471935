import React, { Fragment } from 'react';
import _ from 'lodash';
import { PropagateLoader } from 'react-spinners';
import { Col, Row, Button, CustomInput, Form, FormGroup, Label, Input, Alert,UncontrolledTooltip, InputGroup, InputGroupAddon } from 'reactstrap';
import { InputReactAutoComplete, ReactDateTime } from 'components/common';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import Select, { Option } from 'rc-select';

function AddUpdateUserForm(props) {

    const { nameTitle, orgOptions, usersForm, handleCheckbox, isLoading, handleChange, handleSubmit, formError, resetForm, saveManageUsersResProps,
        checkUniqueEmailValidation, serverErrorMsg, setAlertOpen, alertOpen } = props;
    
    let isValidStartDt = _.has(formError, 'start_date') ? formError.start_date : false;
    let isValidEndDt = _.has(formError, 'end_date') ? formError.end_date : false;
        
    let isApplySmDevice = false;
    if (window.outerWidth >= 1200 && window.outerWidth <= 1440) { 
        isApplySmDevice = true;
    }    
    
    return (
        <Fragment>
            <div className="px-4 py-2 page-drawer-header-container">
                <div className="text-left">
                    <h6 className="mb-0 text-black" style={{marginTop: '5px'}}>Manage Users - {(usersForm.id_login_user || 0) > 0 ? 'Update' : 'Add' }</h6>
                </div>
            </div>
            <div className="divider" />
            <div className="px-4 py-3 page-drawer-body-container">    
                <Form style={{ marginBottom: '5rem' }} onSubmit={handleSubmit} className="form-customise">
                    <Input type="hidden" name="id_login_user" id="id_login_user" defaultValue={usersForm.id_login_user || -1} />
                    <FormGroup>
                        <Label>Name Of The User</Label>
                        <Row>
                            <Col xs="3" className="pr-1">

                                {/***<ReactSelect name="id_name_title" isInValid={formError.id_name_title} options={nameTitle} selectedId={usersForm.id_name_title || -1} handleChange={handleChange} />
                                ****/}

                                <Select dropdownClassName="rc-dropdown-styles z-index-2100" placeholder={_.size(nameTitle) ? "Select" : "No data available"} className={cx("w-100", {'is-invalid': formError.id_name_title})} optionLabelProp="children" optionFilterProp="text" onChange={(e) => handleChange(e, 'id_name_title')} value={_.size(nameTitle) && +usersForm.id_name_title > 0 ? +usersForm.id_name_title : _.noop()}>
                                    {_.map(nameTitle, (obj, indx) => (<Option value={obj.value} text={obj.label} key={obj.value}>{obj.label}</Option> ))}
                                </Select>

                            </Col>
                            <Col xs="9"  className="pl-1">
                                <Input type="text" invalid={formError.user_name} name="user_name" id="user_name" bsSize="sm" value={usersForm.user_name || ''} onChange={e => handleChange(e) } placeholder="Enter the your name" />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Label>Login Id (Email Id)</Label>
                        <Input type="email" invalid={formError.user_login} name="user_login" id="user_login" bsSize="sm" value={usersForm.user_login || ''} onChange={e => handleChange(e)} onBlur={e=>checkUniqueEmailValidation(e.target.value)} placeholder="Enter Email Id" />                                   
                    </FormGroup>                    

                    {_.lte(+usersForm.id_login_user, 0) ? (
                        <>
                        <FormGroup className="form-row mb-2">
                            <Col>
                                <CustomInput type="checkbox" invalid={formError.is_subscription} id="is_subscription" name="is_subscription" label="Subscription" checked={usersForm.is_subscription||false} onChange={(e)=>handleCheckbox(e, 'is_subscription')} />
                            </Col>
                            <Col>
                                <CustomInput type="checkbox" invalid={formError.is_trial} id="is_trial" name="is_trial" label="Trial" checked={usersForm.is_trial||false} onChange={(e)=>handleCheckbox(e, 'is_trial')} />
                            </Col>                                
                        </FormGroup>
                        <FormGroup className="date-range">
                            <Label>Validity Period</Label>
                            {/***<DateRangePicker
                                fromDate={usersForm.start_date || ''}
                                formError={formError}
                                toDate={usersForm.end_date || ''}
                                isValidFrom={formError.start_date}
                                isValidTo={formError.end_date}
                                handleChange={handleChange}
                            />***/}    
                                <ReactDateTime.DateRangeField id="validity_period" dateFormat={"DD-MMM-YYYY"} viewMode="days" key="date_range_validity" 
                                    inputProps={{ placeholder: 'DD-MMM-YYYY', readOnly: true, className: (isValidStartDt || isValidEndDt) ? 'is-invalid': '' }}
                                    handleChange={handleChange}
                                    startDate={_.has(usersForm, 'validity_period.start_date') ? moment.unix(usersForm.validity_period.start_date) : ''}
                                    endDate={_.has(usersForm, 'validity_period.end_date') ? moment.unix(usersForm.validity_period.end_date) : ''}
                                    closeOnSelect={true}
                                    timeFormat={false}
                                />    
                        </FormGroup>
                        </>
                    ): null }                    

                    <FormGroup className="mb-2">
                        <CustomInput type="checkbox" id="is_admin" name="is_admin" label="Admin" checked={usersForm.is_admin||false} onChange={(e)=>handleCheckbox(e, 'is_admin')} />
                    </FormGroup>
                    {
                        usersForm.is_admin ? (
                            <FormGroup>
                                <Label>Institution / Organization</Label>  
                                <InputReactAutoComplete
                                    placeholder="Institution / Organization"
                                    name="organization_name"
                                    idFieldName="m_organization_id"
                                    items={orgOptions}
                                    value={usersForm.organization_name || ''}
                                    handleChange={handleChange}
                                    formError={formError}
                                    isvalid={formError.organization_name}                                    
                                />
                            </FormGroup>
                        ) : null
                    }
                    {
                        usersForm.is_admin ? (
                            <FormGroup className="form-row mb-3">
                                <Col>
                                    <CustomInput type="checkbox" id="is_ip_user" name="is_ip_user" label="IP Based" checked={usersForm.is_ip_user||false} onChange={e => handleCheckbox(e, 'ip_user')}/>
                                </Col>
                                <Col>
                                    <CustomInput type="checkbox" id="is_local_admin" name="is_local_admin" label="Group Based" checked={usersForm.is_local_admin||false} onChange={e => handleCheckbox(e, 'group_user')}/>
                                </Col>                                
                            </FormGroup>
                        ) : null
                    }
                    
                    {
                        usersForm.is_local_admin ? (
                            <FormGroup>
                                <Label>No Of Login User</Label>
                                <Input type="number" invalid={formError.no_of_login_user}  name="no_of_login_user" id="no_of_login_user" bsSize="sm" value={usersForm.no_of_login_user||''} onChange={e => handleChange(e) } placeholder="Enter No Of Login User" />                                   
                            </FormGroup>
                        ) : null
                    }
                                                
                    {
                        /**** Add Multiple Ip Range ***/
                        usersForm.is_ip_user ? (
                            <IpRangeAddMultiple title="IP Range" formError={formError} inputData={usersForm.ip_range || []} handleChange={handleChange} isOpen={true} />
                        ) : null
                    }                            

                    <FormGroup>
                        <CustomInput className="mt-4" type="checkbox" id="is_email_send" checked={usersForm.is_email_send||false} onChange={e=>handleChange(!usersForm.is_email_send, 'is_email_send')} name="is_email_send" label="E-Mail Send To Client"/>
                    </FormGroup>

                    {!_.isEmpty(serverErrorMsg) ? (
                        <FormGroup>
                            <Alert color="danger" isOpen={alertOpen} toggle={setAlertOpen}>{serverErrorMsg}</Alert>
                        </FormGroup>
                    ) : null }
                    
                    <FormGroup row className="mt-2 text-center">
                        {_.lte(+usersForm.id_login_user, 0) ? (
                            <>
                                {saveManageUsersResProps.loading ? (
                                    <Col md={{ size: 6, offset: 5 }}>
                                        <PropagateLoader color={'var(--primary)'} loading={true} />
                                    </Col>
                                ) : (
                                        <>
                                            {isApplySmDevice ? <Col>&nbsp;</Col> : null}
                                            <Col> <Button type="reset" color="warning" className="form-reset-cancel-btn" outline block onClick={e => resetForm(e)}>Cancel</Button> </Col>
                                            <Col> <Button type="submit" color="primary" block  className="form-submit-btn"> Save </Button> </Col>
                                        </>
                                    )}
                            </>
                        )   : (
                                <>
                                    {saveManageUsersResProps.loading ? (
                                        <Col md={{ size: 6, offset: 5 }}>
                                            <PropagateLoader color={'var(--primary)'} loading={true} />
                                        </Col>) : (<Col md={{ size: 6, offset: 3 }}><Button type="submit" color="primary" block className="form-submit-btn">Save</Button></Col>)}
                                </>
                            )}
                    </FormGroup>
                </Form>
            </div>
        </Fragment>
    );
}
export default AddUpdateUserForm;


function IpRangeAddMultiple( props ) {
    //let [ipRangeRow, setState] = useState({ rows: [{ start_ip_char: '', end_ip_char: '' }], addBtnDisabled: false });
    
    const { name, limitedRows, isOpen, title, inputData, handleChange, formError } = props; 

    const { ip_range: err_ip_range } = formError;

    if (_.isEmpty(inputData)) { inputData.push({ start_ip_char: '', end_ip_char: '' }); }

    let isShow = !_.isNil(isOpen) ? isOpen : true;

    const addRow = (e) => {
        const values = [...inputData];        
        values.push({ start_ip_char: '', end_ip_char: '' });
        handleChange(values, 'ip_range'); 
    }

    const removeRow = (arrIndx) => {
        const values = [...inputData];
        values.splice(arrIndx, 1);
        handleChange(values, 'ip_range');
    }

    const handleChangeIp = (indx, e) => { 
        const values = [...inputData];  
        values[indx][e.target.name] = e.target.value;
        handleChange(values, 'ip_range');
    }

    return (inputData.length && isShow ) ? (
            <>
            <div className="text-left mb-1">
                <div className="mb-0 text-black" style={{ fontSize: '0.83rem' }}>{title}</div>
            </div>
            <div className="divider mb-3" />
            {
                _.map(inputData, (inputField, indx) => (
                        <FormGroup row key={`${inputField}~${indx}`}>
                            <Col xs="10">
                                <InputGroup className="subscription-period-group">
                                     <Input className={cx("form-control form-control-sm", {'invalid': (!_.isEmpty(err_ip_range) ? _.get(err_ip_range, `${indx}.start_ip_char`) : false) })} name="start_ip_char" id="start_ip_char" value={inputField.start_ip_char || ''} placeholder="10.10.10.10" onChange={e => handleChangeIp(indx, e)} />
                                    <InputGroupAddon addonType="prepend"> To </InputGroupAddon>
                                    <Input className={cx("form-control form-control-sm", { 'invalid': (!_.isEmpty(err_ip_range) ? _.get(err_ip_range, `${indx}.end_ip_char`) : false) })} name="end_ip_char" id="end_ip_char" value={inputField.end_ip_char || ''} placeholder="10.10.10.20" onChange={e => handleChangeIp(indx, e)} />
                                
                                {/***
                                 * <InputMask className={cx("form-control form-control-sm", {'invalid': (!_.isEmpty(err_ip_range) ? _.get(err_ip_range, `${indx}.start_ip_char`) : false) })} name="start_ip_char" id="start_ip_char" value={inputField.start_ip_char || ''} placeholder="From Ip Address" mask="999.999.999.999" onChange={e => handleChangeIp(indx, e)} />
                                    <InputGroupAddon addonType="prepend"> To </InputGroupAddon>
                                    <InputMask className={cx("form-control form-control-sm", {'invalid': (!_.isEmpty(err_ip_range) ? _.get(err_ip_range, `${indx}.end_ip_char`) : false) })} name="end_ip_char" id="end_ip_char" value={inputField.end_ip_char || ''} placeholder="To Ip Address" mask="999.999.999.999" onChange={e => handleChangeIp(indx, e)} />
                                 * 
                                 * ***/}
                                </InputGroup>
                            </Col>
                            <Col xs="2">
                                {
                                    (indx === 0) ? (
                                        <>
                                            <Button size="sm" color="primary" id="AddIpRange" onClick={addRow} className="add-remove-inner-btn">
                                                <span className="btn-wrapper--icon">
                                                    <FontAwesomeIcon icon={['fas', 'plus']} className="opacity-8 font-size-xs" />
                                                </span>
                                            </Button>
                                            <UncontrolledTooltip target="AddIpRange">Add New Ip Range</UncontrolledTooltip>
                                        </>
                                    ) : (
                                            <>
                                                <Button size="sm" color="danger" id={'RemoveIpRange_' + indx} onClick={() => { removeRow(indx) }} className="add-remove-inner-btn">
                                                    <span className="btn-wrapper--icon">
                                                        <FontAwesomeIcon icon={['fas', 'minus']} className="opacity-8 font-size-xs" />
                                                    </span>
                                                </Button>
                                                <UncontrolledTooltip target={'RemoveIpRange_' + indx}>Remove Ip Range</UncontrolledTooltip>
                                            </>
                                        )
                                }
                            </Col>
                        </FormGroup>
                    )
                )
            }
        </>    
    ) : null;
}
