import Axios from 'axios';
import { SERVER_BASE_URL, SERVER_BASE_URL_ROAD,SERVER_BASE_URL_WS } from '../constants';
import _ from 'lodash';
import jQ from 'jquery';
import { getLoginUserID, getLoginUserTypeId} from './functions.utils';
import packageJson from '../../package.json';

// To DO Laterly Add jQuery
//Axios.defaults.headers.post['Content-Type'] = 'multipart/form-data;charset=UTF-8';

export const Server = {
    API : 1,
    ROAD_API : 2,
    WSAPI_API : 3

};

export const Api = Axios.create({
    baseURL: SERVER_BASE_URL,
    transformRequest: [(data, headers) => {
        data['current_login_user_id'] = getLoginUserID();
        data['wv'] = packageJson.version;
        data["_LID"]= getLoginUserID()
        return jQ.param(data);
    }]
});


export const ApiRoad = Axios.create({
    baseURL: SERVER_BASE_URL_ROAD,
    responseType: "json",
    transformRequest: [(data, headers) => {
        data['current_login_user_id'] = getLoginUserID();
        data['current_login_user_type'] = getLoginUserTypeId();
        data["_LID"]= getLoginUserID()
        data['wv'] = packageJson.version;
        return jQ.param(data);
    }]
});

export const ApiWs = Axios.create({
    baseURL: SERVER_BASE_URL_WS,
    responseType: "json",
    transformRequest: [(data, headers) => {
        data['current_login_user_id'] = getLoginUserID();
        data['current_login_user_type'] = getLoginUserTypeId();
        data['wv'] = packageJson.version;
        return jQ.param(data);
    }]
});



export const Request = {
    _convertObjToParams(data) {
        let res = '';
        if (!_.isEmpty(data)) {
            if (_.isPlainObject(data)) {
                data['current_login_user_id'] = getLoginUserID();
                data['current_login_user_type'] = getLoginUserTypeId();
                data['wv'] = packageJson.version;
                data["_LID"]= getLoginUserID()
                //let data_ =  (_.has(data, 'id_login_user') && +_.get(data, 'id_login_user') > 0) ? data : _.assign(data, {current_login_user: id_login_user});
                res = jQ.param(data);
            }
        }
        return res;
    },

    post(requestFor, data = {}, ApiFor = Server.API, objConfig = {}) {

        let param_data = this._convertObjToParams(data);

        let base_url = '';
        switch (ApiFor) {
            case Server.API:
                base_url = SERVER_BASE_URL;
                break;
            case Server.ROAD_API:
                base_url = SERVER_BASE_URL_ROAD;
                break;
            case Server.WSAPI_API:
                base_url = SERVER_BASE_URL_WS;
                break;
        }

        let defaultParams = {
            baseURL: base_url,
            //url: Url,
            method: 'POST',
            data: param_data,
            params: { 'for': requestFor ,"_LID": getLoginUserID()},

        };

        defaultParams = Object.assign({}, defaultParams, objConfig);

        return Axios(defaultParams).then(({ data, status, ...res }) => {
            if (!_.has(data, 'meta') || !_.has(data, 'meta.error') || _.get(data, 'meta.error') == 1) {
                throw new Error(_.get(data, 'meta.msg') || 'Bad Request');
            } else {
                return Promise.resolve(data);
            }
        }).catch(err => {
            return Promise.reject(err.toString());
        });
    },
    get(requestFor, data = {}, ApiFor = Server.API) {

        let base_url = '';
        switch (ApiFor) {
            case Server.API:
                base_url = SERVER_BASE_URL;
                break;
            case Server.ROAD_API:
                base_url = SERVER_BASE_URL_ROAD;
                break;
        }

        data['for'] = requestFor;
        data['current_login_user_id'] = getLoginUserID();
        data["_LID"]= getLoginUserID()


        return Axios({
            baseURL: base_url,
            //url: Url,
            method: 'GET',
            params: data,
            //params: {'for': requestFor}
        }).then(({data, status, ...res}) => {
            if (!_.has(data, 'meta') || !_.has(data, 'meta.error') || _.get(data, 'meta.error') == 1) {
                throw new Error(_.get(data, 'meta.msg') || 'Bad Request')
            } else {
                return Promise.resolve(_.get(data, 'meta.data'));
            }
        }).catch(err => {
            return Promise.reject(err.toString());
        });
    },
    all(groupReqArr) {
        return Axios.all(groupReqArr)
            .then(Axios.spread(function (...response) {
                return Promise.resolve(response);
            }))
            .catch(function (err) {
                return Promise.reject(err.toString());
            });
    },


    uploadFiles(requestFor, data, ApiFor = Server.API) {
        //const { requestFor, data } = objparams;

        //let data_ =  (_.has(data, 'id_login_user') && +_.get(data, 'id_login_user') > 0) ? data : _.assign(data, {current_login_user: id_login_user});

        let base_url = '', Url = '';
        switch (ApiFor) {
            case Server.API:
                base_url = SERVER_BASE_URL;
                break;
            case Server.ROAD_API:
                base_url = SERVER_BASE_URL_ROAD;
                break;
                case Server.WSAPI_API:
                base_url = SERVER_BASE_URL_WS;
                break;
        }

        data['current_login_user_id'] = getLoginUserID();
        data['wv'] = packageJson.version;
        return Axios({
            baseURL: base_url,
            //url: Url,
            headers: { 'Content-Type': 'multipart/form-data' },
            method: 'POST',
            data: data,
            params: { 'for': requestFor ,"_LID":getLoginUserID()},

        }).then(({data, status, ...res}) => {
            if (!_.has(data, 'meta') || !_.has(data, 'meta.error') || _.get(data, 'meta.error') == 1) {
                throw new Error(_.get(data, 'meta.msg') || 'Bad Request')
            } else {
                return Promise.resolve(_.get(data, 'meta'));
            }
        }).catch(err => {
            return Promise.reject(err.toString());
        });
    },
    downloadFiles(objparams, ApiFor = Server.API) {
        const { url, data: data_params, filename, requestFor } = objparams;

        let param_data = this._convertObjToParams(data_params);

        let base_url = '', Url = '';
        switch (ApiFor) {
            case Server.API:
                base_url = SERVER_BASE_URL;
                break;
            case Server.ROAD_API:
                base_url = SERVER_BASE_URL_ROAD;
                break;
                case Server.WSAPI_API:
                base_url = SERVER_BASE_URL_WS;
                break;
        }
        return Axios({
            baseURL: base_url,
            //url: Url, //'http://localhost/idip_v1/api/api.php?for=export_board_director',
            /***method: 'GET',
            params: params, //{m_company_id: 542},
            ***/
            method: 'POST',
            data: param_data,
            params: { 'for': requestFor },
            responseType: 'blob'
        }).then(({data, ...res}) => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const a = document.createElement('a');
            a.href = url;
            a.setAttribute('download', filename || 'unknown_files');
            document.body.appendChild(a);
            a.click();
            setTimeout(() => {
                window.URL.revokeObjectURL(url);
            }, 10000);

            return Promise.resolve(true);

        }).catch(err => {
            return Promise.reject(err.toString());
        })
    }
};