import { GET_FINANCE_DATA_BASED_COMPANY_ID_REQUEST_INIT, GET_FINANCE_DATA_BASED_COMPANY_ID_REQUEST_DONE, GET_FINANCE_DATA_BASED_COMPANY_ID_REQUEST_FAILURE,GET_FINANCE_DATA_DOWNLOAD_REQUEST_INIT, GET_FINANCE_DATA_DOWNLOAD_REQUEST_DONE, GET_FINANCE_DATA_DOWNLOAD_REQUEST_FAILURE, UPLOAD_FINANCE_DATA_REQUEST_INIT, UPLOAD_FINANCE_DATA_REQUEST_DONE, UPLOAD_FINANCE_DATA_REQUEST_FAILURE, SAVE_FINANCE_DATA_REQUEST_INIT, SAVE_FINANCE_DATA_REQUEST_DONE, SAVE_FINANCE_DATA_REQUEST_FAILURE, DELETE_FINANCE_MON_YR_DATA_REQUEST_DONE, DELETE_FINANCE_MON_YR_DATA_REQUEST_INIT, DELETE_FINANCE_MON_YR_DATA_REQUEST_FAILURE, DELETE_COMPANY_FINANCE_DATA_REQUEST_DONE, DELETE_COMPANY_FINANCE_DATA_REQUEST_INIT, DELETE_COMPANY_FINANCE_DATA_REQUEST_FAILURE, UPLOAD_FINANCE_ANNUAL_REPORT_DATA_REQUEST_INIT, UPLOAD_FINANCE_ANNUAL_REPORT_DATA_REQUEST_DONE, UPLOAD_FINANCE_ANNUAL_REPORT_DATA_REQUEST_FAILURE, DELETE_FINANCE_ANNUAL_REPORT_DATA_REQUEST_INIT, DELETE_FINANCE_ANNUAL_REPORT_DATA_REQUEST_DONE, DELETE_FINANCE_ANNUAL_REPORT_DATA_REQUEST_FAILURE, GET_ADD_COMPANY_PAGE_INITIALIZE_DATA_REQUEST_INIT, GET_ADD_COMPANY_PAGE_INITIALIZE_DATA_REQUEST_DONE, GET_ADD_COMPANY_PAGE_INITIALIZE_DATA_REQUEST_FAILURE, CHECK_CIN_CODE_ISVALID_REQUEST_INIT, CHECK_CIN_CODE_ISVALID_REQUEST_DONE, CHECK_CIN_CODE_ISVALID_REQUEST_FAILURE, GET_COMPANY_LIST_DATA_REQUEST_INIT, GET_COMPANY_LIST_DATA_REQUEST_DONE, GET_COMPANY_LIST_DATA_REQUEST_FAILURE, GET_PARENT_COMPANY_OPTIONS_DATA_REQUEST_INIT, GET_PARENT_COMPANY_OPTIONS_DATA_REQUEST_DONE, GET_PARENT_COMPANY_OPTIONS_DATA_REQUEST_FAILURE } from "../constants";

const initState = {
    finance: {
        loading: false,
        error: false,
        msg: '',
        financial_summary: {},
        source_funds: {},
        application_funds: {},
        cash_flow: {},
        key_ratio: {},
        key_share_holder: {},
        board_directors: {},
        capex_details: {},
        excel_is_downloading: false,
        isDeleteFinanceMonYrLoading: false        
    },
    upload: {
        loading: false,
        error: false,
        msg: '',
        res_data: {}
    },
    upExcelFileImportToDb: {
        loading: false,
        error: false,
        msg: '',
    },
    annualReportUpload: {
        loading: false,
        error: false,
        msg: '',
        res_data: {},
        is_deleting: false
    },
    addPageInit: {
        loading: false,
        error: false
    },
    validation: {
        loading: false,
        error: false,
        msg: ''
    },
    companyList: {
        loading: false,
        error: false,
        msg: '',
        res_data: {}
    },
    parentCompanyOption: {
        loading: false,
        error: false,
        msg: '',
        data: []
    }
};

export const Company = (state = initState, action) => {

    switch (action.type) {

        case GET_COMPANY_LIST_DATA_REQUEST_INIT:
            return Object.assign({}, state, { companyList: { ...state.companyList, loading: true, error: false, res_data: {} } });
        case GET_COMPANY_LIST_DATA_REQUEST_DONE:
            return Object.assign({}, state, { companyList: { ...state.companyList, loading: false, error: false, res_data: action.payload } });
        case GET_COMPANY_LIST_DATA_REQUEST_FAILURE:
            return Object.assign({}, state, { companyList: { ...state.companyList, loading: false, error: true, msg: action.payload } });

        case GET_ADD_COMPANY_PAGE_INITIALIZE_DATA_REQUEST_INIT:
            return Object.assign({}, state, { addPageInit: { ...state.addPageInit, loading: true, error: false } });
        case GET_ADD_COMPANY_PAGE_INITIALIZE_DATA_REQUEST_DONE:
            return Object.assign({}, state, { addPageInit: { ...state.addPageInit, loading: false, error: false } });
        case GET_ADD_COMPANY_PAGE_INITIALIZE_DATA_REQUEST_FAILURE:
            return Object.assign({}, state, { addPageInit: { ...state.addPageInit, loading: false, error: true, msg: action.payload } });

        case GET_FINANCE_DATA_BASED_COMPANY_ID_REQUEST_INIT:
            return Object.assign({}, { ...state }, { finance: { ...state.finance, loading: true, error: false } });
        case GET_FINANCE_DATA_BASED_COMPANY_ID_REQUEST_DONE:
            return Object.assign({}, state, { finance: { ...state.finance, loading: false, error: false, ...action.payload } });
        case GET_FINANCE_DATA_BASED_COMPANY_ID_REQUEST_FAILURE:
            return Object.assign({}, state, { finance: { ...state.finance, loading: false, error: true, msg: action.payload } });
        case GET_FINANCE_DATA_DOWNLOAD_REQUEST_INIT:
            return Object.assign({}, state, { finance: { ...state.finance, excel_is_downloading: true } });
        case GET_FINANCE_DATA_DOWNLOAD_REQUEST_DONE:
            return Object.assign({}, state, {finance: { ...state.finance, excel_is_downloading: false }});
        case GET_FINANCE_DATA_DOWNLOAD_REQUEST_FAILURE:
            return Object.assign({}, state, { finance: { ...state.finance, excel_is_downloading: false } });
        
        case DELETE_FINANCE_MON_YR_DATA_REQUEST_INIT:
        case DELETE_COMPANY_FINANCE_DATA_REQUEST_INIT:    
            return Object.assign({}, state, { finance: { ...state.finance, isDeleteFinanceMonYrLoading: true } });
        
        case DELETE_COMPANY_FINANCE_DATA_REQUEST_DONE:
        case DELETE_FINANCE_MON_YR_DATA_REQUEST_DONE:
            return Object.assign({}, state, { finance: { ...state.finance, isDeleteFinanceMonYrLoading: false } });
        case DELETE_FINANCE_MON_YR_DATA_REQUEST_FAILURE:
        case DELETE_COMPANY_FINANCE_DATA_REQUEST_FAILURE:
            return Object.assign({}, state, { finance: { ...state.finance, isDeleteFinanceMonYrLoading: false,msg: action.payload } });
        
        case UPLOAD_FINANCE_DATA_REQUEST_INIT:
            return Object.assign({}, state, { upload: { ...state.upload, loading: true } });
        case UPLOAD_FINANCE_DATA_REQUEST_DONE:
            return Object.assign({}, state, { upload: { ...state.upload, loading: false, error: false, res_data: action.payload } });
        case UPLOAD_FINANCE_DATA_REQUEST_FAILURE:
            return Object.assign({}, state, { upload: { ...state.upload, loading: false, error: true, msg: action.payload } });
        
        case UPLOAD_FINANCE_ANNUAL_REPORT_DATA_REQUEST_INIT:
            return Object.assign({}, state, { annualReportUpload: { ...state.annualReportUpload, loading: true } });
        case UPLOAD_FINANCE_ANNUAL_REPORT_DATA_REQUEST_DONE:
            return Object.assign({}, state, { annualReportUpload: { ...state.annualReportUpload, loading: false, error: false, res_data: action.payload } });
        case UPLOAD_FINANCE_ANNUAL_REPORT_DATA_REQUEST_FAILURE:
            return Object.assign({}, state, { annualReportUpload: { ...state.annualReportUpload, loading: false, error: true, msg: action.payload } });
        
        case DELETE_FINANCE_ANNUAL_REPORT_DATA_REQUEST_INIT:
            return Object.assign({}, state, { annualReportUpload: { ...state.annualReportUpload, is_deleting: true } });
        case DELETE_FINANCE_ANNUAL_REPORT_DATA_REQUEST_DONE:
            return Object.assign({}, state, { annualReportUpload: { ...state.annualReportUpload, is_deleting: false} });
        case DELETE_FINANCE_ANNUAL_REPORT_DATA_REQUEST_FAILURE:
            return Object.assign({}, state, { annualReportUpload: { ...state.annualReportUpload, is_deleting: false } });
        
        case SAVE_FINANCE_DATA_REQUEST_INIT:
            return Object.assign({}, state, { upExcelFileImportToDb: { ...state.upExcelFileImportToDb, loading: true } });
        case SAVE_FINANCE_DATA_REQUEST_DONE:
            return Object.assign({}, state, { upExcelFileImportToDb: { ...state.upExcelFileImportToDb, loading: false, error: false } });
        case SAVE_FINANCE_DATA_REQUEST_FAILURE:
            return Object.assign({}, state, { upExcelFileImportToDb: { ...state.upExcelFileImportToDb, loading: false, error: true, msg: action.payload } });

        case CHECK_CIN_CODE_ISVALID_REQUEST_INIT:
            return Object.assign({}, state, { validation: { ...state.validation, loading: true } });
        case CHECK_CIN_CODE_ISVALID_REQUEST_DONE:
            return Object.assign({}, state, { validation: { ...state.validation, loading: false, error: false, ...action.payload } });
        case CHECK_CIN_CODE_ISVALID_REQUEST_FAILURE:
            return Object.assign({}, state, { validation: { ...state.validation, loading: false, error: true, msg: action.payload } });
        
        case GET_PARENT_COMPANY_OPTIONS_DATA_REQUEST_INIT:
            return Object.assign({}, state, { parentCompanyOption: { loading: true, error: false, msg: '', data: {} } });
        case GET_PARENT_COMPANY_OPTIONS_DATA_REQUEST_DONE:
            return Object.assign({}, state, { parentCompanyOption: { loading: false, error: false, msg: '', data: action.payload } });
        case GET_PARENT_COMPANY_OPTIONS_DATA_REQUEST_FAILURE:
            return Object.assign({}, state, { parentCompanyOption: { loading: false, error: true, msg: action.payload, data: {} } });
    }

    return state;
};