import { SessionData, toDecryption } from "utils";
import _ from 'lodash';
import { APP_SESSION_KEY, LOGIN_AUTH_REQUEST, LOGIN_AUTH_SUCCESS, LOGIN_AUTH_FAILURE, LOGOUT_AUTH_REQUEST, USER_LOGIN_AUTH_REQUEST_INIT, USER_LOGIN_AUTH_REQUEST_DONE, USER_LOGIN_AUTH_REQUEST_FAILURE, USER_FORGET_PASSWORD_REQUEST_INIT, USER_FORGET_PASSWORD_REQUEST_DONE, USER_FORGET_PASSWORD_REQUEST_FAILURE, USER_TRIAL_ACC_REQUEST_DONE, USER_TRIAL_ACC_REQUEST_INIT, USER_TRIAL_ACC_REQUEST_FAILURE, IP_USER_SELF_REGISTER_REQUEST_INIT, IP_USER_SELF_REGISTER_REQUEST_DONE, IP_USER_SELF_REGISTER_REQUEST_FAILURE, GET_IP_ADMIN_DETAIL_REQUEST_INIT, GET_IP_ADMIN_DETAIL_REQUEST_DONE, GET_IP_ADMIN_DETAIL_REQUEST_FAILURE, USER_CHANGE_PWD_SAVE_REQUEST_INIT, USER_CHANGE_PWD_SAVE_REQUEST_DONE, USER_CHANGE_PWD_SAVE_REQUEST_FAILURE, USER_CHANGE_PWD_SAVE_REQUEST_CLEAR_MSG, USER_PROFILE_INIT_DATA_REQUEST_INIT, USER_PROFILE_INIT_DATA_REQUEST_DONE, USER_PROFILE_INIT_DATA_REQUEST_FAILURE, SAVE_USER_PROFILE_DATA_REQUEST_INIT, SAVE_USER_PROFILE_DATA_REQUEST_DONE, SAVE_USER_PROFILE_DATA_REQUEST_FAILURE, SAVE_USER_PROFILE_DATA_REQUEST_RESET } from "../constants";

const _session_usr = SessionData.get(APP_SESSION_KEY); 
let user = (!_.isNull(_session_usr) && !_.isEmpty(_session_usr) && _session_usr !== 'null') ? toDecryption(_session_usr, true) : null;
user = ( _.size(user) > 0 && (_.isNil(user.id_user_type) || user.id_user_type == 0) ) ? null : user;

let initObj = {
    /***isLoggedIn: false,
    loading: false,
    error: ''***/

    isLoggedIn: false,
    loading: false,
    error: false,
    msg: '',
    //user: {}
    forgetPwd: {
        loading: false,
        error: false,
        msg: '',
    },
    trialAcc: {
        loading: false,
        error: false,
        msg: ''
    },
    selfReg: {
        loading: false,
        error: false,
        msg: ''
    },
    selfRegAdminDetails: {
        loading: false,
        error: false,
        msg: '',
        data: {}
    },
    changePwd: {
        loading: false,
        error: false,
        msg: ''
    },
    profileInitData: {
        loading: false,
        error: false,
        msg: '',
        data: {}
    },
    saveProfile: {
        loading: false,
        error: false,
        msg: ''
    }
};
const initState = !_.isNil(user) ? { ...initObj, isLoggedIn: true, user } : initObj;

export const Authentication = (state = initState, action) => {
    
    switch (action.type) {
        case LOGIN_AUTH_REQUEST:
            return Object.assign({}, state, {loading: true, error: ''});
        case LOGIN_AUTH_SUCCESS:
            return Object.assign({}, state, {isLoggedIn: true, user: action.payload, error: '', loading: false});
        case LOGIN_AUTH_FAILURE:
            return Object.assign({}, state, {loading: false, error: action.payload});
        case LOGOUT_AUTH_REQUEST:
            return Object.assign({}, state, { loading: false, error: '', isLoggedIn: false });
        
        case USER_LOGIN_AUTH_REQUEST_INIT:
            return Object.assign({}, state, {loading: true, error: false, msg: '', isLoggedIn: false});
        case USER_LOGIN_AUTH_REQUEST_DONE:
            return Object.assign({}, state, {loading: false, error: false, msg: '', user: action.payload, isLoggedIn: true});
        case USER_LOGIN_AUTH_REQUEST_FAILURE:
            return Object.assign({}, state, {loading: false, error: true, msg: action.payload, user: {}, isLoggedIn: false});
        
        case USER_FORGET_PASSWORD_REQUEST_INIT:
            return Object.assign({}, state, {forgetPwd: {loading: true, error: false, msg: ''} });
        case USER_FORGET_PASSWORD_REQUEST_DONE:
            return Object.assign({}, state, {forgetPwd: {loading: false, error: false, msg: action.payload} });
        case USER_FORGET_PASSWORD_REQUEST_FAILURE:
            return Object.assign({}, state, { forgetPwd: { loading: false, error: true, msg: action.payload } });
        
        case USER_TRIAL_ACC_REQUEST_INIT:
            return Object.assign({}, state, { trialAcc: {loading: true, error: false, msg: ''} });
        case USER_TRIAL_ACC_REQUEST_DONE:
            return Object.assign({}, state, { trialAcc: {loading: false, error: false, msg: action.payload} });
        case USER_TRIAL_ACC_REQUEST_FAILURE:
            return Object.assign({}, state, { trialAcc: { loading: false, error: true, msg: action.payload } });
        
        case IP_USER_SELF_REGISTER_REQUEST_INIT: //selfReg
            return Object.assign({}, state, { selfReg: {loading: true, error: false, msg: ''} });
        case IP_USER_SELF_REGISTER_REQUEST_DONE:
            return Object.assign({}, state, { selfReg: {loading: false, error: false, msg: action.payload} });
        case IP_USER_SELF_REGISTER_REQUEST_FAILURE:
            return Object.assign({}, state, { selfReg: { loading: false, error: true, msg: action.payload } });
        
        case GET_IP_ADMIN_DETAIL_REQUEST_INIT: //selfReg
            return Object.assign({}, state, { selfRegAdminDetails: {loading: true, error: false, msg: ''} });
        case GET_IP_ADMIN_DETAIL_REQUEST_DONE:
            return Object.assign({}, state, { selfRegAdminDetails: { loading: false, error: false, data: action.payload, msg: '' } });
        case GET_IP_ADMIN_DETAIL_REQUEST_FAILURE:
            return Object.assign({}, state, { selfRegAdminDetails: { loading: false, error: true, msg: action.payload } });
        
        case USER_CHANGE_PWD_SAVE_REQUEST_CLEAR_MSG:
            return Object.assign({}, state, { changePwd: { loading: false, error: false, msg: ''} });
        case USER_CHANGE_PWD_SAVE_REQUEST_INIT:
            return Object.assign({}, state, { changePwd: {loading: true, error: false, msg: ''} });
        case USER_CHANGE_PWD_SAVE_REQUEST_DONE:
            return Object.assign({}, state, { changePwd: {loading: false, error: false, msg: action.payload} });
        case USER_CHANGE_PWD_SAVE_REQUEST_FAILURE:
            return Object.assign({}, state, { changePwd: { loading: false, error: true, msg: action.payload } });
        
        case USER_PROFILE_INIT_DATA_REQUEST_INIT:
            return Object.assign({}, state, { profileInitData: {loading: true, error: false, msg: ''} });
        case USER_PROFILE_INIT_DATA_REQUEST_DONE:
            return Object.assign({}, state, { profileInitData: { loading: false, error: false, data: action.payload, msg: '' } });
        case USER_PROFILE_INIT_DATA_REQUEST_FAILURE:
            return Object.assign({}, state, { profileInitData: { loading: false, error: true, msg: action.payload } });
        
        case SAVE_USER_PROFILE_DATA_REQUEST_RESET:
            return Object.assign({}, state, { saveProfile: {loading: false, error: false, msg: ''} });
        case SAVE_USER_PROFILE_DATA_REQUEST_INIT: 
            return Object.assign({}, state, { saveProfile: {loading: true, error: false, msg: ''} });
        case SAVE_USER_PROFILE_DATA_REQUEST_DONE: 
            return Object.assign({}, state, { saveProfile: {loading: false, error: false, msg: action.payload} });
        case SAVE_USER_PROFILE_DATA_REQUEST_FAILURE: //
            return Object.assign({}, state, { saveProfile: { loading: false, error: true, msg: action.payload } });
        
        default:
            return state;
    }

};