import React, { Fragment, useState, useEffect } from 'react'
import { Form, FormGroup, Label, Input, Col, Button, Alert, Row, CustomInput } from 'reactstrap';
import { PropagateLoader } from 'react-spinners';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { USER_CHANGE_PWD_SAVE_REQUEST_CLEAR_MSG } from '../../constants';
import { ReactSelect } from 'components/common';
import Select, { Option, OptGroup } from 'rc-select';
import cx from 'classnames';

export default function UserProfile(props) { 

    const { userProfileForm: state, handleChange, submitUserProfileForm, resetProfileForm, profileFormErr, resProfileMsg,  
        saveProfile: { loading, error, msg }, resetUserProfileResMsg,
        logedInUser,
        profileInitData: { user_data, name_title: nameTitle, state_options: stateOptions, district_options },
        districtOptions } = props;
    
    let isApplySmDevice = false;
    if (window.outerWidth >= 1200 && window.outerWidth <= 1440) { 
        isApplySmDevice = true;
    }     
    return (
        <Fragment>
            <div className="px-4 py-2 page-drawer-header-container" style={{backgroundColor: '#f7f7f7' }}>
                <div className="text-left">
                    <h6 className="mb-0 text-black" style={{ fontSize: '0.83rem'}}>Profile</h6>
                </div>
            </div>
            <div className="divider" />
            <div className="px-4 py-2 page-drawer-body-container" style={{maxHeight: 'calc( 100vh - 220px )', overflowY: 'auto', overflowX: 'hidden'}}>    
                <Form onSubmit={e=>submitUserProfileForm(e)} className="form-customise">
                    <FormGroup>
                        <Label>Name</Label>
                        <Row>
                            <Col xs="3" className="pr-1">                                
                                <Select dropdownClassName="rc-dropdown-styles z-index-2100" placeholder={_.size(nameTitle) ? "Select" : "No data available"} className={cx("w-100", {'is-invalid': profileFormErr.id_name_title})} optionLabelProp="children" optionFilterProp="text" onChange={(e) => handleChange(e, 'id_name_title')} value={_.size(nameTitle) && +state.id_name_title > 0 ? +state.id_name_title : _.noop()}>
                                    {_.map(nameTitle, (obj, indx) => (<Option value={obj.value} text={obj.label} key={obj.value}>{obj.label}</Option> ))}
                                </Select>

                            </Col>
                            <Col xs="9"  className="pl-1">
                                <Input type="text" bsSize="sm" placeholder="Enter User Name" invalid={profileFormErr.user_name} value={state.user_name||""} onChange={e=>{ handleChange(e.target.value, 'user_name') }} />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Label>Gender</Label>
                        <div>
                            <CustomInput type="radio" value={1} invalid={profileFormErr.gender} checked={+state.gender===1} label="Male" id="gender_1" onChange={e => handleChange(1, 'gender')} inline required />
                            <CustomInput type="radio" value={2} invalid={profileFormErr.gender} checked={+state.gender===2} label="Female" id="gender_2" onChange={e => handleChange(2, 'gender')} inline required />
                            <CustomInput type="radio" value={3} invalid={profileFormErr.gender} checked={+state.gender===3} label="Transcender" id="gender_3" onChange={e => handleChange(3, 'gender')} inline required />
                        </div>
                    </FormGroup>
                    <FormGroup row>
                        <Col>
                            <Label>Email Id</Label>
                            <Input type="email" readOnly bsSize="sm" defaultValue={state.email_id}/>  
                        </Col>
                        <Col>
                            <Label>Organization</Label>
                            <Input type="text" bsSize="sm" placeholder="Enter Org. Name" value={state.organization_name || ''} onChange={e=>{ handleChange(e.target.value, 'organization_name') }}/>    
                        </Col>                                                         
                    </FormGroup>
                    <FormGroup row>
                        <Col>
                            <Label>Mobile</Label>
                            <Input type="text" bsSize="sm" placeholder="Enter Mobile No" value={state.mobile||""} onChange={e=>{ handleChange(e.target.value, 'mobile') }}/>  
                        </Col>
                        <Col>
                            <Label>Phone</Label>
                            <Input type="text" bsSize="sm" placeholder="Enter Phone No" value={state.phone||""} onChange={e=>{ handleChange(e.target.value, 'phone') }}/>    
                        </Col>                                                         
                    </FormGroup>
                    <FormGroup row>
                        <Col>
                            <Label>Address</Label>
                            <Input type="text" bsSize="sm" invalid={profileFormErr.address1} placeholder="Enter Address-1" value={state.address1||""}  onChange={e=>{ handleChange(e.target.value, 'address1') }}/>  
                        </Col>
                        <Col>
                            <Label>&nbsp;</Label>
                            <Input type="text" bsSize="sm" placeholder="Enter Address-2 (Optional)" value={state.address2||""} onChange={e=>{ handleChange(e.target.value, 'address2') }}/>    
                        </Col>                                                         
                    </FormGroup>                    
                    <FormGroup row>
                        <Col>
                            <Label>City</Label>
                            <Input type="text" bsSize="sm" invalid={profileFormErr.city} placeholder="Enter City" value={state.city||""} onChange={e=>{ handleChange(e.target.value, 'city') }}/>  
                        </Col>
                        <Col>
                            <Label>Pincode</Label>
                            <Input type="text" bsSize="sm" invalid={profileFormErr.pincode} placeholder="Enter Pincode" value={state.pincode||""} onChange={e=>{ handleChange(e.target.value, 'pincode') }}/>    
                        </Col>                                                         
                    </FormGroup>
                    <FormGroup row>
                        <Col>
                            <Label>State</Label>
                            <Select dropdownClassName="rc-dropdown-styles z-index-2100" placeholder={_.size(stateOptions) ? "Select State" : "No data available"} className={cx("w-100", {'is-invalid': profileFormErr.m_state_id})} optionLabelProp="children" optionFilterProp="text" onChange={(e) => handleChange(e, 'm_state_id')} value={_.size(stateOptions) && +state.m_state_id >0 ? state.m_state_id : _.noop()}>
                                {_.map(stateOptions, (obj, indx) => (<Option value={obj.id} text={obj.text} key={obj.id}>{obj.text}</Option> ))}
                            </Select>
                        </Col>
                        <Col>
                            <Label>District</Label>
                            <Select dropdownClassName="rc-dropdown-styles z-index-2100"  placeholder={_.size(districtOptions) ? "Select District" : "No data available"} className={cx("w-100", {'is-invalid': profileFormErr.m_district_id})} optionLabelProp="children" optionFilterProp="text" onChange={(e) => handleChange(e, 'm_district_id')} value={_.size(districtOptions) && +state.m_district_id > 0 ? `${state.m_district_id}`: _.noop()}>
                                {
                                    _.map(districtOptions, (districtArrObj, state_name) => (
                                        <OptGroup label={state_name} key={state_name}>{
                                            _.map(districtArrObj, (obj, indx) => (<Option value={obj.m_district_id} text={obj.district_name} key={obj.m_district_id}>{obj.district_name}</Option>))
                                        }</OptGroup>
                                    ))
                                }
                            </Select> 
                        </Col>                                                         
                    </FormGroup>
                    

                    <FormGroup>
                        <Alert color={error ? "danger" : "success"} isOpen={!_.isEmpty(msg) ? true : false} toggle={() => { resetUserProfileResMsg(); }}>{msg}</Alert>
                    </FormGroup>
                    
                    <FormGroup row className="mt-2 text-center mb-0">
                        {loading ? (
                            <Col md={{ size: 6, offset: 5 }}>
                                <PropagateLoader color={'var(--primary)'} loading={true} />
                            </Col>
                        ) : (
                            <Fragment>
                                {isApplySmDevice ? <Col md={6}>&nbsp;</Col> : null}    
                                <Col> <Button type="reset" color="warning" outline block onClick={e=>resetProfileForm(e)} className="form-reset-cancel-btn" >Cancel</Button> </Col>
                                <Col> <Button type="submit" color="primary" block className="form-submit-btn"> Save </Button> </Col>
                            </Fragment>
                        )}
                    </FormGroup>
                </Form>
            </div>
        </Fragment>
    );
}