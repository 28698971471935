import React, { useEffect, useState } from 'react'
import L from "leaflet"
import '../../components/Road/Icon'
import '../../components/Road/icon.css'
import Axios from 'axios'

//  const India_shape = '/assets/India.json'
var map
var road_layer_b={}
var road_layer_g=new L.FeatureGroup()
var toll_marker_g=new L.FeatureGroup()
var pre_load_g=new L.FeatureGroup()
var pre_load_b={}

var road_layer_b={}
var road_layer_g=new L.FeatureGroup()
var pre_load_g=new L.FeatureGroup()
var pre_load_b={}
var fully_stretch_g=new L.FeatureGroup()
var fully_stretch_b={}
var district_b={}
var district_g=new L.FeatureGroup()

export default function MapView(props,{state,mapHeight=0,markWater,value}) {
  const [height,setHeight]=useState(700)
  const [tollMarker,setTolMarker]=useState([])
  const india_center=[21.146633, 79.08886]
console.log(props)

  const iniMap=()=>{
    map=L.map('map',{
      center:india_center,
      zoom: 5,
      preferCanvas: true,
      doubleClickZoom: false,
      zoomControl: true,
      scrollWheelZoom: false,
    })
    map.zoomControl.setPosition("topright");
    var osmUrl = "http://{s}.tile.osm.org/{z}/{x}/{y}.png";
    var osmAttr =
      'Map data &copy; <a href="https://osm.org/copyright" target="_blank">OpenStreetMap</a> contributors';
    L.tileLayer(osmUrl, { attribution: osmAttr }).addTo(map);
   fetch(`${process.env.PUBLIC_URL}/assets/India.json`).then(r => r.json()).then((e)=>{
     new L.GeoJSON(e).addTo(map).setStyle({
          color: "#00000",
          fill:false,
          weight: 0.5,
          opacity: 0.9,
          fillOpacity:0
        });
   }).catch((e)=>{

   })

  }
useEffect(()=>{
  iniMap()
return () => map.remove();
},[mapHeight])

useEffect(()=>{
  if([district_g].length>0){
    Object.keys(district_b).map((e)=>{
     if(map.hasLayer(district_b[e])){
       map.removeLayer(district_b[e])
     }
    })
   }
   console.log(district_g)
    setTimeout(()=>{
      if([district_b].length>0){
        Object.keys(district_b).map((e)=>{
         if(map.hasLayer(district_b[e])){
           map.removeLayer(district_b[e])
         }
        })
       }
      const data=props.initData.districts?.filter((s)=>Number(s.m_district_id)===props?.projectData?.m_district_id)
      console.log(data)

    if(props?.projectData?.m_district_id && data?.length>0){
            if(data[0]?.path){
              Axios.get(/^(http|https):\/\/[^ "]+$/.test(data[0]?.path)?data[0]?.path:atob(data[0]?.path)).then(async(res)=>{
                district_b[data[0].m_district_id]= new L.GeoJSON(await res.data).addTo(map).setStyle({color:'blue',fillColor:'white',fillOpacity:0.2,weight:0.5})
                if( district_b[data[0].m_district_id]){
                  district_b[data[0].m_district_id].addTo(district_g)
                 }

              }).catch((e)=>{

              })

            }


            if( Object.keys(district_g.getBounds()).length>0){
              map.fitBounds(district_g.getBounds(),{maxZoom:8})
          }

          }
        },400)

},[props])

useEffect(()=>{
const {ws_lat,ws_long}=props.projectData??{ws_lat:'',ws_long:''}

    if(Object.keys(toll_marker_g).length>0){
      Object.keys(toll_marker_g._layers).map((e)=>{
          map.removeLayer(toll_marker_g._layers[e])
      })


if(ws_lat!==''&&ws_long!=='' && ws_lat!==undefined&&ws_long!==undefined&& ws_lat!==null&&ws_long!==null){
  var marker=new L.marker([ws_lat,ws_long],{icon:L.AwesomeMarkers.icon({
    icon:'fa-pipe-valve', iconColor:'red',className:'awesome-marker'
  }), autoPan: true}).addTo(toll_marker_g).addTo(map)
}

  }

},[props.projectData.ws_lat,props.projectData.ws_long,mapHeight])

  useEffect(()=>{
    setHeight(400)
  },[mapHeight])

  return (
    <div>
      <div id="map" style={{width:"100%",height:"700px"}}></div>
    </div>
  )
}
