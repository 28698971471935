import { combineReducers } from 'redux';
import { default as ThemeOptions } from './ThemeOptions';
/***import ThemeOptions from './ThemeOptions'; export default { ThemeOptions };**/
import { Authentication } from './authentication.reducers';
import { Users } from './users.reducers';
import { Road } from './road.reducers';
import { Company } from './company.reducers';
import { Dashboard } from './dashboard.reducers';
import { Metro } from './metro.reducers';
import { WaterSanitation } from './watersanitation.reducers';

import reduceReducers from 'reduce-reducers';
import {CommonCrossSliceReducers} from './commoncross.reducers'

const combinedReducer = combineReducers({
    ThemeOptions,
    Authentication,
    Users,
    Road,
    Company,
    Dashboard,
    Metro,
    WaterSanitation
});
const rootReducers = reduceReducers(combinedReducer, CommonCrossSliceReducers);
export default rootReducers;