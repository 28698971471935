import React, { Fragment } from 'react';
import { Table } from 'reactstrap';
import _ from 'lodash';
import moment from 'moment';

function ViewGroupAdminUsersList(props) {

    const { groupUsersList, userData } = props;

    const group_ip_user = userData.is_ip_user === 't' ? 'IP Group Admin' : 'Group Admin';

    const periods = !_.isEmpty(userData.start_date) && !_.isEmpty(userData.end_date) ? (moment(userData.start_date, 'YYYY-MM-DD').format('DD-MMM-YYYY') + ' to ' + moment(userData.end_date, 'YYYY-MM-DD').format('DD-MMM-YYYY')) : '';

    const name = ( _.has(userData.profile, 'organization_name') && !_.isNil(userData.profile.organization_name) )? userData.profile.organization_name : userData.user_name;

    return (
        <Fragment>
             <div className="px-4 py-2 page-drawer-header-container">
                <div className="text-left">
                    <h6 className=" mb-0 text-black" style={{marginTop: '5px'}}>{group_ip_user} <span className="text-danger">{name}</span> - Users List ( {periods ? periods: '-'} )</h6>
                </div>
            </div>
            <div className="divider" />
            <div className="px-4 py-3 page-drawer-body-container">
                <Table  className="table table-bordered table-condensed table-hover table-custom">
                    <thead className="thead-light">
                        <tr>
                            <th className="text-center" width="10%">#</th>
                            <th>Name</th>
                            <th>Login ID</th>
                            <th>Subscription Period</th>
                        </tr>
                    </thead>
                    <tbody>
                        {groupUsersList.length > 0 ? groupUsersList.map(row => (
                            <tr key={row.rec_count}>
                                <td className='text-center'>{row.rec_count}</td>
                                <td>{row.user_name}</td>
                                <td>{row.user_login}</td>
                                <td>{row.validity_period}</td>
                            </tr>
                            )): (
                            <tr>
                                <td className='text-center' colSpan="4">No Data Found!...</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
        </Fragment>
    );
}
export default ViewGroupAdminUsersList;