import React, { lazy, Suspense, Fragment, useEffect, useState } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from "framer-motion";
import {ClimbingBoxLoader} from 'react-spinners';

// Layout Blueprints

//import { LeftSidebar, CollapsedSidebar, MinimalLayout, PresentationLayout } from './layout-blueprints';
import { LeftSidebar, MinimalLayout } from './layout-blueprints';
import { useSelector, shallowEqual } from 'react-redux';
import _ from 'lodash';
import packageJson from '../package.json';
import { Request } from 'utils';
// Example Pages
const LandingPage = lazy(() => import('./pages/LandingPage'));
//const LoginPage = lazy(() => import('./pages/LoginPage'));
const PageNotFound = lazy(() => import('./pages/PageNotFound'));
const Dashboard = lazy(() => import('./pages/Dashboard'));
const Users = lazy(() => import('./pages/Users'));
const LocalAdminUsers = lazy(() => import('./pages/LocalAdmin/'));
const Company = lazy(() => import('./pages/Company/'));
const Companyupdatelog = lazy(() => import('./pages/Company/UpadteLog'));
const AddCompanyFinance = lazy(() => import('./pages/Company/AddFinance'));
const Companygroup = lazy(() => import('./pages/Company/Companygroup'));
const AddUpdateCompany = lazy(() => import('./pages/Company/AddCompany'));
const RoadProjects = lazy(() => import('./pages/Road/Projects'));
const AddRoadProjects = lazy(() => import('./pages/Road/AddProjects'));
const RoadProjectsViewDetails = lazy(() => import('./pages/Road/ProjectsViewDetails'));
const RoadQueryPage = lazy(() => import('./pages/Road/Query'));
const Roadupdatelog = lazy(() => import('./pages/Road/Updatelog'));
const Myprojectlist = lazy(() => import('./pages/Road/MyProjectList'))
const UnpublishProjects = lazy(() => import('./pages/Road/Unpublished'))
const Myquerylist = lazy(() => import('./pages/Road/MyQuerList'))
const MyQueryList = lazy(() => import('./pages/WaterSanitation/MyQueryList'))
const MetroProjects = lazy(() => import('./pages/Metro/Projects'));
const WaterSanitationProjects = lazy(() => import('./pages/WaterSanitation/Projects'));
const AddWaterSanitationProjects = lazy(() => import('./pages/WaterSanitation/AddWaterProjects'));
const WsProjectsViewDetails = lazy(() => import('./pages/WaterSanitation/ProjectViewDetailsWater'));
const WsQueryPage = lazy(() => import('./pages/WaterSanitation/WsQuery'));
const WSupdatelog = lazy(() => import('./pages/WaterSanitation/UpdateLogWater'));
const UnpublishWaterProjects = lazy(() => import('./pages/WaterSanitation/UnpublishedWater'))
const MyprojectlistWater = lazy(() => import('./pages/WaterSanitation/MyProjectListWater'))
const Test = lazy(() => import('./pages/Test'));



const Routes = () => {

    const location = useLocation();
    const store = useSelector(state => ({ ...state }), shallowEqual);
    const { Authentication: auth } = store;
    const Action = (_.has(auth, 'isLoggedIn') && auth.isLoggedIn) ? _.get(auth, 'user.action') : {};
    var road_project_count = localStorage.getItem("my_project_count")
    var ws_project_count = localStorage.getItem("my_ws_project_count")

    var  road_qry_count=localStorage.getItem("my_query_count")
    var  ws_qry_count=localStorage.getItem("my_ws_count")
    const [refresh,setRefresh]=useState(false)
    const reset = window.location.pathname.includes('/myqrylist')



   if(window.location.pathname.includes("/clist")===false &&!window.location.pathname.includes("/cadd") && !window.location.pathname.includes("/rdprojectsview") ){
    localStorage.removeItem('gf')
   }

    if (localStorage.getItem('version') !== packageJson.version) {
        localStorage.clear()
        window.location.reload()
        localStorage.setItem("version", packageJson.version.toString())
    }

    if (localStorage.getItem('user_type_id') && !window.location.pathname === '/') {
        localStorage.clear()
        window.location.reload()
        localStorage.setItem("version", packageJson.version.toString())
    }

    let PathComponentObj = [];
    let pathUrls = [];
    if (_.size(Action)) {
        const Menu = Action.MENU;
        PathComponentObj = [
            {url: '/dashboard', access: Menu.DASHBOARD, component: Dashboard},
            {url: '/manageusers', access: Menu.MANAGE_USERS, component: Users},
            {url: '/lausers', access: Menu.MANAGE_LOCAL_ADMIN_USERS, component: LocalAdminUsers},
            //{url: '/lausers', access: true, component: LocalAdminUsers},
            {url: '/clist', access: Menu.COMPANY, component: Company},
            {url: '/cadd', access: Action.COMPANY.ALLOW_ADD, component: AddUpdateCompany},
            {url: '/cgroup', access: Menu.COMPANY_GROUP, component: Companygroup},
            {url: '/cfinance', access: Action.COMPANY.ALLOW_FINANCE_ADD, component: AddCompanyFinance},
            {url: '/cupdatelog', access: Menu.COMPANY_UPDATE_LOG, component:Companyupdatelog  },
            {url: '/rdprojects', access: Menu.ROAD_PROJECT_LIST, component: RoadProjects},
            {url: '/rdprojectsadd', access: Action.ROAD_PROJECT_LIST.ALLOW_ADD || Action.ROAD_PROJECT_UNPUBLISH.ALLOW_ADD, component: AddRoadProjects},
            {url: '/rdprojectsview', access: Action.ROAD_PROJECT_LIST.ALLOW_VIEW_MORE || Action.ROAD_PROJECT_UNPUBLISH.ALLOW_VIEW_MORE, component: RoadProjectsViewDetails},
            {url: '/rdqry', access: Menu.ROAD_QUERY, component: RoadQueryPage},
            {url: '/rdupdatelog', access: Menu.ROAD_UPDATE_LOG, component: Roadupdatelog},
            {url: '/myprojectlist', access:Menu.ROAD_MY_PROJECT, component: Myprojectlist},
            {url: '/myqrylist', access:Menu.ROAD_MY_QUERY, component: Myquerylist},
            {url: '/unpublishproject', access:Menu.ROAD_PROJECT_UNPUBLISH, component: UnpublishProjects},
            {url: '/metroprojects', access: Menu.METRO_PROJECT_LIST, component: MetroProjects},
            {url: '/wsprojects', access: Menu.WS_PROJECT_LIST, component: WaterSanitationProjects},
            {url: '/wsprojectsadd', access: Menu.WS_PROJECT_LIST, component: AddWaterSanitationProjects},
            {url: '/wsupdatelog', access: Menu.WS_PROJECT_LIST, component: WSupdatelog},
            {url: '/wsprojectsview', access:Menu.WS_PROJECT_LIST, component: WsProjectsViewDetails},
            {url: '/wsqry', access:Menu.WS_PROJECT_LIST, component: WsQueryPage},
            {url: '/myqrylistws', access:Menu.WS_PROJECT_LIST, component: MyQueryList},
            {url: '/wsunpublishproject', access:Menu.WS_PROJECT_LIST, component: UnpublishWaterProjects},
            {url: '/myprojectlistwater', access:Menu.WS_PROJECT_LIST, component: MyprojectlistWater},
            {url: '/test', access: true, component: Test},
        ];
        PathComponentObj = _.filter(PathComponentObj, ['access', true]);
        pathUrls = _.map(PathComponentObj, 'url');
    }

    const pageVariants = {
        initial: {
            opacity: 0,
            //scale: 0.99
        },
        in: {
            opacity: 1,
            //scale: 1
        },
        out: {
            opacity: 0,
            //scale: 1.01
        }
    };

    const pageTransition = {
        type: "tween",
        ease: "anticipate",
        duration: 2.0
    };

    const SuspenseLoading = () => {
        return (
            <Fragment>
                <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
                    <div className="d-flex align-items-center flex-column px-4">
                        <ClimbingBoxLoader color={"#3c44b1"} loading={true} />
                    </div>
                    <div className="text-muted font-size-xl text-center pt-3">
                        Please wait while we load the page
                        {/***<span className="font-size-lg d-block text-dark">
                            This live preview instance can be slower than a real production build!
                        </span>***/}
                    </div>
                </div>
            </Fragment>
        );
    }



    /***
    let loggedInContent = (
        <Switch>
            <Redirect exact from="/" to="/dashboard" />
            <Route exact path={['/dashboard', '/users', '/clist', '/cadd', '/cfinance', '/rdprojects', '/rdprojectsadd', '/rdprojectsview', '/rdqry', '/metroprojects', '/wsprojects', '/test']}>
                <LeftSidebar>
                    <Switch location={location} key={location.pathname}>
                        <Route path="/dashboard" component={ Dashboard } />
                        <Route path="/users" component={ Users } />
                        <Route path="/clist" component={ Company } />
                        <Route path="/cadd" component={ AddUpdateCompany } />
                        <Route path="/cfinance" component={ AddCompanyFinance } />
                        <Route path="/rdprojects" component={ RoadProjects } />
                        <Route path="/rdprojectsadd" component={ AddRoadProjects } />
                        <Route path="/rdprojectsview" component={ RoadProjectsViewDetails } />
                        <Route path="/rdqry" component={ RoadQueryPage } />
                        <Route path="/metroprojects" component={ MetroProjects } />
                        <Route path="/wsprojects" component={ WaterSanitationProjects } />
                        <Route path="/test" component={ Test } />
                    </Switch>
                </LeftSidebar>
            </Route>
            <Route path="*">
                <MinimalLayout>
                    <Switch location={location} key={location.pathname}>
                        <Route path="*" component={ PageNotFound } />
                    </Switch>
                </MinimalLayout>
            </Route>
        </Switch>
    );****/

    let loggedInContent = (
        <Switch>
            <Redirect exact from="/" to="/dashboard" />
            <Route exact path={pathUrls}>
                <LeftSidebar count={road_project_count} qry_count={road_qry_count}  my_ws_count={ws_qry_count} ws_project_count = {ws_project_count} setRefresh={setRefresh}>
                    <Switch location={location} key={location.pathname} >
                        {_.map(PathComponentObj, (obj, indx) => (<Route path={obj.url}  component={obj.component} key={indx} /> ))}
                    </Switch>
                </LeftSidebar >
            </Route>
            <Route path="*">
                <MinimalLayout>
                    <Switch location={location} key={location.pathname}>
                        <Route path="*"  component={ PageNotFound } />
                    </Switch>
                </MinimalLayout>
            </Route>
        </Switch>
    );


    return (
        <AnimatePresence>
            <Suspense fallback={<SuspenseLoading />}>
                <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}
                >

                {/***
                    <Switch>
                     <Route exact path={["/login"]}>
                        <MinimalLayout>
                            <Switch location={location} key={location.pathname}>
                                <motion.div
                                    initial="initial"
                                    animate="in"
                                    exit="out"
                                    variants={pageVariants}
                                    transition={pageTransition}
                                >
                                    <Route exact path="/login" component={ LoginPage } />
                                </motion.div>
                            </Switch>
                        </MinimalLayout>
                    </Route>

                        <Route path={[  "/LandingPage",  ]}>
                            <PresentationLayout>
                                <Switch location={location} key={location.pathname}>
                                    <motion.div
                                        initial="initial"
                                        animate="in"
                                        exit="out"
                                        variants={pageVariants}
                                        transition={pageTransition}
                                    >
                                        <Route path="/LandingPage" component={ LandingPage } />
                                    </motion.div>
                                </Switch>
                            </PresentationLayout>
                        </Route>
                    </Switch>
                ***/}
                    {/***!auth.isLoggedIn ? (<Switch><Route exact path="*" component={LandingPage} /></Switch>) : (loggedInContent)***/}
                    {!auth.isLoggedIn ? (<Switch><Route exact path="/" component={LandingPage} /><Route path="*"> <Redirect to="/"/> </Route> </Switch>) : (loggedInContent)}
                </motion.div>
            </Suspense>
        </AnimatePresence>
    );
};
export default Routes;