import React, { useState } from 'react';
import { Form, FormGroup, Input, Row, Col, Button, Alert } from 'reactstrap';
import _ from 'lodash';
import { isValidEmail,SweetAlert } from 'utils';
import { PropagateLoader } from 'react-spinners';
import { Fragment } from 'react';

export const UserRegisterForm = (props) => {

    const [err, setErr] = useState({});
    const [state, setState] = useState({});

    const { closeModal, createAccSubmit, toggleTrialAccResAlert, trialAccResAlert, trialAcc } = props;

    const _handleChange = (val, name) => {
        let _err = { ...err };
        if (_.size(_err)) { 
            if (_.has(_err, name) && !_.isEmpty(val)) { 
                _err = _.omit(_err, [name]);
            }
            setErr(_err);
        }
        setState((prevState) => ({...prevState, [name]: val}));
    }

    const _handleSubmit = (e) => {
        e.preventDefault();
        let _errObj = {};
        if (_.isNil(state.email_id) || (_.has(state, 'email_id') && _.isEmpty(_.trim(state.email_id)))) {
            _errObj.email_id = true;
        } else if (!isValidEmail(state.email_id)) {
            _errObj.email_id = true;
        }
        if (_.isNil(state.name) || (_.has(state, 'name') && _.isEmpty(_.trim(state.name)))) {
            _errObj.name = true;
        }

        if (_.size(_errObj)) {
            setErr(_errObj);
        } else { 
            createAccSubmit(state);
            SweetAlert.confirm({
                title: trialAcc.error ? "Error" : "Success",
                text: trialAcc.msg,
                icon: trialAcc.error ? "error" : "success",
                type: trialAcc.error ? "error" : "success",
                showConfirmButton: true,
                dangerMode: false,
                closeOnClickOutside: false
            }, (isConfirm) => {
                if (isConfirm) {
                    _closeModal(e);
                }
            });
            
        }
    }
    const _closeModal = (e) => {
        e.preventDefault();
        setState({});
        setErr({});
        closeModal(e);
    }

    return (
        <div className="px-3 pb-3 pt-4 sign-in-container register-form-container">
            <h1 className="display-4 mb-2 font-weight-bold h1-title">
                Request Trial Account
            </h1>
            <p className="font-size-lg mb-3 text-black-50 p-subtitle">
                Fill in the fields below and you'll be good to go.
            </p>
            <Form onSubmit={_handleSubmit} className="form-customise">
                <FormGroup>
                    <label style={{color:"#9b9797"}}>Name</label>
                    <Input placeholder="Enter your name" invalid={err.name} type="text" onChange={e => _handleChange(e.target.value, 'name')} value={_.has(state, 'name') ? state.name : ''} />
                </FormGroup>
                <FormGroup>
                    <label style={{color:"#9b9797"}}>Email Id</label>
                    <Input placeholder="Enter your email address" invalid={err.email_id} type="email" onChange={e => _handleChange(e.target.value, 'email_id')} value={_.has(state, 'email_id') ? state.email_id : ''} />
                </FormGroup>
                <FormGroup>
                    <label style={{color:"#9b9797"}}>Mobile (Optional)</label>
                    <Input placeholder="Enter your mobile no" type="text" onChange={e => _handleChange(e.target.value, 'mobile_no')} value={_.has(state, 'mobile_no') ? state.mobile_no : ''}/>
                </FormGroup>
                <div className="form-group mb-3 p-subtitle">
                    By clicking the <strong>Submit</strong> button below you agree to our terms of service and privacy statement.
                </div>
                <Row>
                    {trialAcc.loading ? (
                            <Col md={{ size: 6, offset: 5 }}>
                            <PropagateLoader color={'var(--primary)'} loading={true} />
                        </Col>
                    ) : (
                        <Fragment>
                            <Col>
                                <Button onClick={_closeModal} size="lg" color="warning" block={true} className="form-reset-cancel-btn">
                                    <span className="btn-wrapper--label">Close</span>
                                </Button>
                            </Col>
                            <Col>
                                <Button type="submit" size="lg" block={true} color="second" className="form-submit-btn"><span className="btn-wrapper--label">Submit</span></Button>
                            </Col>
                        </Fragment>
                    )}   
                </Row>
            </Form>
            {/* <Alert color={trialAcc.error ? "danger" : "success"} isOpen={true} className="mt-4 general-text" toggle={toggleTrialAccResAlert}>
                {trialAcc.msg}
            </Alert> */}
        </div>
    );
};