
export default {
    ZERO: 48,
    NINE: 57,
  
    NUMPAD_ZERO: 96,
    NUMPAD_NINE: 105,
  
    BACKSPACE: 8,
    DELETE: 46,
    ENTER: 13,
  
    ARROW_UP: 38,
    ARROW_DOWN: 40,
  };