import React, {Fragment, useState, Component} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Row, Table, Col, CardBody, Card, CardTitle, CardHeader, Collapse, UncontrolledPopover, Container, InputGroup, InputGroupAddon, InputGroupText, FormText, Form, CustomInput, Label, FormGroup, Input, Badge, ListGroup, ListGroupItem, PopoverHeader, PopoverBody, UncontrolledTooltip, TabContent, TabPane, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledDropdown, Progress, DropdownToggle, DropdownMenu, Alert, DropdownItem } from 'reactstrap'

import {connect} from 'react-redux';
import { setFooterFixed } from 'reducers/ThemeOptions';

class Footer extends Component {

    componentDidMount() { 
        //this.props.setFooterFixed();
    }

    render() {

        let {
            footerShadow,
            footerFixed,
            footerBgTransparent
        } = this.props;

        //console.log('footerFixed', footerFixed);

        return (
            <Fragment>
                <div className={cx("app-footer text-black-50", { 'app-footer--shadow': footerShadow, 'app-footer--opacity-bg': footerBgTransparent })}>
                    <div className="app-footer--first">
                        <Nav>
                            <NavItem>
                                <NavLink tag={Link} to="/DashboardAnalytics" className="rounded-sm">Analytics</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} to="/FormsWizard" className="rounded-sm">Wizards</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} to="/DashboardCrmManager" className="rounded-sm">CRM Manager</NavLink>
                            </NavItem>
                        </Nav>
                    </div>
                    <div className="app-footer--second">
                        <span>Bamburgh React Admin Dashboard with Reactstrap PRO</span> © 2020 - crafted with <span className="text-danger px-1">❤</span> by <a href="https://uifort.com" target="_blank" title="UiFort.com">UiFort.com</a>
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    footerFixed: state.ThemeOptions.footerFixed,
    footerShadow: state.ThemeOptions.footerShadow,
    footerBgTransparent: state.ThemeOptions.footerBgTransparent
});

const mapDispatchToProps = dispatch => ({
    setFooterFixed: () => dispatch( setFooterFixed(true) )
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
