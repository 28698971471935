import React, { Fragment } from 'react'
import { Form, FormGroup, Input, Row, Col, Button, Alert, Label } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom';
import { PropagateLoader } from 'react-spinners';

export const LoginForm = (props) => {
    const { forgotPassword, closeModal, loginSubmit, toggleTab, handleChange, isLoginSubmit, loginFormData, loading, error, errMsg, loginFormErr, alertVisible, onDismiss } = props;    
    return (
        <Fragment>
            <div className="text-black px-3 pb-3 pt-4 sign-in-container">
                <h1 className="display-4 mb-2 font-weight-bold h1-title">
                    Login to your account
                </h1>
                <p className="font-size-lg mb-3 text-black-50 p-subtitle">
                    We're glad you're glad crunching on your dashboard. Login in below to continue.
                </p>                
                <div className="divider my-3" />        
                
                <Form onSubmit={(e) => loginSubmit(e)}>
                    <FormGroup className="mb-3">
                        <Label style={{color:'#9b9797'}}>Email Address</Label>
                        <Input placeholder="yourname@yourmail.com" invalid={loginFormErr.user_login} type="email" name="user_login" onChange={(e) => handleChange(e)} value={loginFormData.user_login||''} />
                        {/**<div className="input-group input-group-alternative">
                            <div className="input-group-prepend">
                                <span className="input-group-text">
                                    <FontAwesomeIcon icon={['far', 'envelope']} />
                                </span>
                            </div>
                            <Input placeholder="yourname@yourmail.com" invalid={loginFormErr.user_login} type="email" name="user_login" onChange={(e) => handleChange(e)} value={loginFormData.user_login||''} />
                        </div>***/}
                    </FormGroup>
                    <FormGroup>
                        <Label  style={{color:'#9b9797'}}>Password</Label>
                        <Input placeholder="Enter your password" invalid={loginFormErr.user_pwd} type="password" autoComplete="off" name="user_pwd" onChange={(e) => handleChange(e)} value={loginFormData.user_pwd||''} />
                        {/***<div className="input-group input-group-alternative">
                            <div className="input-group-prepend">
                                <span className="input-group-text">
                                    <FontAwesomeIcon icon={['fas', 'unlock-alt']} />
                                </span>
                            </div>
                            <Input placeholder="Enter your password" invalid={loginFormErr.user_pwd} type="password" autoComplete="off" name="user_pwd" onChange={(e) => handleChange(e)} value={loginFormData.user_pwd||''} />
                        </div>***/}
                    </FormGroup>
                    <Row>
                        {/***<Col>
                            <CustomInput type="checkbox" className="custom-control-alternative mb-3" id="exampleCustomCheckbox55" label="Remember me" />
                        </Col>***/}
                        <Col className="text-right mb-3 forgot-pwd-content">
                            <Link to="/#" onClick={(e) => toggleTab(3)}>Forgot password?</Link>
                        </Col>
                    </Row>
                    <Row>
                        {loading ? (
                             <Col md={{ size: 6, offset: 5 }}>
                                <PropagateLoader color={'var(--primary)'} loading={true} />
                            </Col>
                        ) : (
                            <Fragment>
                                <Col>
                                    <Button onClick={(e) => closeModal(e)} size="lg" color="warning" block={true}>
                                        <span className="btn-wrapper--label">Close</span>
                                    </Button>
                                </Col>
                                <Col>
                                    <Button type="submit" size="lg" block={true} color="second"><span className="btn-wrapper--label">Login</span></Button>
                                </Col>
                            </Fragment>
                        )}                        
                    </Row>
                </Form>
            </div>
            {error ? (<Alert color="danger" isOpen={alertVisible} toggle={onDismiss}>
                {errMsg}
            </Alert>) : null}
            {/***<div className="text-center pt-2 text-black-50">Don't have an account? <Link to="/#" onClick={(e) => toggleTab(2)}>Create an Account</Link> </div>***/}
        </Fragment>        
    );
}