import { CMN_SET_PAGE_DRAWER_TOGGLE } from "../constants";


const setPageDrawerToggle = (isOpen, prevState) => ({
    type: CMN_SET_PAGE_DRAWER_TOGGLE,
    pageDrawerToggle: isOpen,
    prevState: prevState
});

export const cmnFnTogglePageDrawer = (isOpen) => {    
    return (dispatch, getState) => {
        let prevState = getState();
        dispatch(setPageDrawerToggle(isOpen, prevState));
        return Promise.resolve();//For Open Slider First, Then Load Data
    }
}