import React, {Fragment, useState, Component} from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { PageTitle } from '../../layout-components';
import { ExampleWrapperSeamless } from '../../layout-components';
import { ExampleWrapperSimple } from '../../layout-components';

import { PromoSection } from '../../layout-components'

const PresentationLayout = props => {
    const { children } = props;

        return (
            <Fragment>
                {children}
            </Fragment>
        );
}

PresentationLayout.propTypes = {
        children: PropTypes.node
}

export default PresentationLayout;
