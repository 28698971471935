export const GET_METRO_LIST_DATA_REQUEST_INIT = 'GET_METRO_LIST_DATA_REQUEST_INIT';
export const GET_METRO_LIST_DATA_REQUEST_DONE = 'GET_METRO_LIST_DATA_REQUEST_DONE';
export const GET_METRO_LIST_DATA_REQUEST_FAILURE = 'GET_METRO_LIST_DATA_REQUEST_FAILURE';

export const DOWNLOAD_METRO_RESULTS_DATA_REQUEST_INIT = 'DOWNLOAD_METRO_RESULTS_DATA_REQUEST_INIT';
export const DOWNLOAD_METRO_RESULTS_DATA_REQUEST_DONE = 'DOWNLOAD_METRO_RESULTS_DATA_REQUEST_DONE';
export const DOWNLOAD_METRO_RESULTS_DATA_REQUEST_FAILURE = 'DOWNLOAD_METRO_RESULTS_DATA_REQUEST_FAILURE';

export const GET_METRO_LIST_INIT_DATA_REQUEST_INIT = 'GET_METRO_LIST_INIT_DATA_REQUEST_INIT';
export const GET_METRO_LIST_INIT_DATA_REQUEST_DONE = 'GET_METRO_LIST_INIT_DATA_REQUEST_DONE';
export const GET_METRO_LIST_INIT_DATA_REQUEST_FAILURE = 'GET_METRO_LIST_INIT_DATA_REQUEST_FAILURE';