import React, { Component, Fragment } from 'react'
import { Modal, Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap'
import { Link, withRouter } from 'react-router-dom';


import { fnLoginAuthentication } from 'actions';
import { connect } from 'react-redux';
import _ from 'lodash';

class LoginModals extends Component {

    loginSubmit = () => {
        this.props.history.push('/dashboard');
    }

    forgotPassword = (e) => {
        e.preventDefault();
    }

    render() { 
        return (
            <Fragment>                
                <Modal centered size="xl" isOpen={this.props.isOpen} zIndex={1300} toggle={this.props.toggle} contentClassName="border-0 bg-transparent">
                    <Row className="no-gutters">
                        <Col lg="5" md={5} sm={4}>
                            <div className="bg-white rounded-left h-100">
                                <div className="p-5 text-center">
                                    <Row>
                                        <Col lg="12" className="d-none d-lg-flex align-items-center my-5 ">
                                            <img alt="..." className="w-100 mx-auto d-block img-fluid" src="/assets/images/illustrations/login.svg" />
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                        <Col lg="7" md={7} sm={8}>
                            <div className="bg-white hero-wrapper bg-composed-wrapper h-100 rounded-right">
                                <div className="flex-grow-1 w-100 d-flex">
                                    <div className="bg-composed-wrapper--content p-5">
                                        <div className="text-black">
                                            <h1 className="display-3 my-3 font-weight-bold">
                                                Login to your account
                                            </h1>
                                            <p className="font-size-lg mb-0">
                                                We're glad you're glad crunching on your dashboard. Login in below to continue.
                                            </p>
                                            <div className="divider my-2"/>
                                            <Form>
                                                <FormGroup>
                                                    <Label>Email address</Label>
                                                    <Input placeholder="yourname@yourmail.com" type="email" />
                                                </FormGroup>
                                                <FormGroup className="mb-4">
                                                    <div className="d-flex justify-content-between">
                                                        <label>Password</label>
                                                        <Link to="/#" onClick={this.forgotPassword}>Forgot password?</Link>
                                                    </div>
                                                    <Input placeholder="Enter your password" type="password" autoComplete="false" />
                                                </FormGroup>
                                                <Button onClick={this.loginSubmit} size="lg" block={true} color="second">Login</Button>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Modal>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state,
        isLoggedIn: !_.isNil(state.Authentication.isLoggedIn) ? state.Authentication.isLoggedIn : false,
        error: state.Authentication.error,
        loading: state.Authentication.loading
    };
};

const mapDispatchToProps = (dispatch) => { 
    return {
        loginAuthentication: (data) => dispatch( fnLoginAuthentication( data ) ) 
    }
};
export default withRouter( connect( mapStateToProps, mapDispatchToProps )( LoginModals ) );